@import '../../../theme/color.scss';
@import '../../../theme/commons.module.scss';
@import '../../../theme/assets.scss';

.notifications_wrap {
  position: relative;
  width: 100%;
  background: $app_white_absolute;
  box-sizing: border-box;
  padding-bottom: 30px;

  &_raw {
    position: relative;
    width: 100%;
    margin-top: 130px;
    background: $app_white_absolute;

    .notifications_header {
      position: relative;
      width: 630px;
      padding: 0 5px;
      display: inline-block;
      height: 40px;
      border-bottom: 1.5px solid $app_white_shade3;

      .title {
        position: relative;
        margin-bottom: 8px;

        & > h4 {
          font-size: 130%;
          font-weight: unset;
          font-family: 'WorkSans-SemiBold';
        }
      }

      &_dark {
        border-bottom: 2px solid rgba($color: $app_white_shade3, $alpha: 0.05);
      }
    }

    .loading {
      position: relative;
      width: 640px;
      margin-top: 30px;
      margin-bottom: 8px;
      padding: 0 4px;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      box-sizing: border-box;

      & > div {
        position: relative;
        width: 100%;
        padding-left: 0;
        padding-right: 16px;
        box-sizing: border-box;
        justify-content: flex-start;
      }

      &_dark {
        svg {
          circle {
            stroke: $app_white_light;
          }
        }
      }
    }

    .notifications_list {
      position: relative;
      width: 640px;
      margin-top: 10px;

      & > ul {
        position: relative;
        width: 100%;
        list-style: none;
        list-style-type: none;

        & > li {
          position: relative;
          width: 100%;
          overflow-wrap: break-word;
          display: block;
          padding: 10px 0;
        }

        & > li:not(:nth-child(1)) {
          border-top: 1.5px solid $app_white_shade3;
          margin-top: 10px;
        }
      }

      &_dark {
        & > ul {
          & > li:not(:nth-child(1)) {
            border-top: 1px solid rgba($color: $app_white_shade3, $alpha: 0.05);
          }
        }
      }
    }

    .navigation_wrap {
      position: relative;
      width: 600px;
      margin-top: 35px;

      .load_button {
        position: relative;
        padding: 8px 16px;
        box-sizing: border-box;

        & > h4 {
          font-family: 'WorkSans-Medium';
          font-weight: unset;
          font-size: 130%;
          box-sizing: border-box;
        }

        &_dark {
          color: $app_darkmode_text1 !important;
          background: transparent !important;

          &:hover,
          &:active {
            background: rgba(
              $color: $app_darkmode_text1,
              $alpha: 0.05
            ) !important;
          }
        }
      }
    }

    .notifications_empty {
      position: relative;
      width: 600px;
      margin-top: 35px;
      overflow: hidden;

      .msg {
        position: relative;
        max-width: 90%;
        overflow-wrap: break-word;

        & > h4 {
          position: relative;
          font-size: 110%;
          font-weight: unset;
          font-family: 'WorkSans-Medium';
        }
      }
    }

    .spinner_wrap {
      position: relative;
      width: 600px;
      margin-top: 60px;

      .raw {
        position: relative;
        height: 30px;
        width: 30px;
      }

      &_dark {
        .raw {
          svg {
            circle {
              stroke: $app_white_light;
            }
          }
        }
      }
    }
  }

  &_dark {
    background: $app_darkmode_background1;

    .notifications_wrap_raw {
      background: $app_darkmode_background1;
    }
  }
}

@media screen and (min-width: $max_width_fix) {
  .notifications_wrap {
    &_raw {
      width: $max_width_fix !important;
    }
  }
}

@media screen and (max-width: 675px) {
  .notifications_wrap {
    &_raw {
      .notifications_header {
        width: calc(92% - 10px);
      }

      .navigation_wrap,
      .notifications_list,
      .notifications_empty,
      .spinner_wrap,
      .loading {
        width: 93vw;
      }
    }
  }
}
