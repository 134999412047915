@import '../../../theme/color.scss';
@import '../../../theme/commons.module.scss';

.personal_space {
  position: relative;
  width: 100%;
  box-sizing: border-box;
  padding: 4px 0;
  padding-right: 30px;
  padding-left: 26px;
  justify-content: space-between;

  .left,
  .right {
    position: relative;
    width: 300px;
    justify-content: flex-start;
  }

  .label {
    position: relative;

    & > h2 {
      font-size: 240%;
      font-weight: unset;
      font-family: 'WorkSans-Bold';
    }

    &_dark {
      & > h2 {
        color: #fff;
      }
    }
  }

  .right {
    justify-content: flex-end;

    .create {
      position: relative;

      &_button {
        height: 38px;
        width: 100px;

        & > p {
          font-weight: unset;
          font-family: 'WorkSans-Medium';
        }
      }

      .link {
        position: absolute;

        a {
          position: absolute;
          height: 100%;
          width: 100%;
          top: 0;
          left: 0;
          text-decoration: none;
        }
      }
    }
  }
}

@media screen and (max-width: 800px) {
  .personal_space {
    display: none;
    height: 0;
    width: 0;
  }
}
