@import '../../../theme/color.scss';
@import '../../../theme/commons.module.scss';
@import '../../../theme/scroll.scss';

@keyframes runMobile {
  0% {
    bottom: -90vh;
  }
  100% {
    bottom: 0;
  }
}

.activities {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  box-sizing: border-box;

  .content {
    position: relative;
    width: 430px;
    padding: 24px 24px;
    padding-right: 16px;
    box-sizing: border-box;
    border-radius: 10px;
    background: #fff;
    box-shadow: 0px 4px 50px rgba(16, 24, 64, 0.04);
    -webkit-box-shadow: 0px 4px 50px rgba(16, 24, 64, 0.04);
    -moz-box-shadow: 0px 4px 50px rgba(16, 24, 64, 0.04);
    align-items: flex-start;

    .list {
      position: relative;
      display: inline-block;
      vertical-align: top;
      width: 100%;
      margin-top: 24px;
      min-height: 90px;
      max-height: 300px;
      overflow: hidden;
      overflow-y: scroll;
      padding: 8px 0;
      box-sizing: border-box;

      @include custom_scroll;

      &_dark {
        @include custom_scroll_dark;
      }

      & > ul {
        position: relative;
        list-style: none;
        list-style-type: none;
        width: 100%;
        box-sizing: border-box;

        & > li {
          position: relative;
          width: 100%;
          display: flex;
          justify-content: flex-start;
          align-items: center;

          .author {
            position: relative;
            height: 36px;
            width: 36px;
            margin-right: 8px;

            span {
              & > div {
                font-size: 90%;
                color: #fff;
              }
            }
          }

          .item {
            position: relative;
            width: calc(100% - 48px);
          }
        }

        & > li:not(:nth-child(1)) {
          margin-top: 8px;
        }
      }
    }

    .title {
      position: relative;
      justify-content: flex-start;
      box-sizing: border-box;

      & > h3 {
        position: relative;
        margin-left: 8px;
        font-size: 105%;
        font-weight: unset;
        font-family: 'WorkSans-Medium';
      }
      &_dark {
        svg {
          fill: #fff;
          path {
            fill: #fff;
          }
        }

        & > h3 {
          color: #fff;
        }
      }
    }

    .close {
      position: absolute;
      top: 16px;
      right: 16px;

      & > button {
        background: #fbfcfe;
        height: 30px;
        width: 30px;
        padding: 0;
        box-sizing: border-box;

        svg {
          fill: #718096;

          path {
            fill: #718096;
          }
        }
      }

      &_dark {
        & > button {
          background: transparent !important;

          &:hover,
          &:active {
            background: rgba(
              $color: $app_darkmode_text1,
              $alpha: 0.05
            ) !important;
          }

          svg {
            fill: $app_darkmode_text1;

            path {
              fill: $app_darkmode_text1;
            }
          }
        }
      }
    }

    &_dark {
      border: 1px solid rgba($color: $app_darkmode_text1, $alpha: 0.1);
      -moz-box-shadow: none;
      -webkit-box-shadow: none;
      box-shadow: none;
      background: $app_darkmode_background_header1;
    }
  }
}

@media screen and (max-width: 900px) {
  .activities {
    .content {
      position: absolute;
      width: 100%;
      height: 80vh;
      padding-left: 16px;
      padding-right: 16px;
      padding-bottom: 20px;
      box-sizing: border-box;
      top: unset;
      bottom: 0;
      border-radius: unset;
      align-items: flex-start;
      justify-content: flex-start;
      animation: ease;
      animation-name: runMobile;
      animation-duration: 0.25s;

      .list {
        min-height: unset;
        max-height: unset;
        height: calc(100% - 60px);

        & > ul {
          padding-bottom: 16px;
          box-sizing: border-box;
        }
      }
    }
  }
}
