@import '../../theme/commons.module.scss';
@import '../../theme/color.scss';

.signup {
  position: relative;
  width: 100%;
  flex-direction: column;
  align-items: flex-start;

  .logo {
    width: 100%;
    position: relative;
  }

  .message {
    position: relative;
    width: 100%;
    box-sizing: border-box;
    padding: 0 16px;
    margin: 16px 0;

    & > h2 {
      position: relative;
      font-weight: unset;
      font-family: 'WorkSans-SemiBold';
      font-size: 36px;
    }

    &_dark {
      & > h2 {
        color: #fff;
      }
    }
  }

  .success_created {
    position: relative;
    width: 100%;
    padding: 16px 8px;
    box-sizing: border-box;
    flex-direction: column;

    & > p {
      position: relative;
      margin-top: 16px;
      font-size: 105%;
      font-family: 'WorkSans-Medium';
    }

    svg {
      height: 30px;
      width: 30px;
    }
  }

  .username,
  .password,
  .email,
  .fullName {
    position: relative;
    padding: 0 16px;
    width: 100%;
    box-sizing: border-box;

    & > div:nth-child(1) {
      position: relative;
      margin-bottom: 15px;
      width: 100%;

      div {
        & > p:nth-child(2) {
          box-sizing: border-box;
          font-family: 'WorkSans-Medium' !important;
          font-size: 90% !important;
          width: calc(100% - 30px) !important;
        }
      }

      label {
        font-weight: unset;
        font-family: 'WorkSans-Medium' !important;
        font-size: 90% !important;
      }
    }

    input[type='text'],
    input[type='password'] {
      font-size: 80% !important;
      font-weight: unset;
      font-family: 'WorkSans-Regular' !important;
      height: 40px;
    }
  }

  .username,
  .password,
  .fullName,
  .email {
    &_dark {
      label,
      span {
        color: $app_darkmode_text1 !important;
      }

      input[type='text'],
      input[type='password'] {
        background: transparent;
        border: 1px solid #28303b;
        color: $app_darkmode_text1;

        &:focus,
        &:active {
          background: transparent;
        }
      }
    }
  }

  .status {
    position: relative;
    width: 100%;
    display: inline-block;
    text-align: center;
    margin: 16px 0;

    & > div:nth-child(1) {
      text-align: center;

      & > h5 {
        font-weight: unset;
        font-family: 'WorkSans-Medium';
        color: #df3e4a;
      }
    }
  }

  .cta {
    position: relative;
    padding: 0 15px;
    width: 100%;
    box-sizing: border-box;

    .agree_terms {
      position: relative;
      width: 100%;
      justify-content: flex-start;
      align-items: center;
      box-sizing: border-box;
      margin: 4px 0;

      & > p {
        position: relative;
        width: calc(100% - 40px);
        margin-left: 8px;
        font-size: 100%;
        cursor: pointer;

        a {
          font-family: 'WorkSans-Medium';

          &:hover {
            text-decoration: underline;
          }
        }
      }

      svg {
        path {
          fill: white;
        }
      }

      label {
        span {
          font-weight: unset;
          font-family: 'WorkSans-Regular' !important;
        }
      }

      &_dark {
        label {
          & > div {
            background: transparent !important;

            svg {
              fill: transparent !important;
              path {
                fill: transparent !important;
              }
            }
          }
        }

        p {
          font-weight: unset;

          color: rgba($color: $app_darkmode_text1, $alpha: 0.7);
          & > a,
          & > span {
            color: $app_darkmode_text1;
          }
        }

        span {
          color: $app_darkmode_text1 !important;
        }
      }

      &_active_dark {
        label {
          & > div {
            background: $app_blue_shade3 !important;

            svg {
              fill: $app_white_shade3 !important;

              path {
                fill: $app_white_shade3 !important;
              }
            }
          }
        }

        span {
          color: $app_darkmode_text1 !important;
        }
      }
    }

    .redirect_login {
      position: relative;
      width: 100%;
      text-align: center;
      margin-top: 14px;
      box-sizing: border-box;

      & > p {
        text-align: center;
        font-size: 100%;
        font-family: 'WorkSans-Medium';

        span {
          cursor: pointer;
          font-family: 'WorkSans-Medium';

          &:hover {
            text-decoration: underline;
          }
        }
      }

      &_dark {
        & > p {
          color: $app_darkmode_text1;

          span {
            color: $app_darkmode_text1;
          }
        }
      }
    }
  }

  .confirm {
    position: relative;
    width: 100%;
    height: 40px;
    font-size: 100%;
    margin-top: 16px;

    & > p,
    & > h5 {
      font-size: 100%;
      font-family: 'WorkSans-Regular';
    }
  }
}
