@import '../../theme/color.scss';
@import '../../theme/commons.module.scss';
@import '../../theme/scroll.scss';

.formats_toolbar {
  position: relative;
  background: #fbfcfe;
  height: 70px;
  box-sizing: border-box;
  z-index: calc(#{$high_front} + 6);

  &_dark {
    background: #1c2026;
    background: #1f242b;
  }

  .content {
    position: relative;
    width: 100%;
    height: 100%;
    justify-content: flex-start;
    align-items: center;
    padding: 0 16px;
    padding-top: 14px;
    box-sizing: border-box;

    .first,
    .second,
    .third,
    .fourth,
    .fifth {
      position: relative;
      padding-right: 16px;
      box-sizing: border-box;

      &::after {
        position: absolute;
        content: ' ';
        height: 50%;
        top: 25%;
        right: 0;
        border-right: 1px solid #e2e8f0;
      }

      &_dark {
        & > button {
          svg {
            fill: #fff;
          }
        }
      }
    }

    .first,
    .second,
    .third,
    .fourth {
      margin-right: 10px;
    }

    .fifth {
      &::after {
        display: none;
        border: none;
      }
    }

    .header_picker {
      position: relative;
      height: 38px;
      width: 130px;
      background: $app_white_absolute;
      border: 1px solid #e2e8f0;
      border-radius: 6px;
      padding: 0 16px;
      box-sizing: border-box;

      &_selected {
        position: relative;
        width: calc(100% - 24px);
        margin-right: 4px;
        justify-content: flex-start;
        cursor: pointer;

        & > p {
          width: 100%;
          text-overflow: ellipsis;
          white-space: nowrap;
          font-size: 95%;
        }
      }

      .icon {
        position: relative;
        height: 20px;
        width: 20px;
        background: transparent;
        border: none;
        box-shadow: none;
        cursor: pointer;
        transition: transform 0.1s ease-in;

        svg {
          height: 16px;
          width: 16px;
        }

        &_active {
          transform: rotate(-180deg);
        }

        &_inactive {
          transform: rotate(0deg);
        }

        &_dark {
          svg {
            fill: $app_darkmode_text1;
            path {
              fill: $app_darkmode_text1;
            }
          }
        }
      }

      &_dark {
        background: #28303b;
        border: 1px solid #28303b;
      }
    }

    .icon_button {
      position: relative;
      padding: 0;
      height: 30px;
      width: 30px;
      margin-right: 2px;

      &_active {
        background: rgba($color: $app_darkmode_text1, $alpha: 0.25) !important;
      }

      &_dark {
        svg {
          fill: #fff;
          path {
            fill: #fff;
          }
        }

        &:hover {
          background: rgba($color: $app_darkmode_text1, $alpha: 0.05);
        }
      }

      &_dark_active {
        background: rgba($color: $app_darkmode_text1, $alpha: 0.1) !important;

        &:hover {
          background: rgba(
            $color: $app_darkmode_text1,
            $alpha: 0.08
          ) !important;
        }

        svg {
          fill: rgba($color: #fff, $alpha: 0.9);

          path {
            fill: rgba($color: #fff, $alpha: 0.9);
          }
        }
      }
    }
  }

  .set_link {
    position: absolute;
    top: calc(100% + 4px);
    left: 30%;
    padding: 8px 16px;
    border-radius: 8px;
    -webkit-box-shadow: 0px 4px 8px rgba(16, 24, 64, 0.05);
    box-shadow: 0px 4px 8px rgba(16, 24, 64, 0.05);
    background: $app_white_absolute;
    border: 1px solid #e6e8f0;

    & > input[type='text'] {
      width: 160px;
      font-size: 100%;
    }

    &_dark {
      background: #1a1e24;
      border: 1px solid transparent;
      -webkit-box-shadow: none;
      box-shadow: none;

      & > input[type='text'] {
        background: transparent !important;
        border: 2px solid #28303b;
        color: $app_darkmode_text1;

        &:focus,
        &:active {
          background: transparent;
        }
      }
    }

    .actions {
      position: relative;
      margin-left: 8px;

      & > button {
        padding: 4px 6px;
      }

      & > button:nth-child(2) {
        margin-left: 8px;
        color: $app_black_shade3;

        & > p {
          color: $app_black_shade3;
        }
      }

      &_dark {
        & > button:nth-child(2) {
          background: rgba($color: $app_darkmode_text1, $alpha: 0.1);

          &:hover {
            background: rgba($color: $app_darkmode_text1, $alpha: 0.05);
          }

          color: $app_darkmode_text1;

          & > p {
            color: $app_darkmode_text1;
          }
        }
      }
    }

    &_scroll {
      left: 35%;
    }
  }

  .set_link_button {
    &_cover {
      position: absolute;
    }
  }

  .align {
    position: absolute;
  }

  .align_options {
    position: absolute;
    top: calc(100% - 10px);
    left: 50%;
    padding: 8px 16px;
    border-radius: 8px;
    -webkit-box-shadow: 0px 4px 8px rgba(16, 24, 64, 0.05);
    box-shadow: 0px 4px 8px rgba(16, 24, 64, 0.05);
    background: $app_white_absolute;
    border: 1px solid #e6e8f0;

    & > div {
      margin: 0 4px;
      padding: 4px;
      box-sizing: border-box;
      cursor: pointer;

      svg {
        height: 25px;
        width: 25px;
      }
    }

    &_dark {
      -webkit-box-shadow: none;
      box-shadow: none;
      background: #1a1e24;
      border: 1px solid transparent;
    }
  }

  .align_option_inactive {
    & > svg {
      opacity: 0.6;
    }
  }

  .align_option_active {
    & > svg {
      opacity: 1;
    }

    &_dark {
      svg {
        fill: #fff;
        path {
          fill: #fff;
        }
      }
    }
  }

  .header_options {
    position: absolute;
    top: calc(100% + 2px);
    display: inline-block;
    left: 0;
    width: 200px;
    z-index: calc(#{$high_front} + 5);

    & > div:nth-child(1) {
      position: relative;
      align-items: flex-start;
      border-radius: 4px;
      background: #ffffff;
      border: 1px solid rgba(113, 128, 150, 0.05);
      -webkit-box-shadow: 0px 4px 8px rgba(16, 24, 64, 0.05);
      box-shadow: 0px 4px 8px rgba(16, 24, 64, 0.05);
      padding: 8px 16px;
      padding-bottom: 16px;

      & > div {
        margin-top: 8px;
        cursor: pointer;
      }

      & > div:nth-child(1) {
        font-size: 170%;
        font-family: 'WorkSans-Bold';
      }

      & > div:nth-child(2) {
        font-size: 120%;
        font-family: 'WorkSans-Medium';
      }
    }

    &_dark {
      & > div:nth-child(1) {
        background: #1a1e24;
        border: 1px solid transparent;
        -webkit-box-shadow: none;
        box-shadow: none;

        & > div {
          color: #fff;
        }
      }
    }
  }

  &_scroll {
    position: fixed;
    top: 64px;
    width: 100vw;
    margin-top: 0;
    left: 0;
    box-sizing: border-box;

    .content {
      justify-content: center;
      padding-top: 2px;
      padding-left: 0;
      padding-right: 50px;
      overflow: unset;
    }

    .header_options {
      left: 25%;
    }
  }

  &_inline {
    position: relative;
    width: 100%;
    margin-top: 32px;
    box-sizing: border-box;

    .content {
      overflow: hidden;
      overflow-x: scroll;

      @include custom_scroll;
    }
  }
}

@media screen and (min-width: 1900px) {
  .formats_toolbar {
    &_scroll {
      .header_options {
        left: 28%;
      }
    }
  }
}

@media screen and (max-width: 1200px) {
  .formats_toolbar {
    &_scroll {
      .header_options {
        left: 22%;
      }
    }
  }
}

@media screen and (max-width: 900px) {
  .formats_toolbar {
    height: 66px;
    box-sizing: border-box;

    &_scroll,
    &_inline {
      position: fixed;
      bottom: 0;
      left: 0;
      width: 100%;
      margin: 0;
      padding: 0;

      .header_options {
        left: 8px;
      }

      .content {
        justify-content: center;
        padding-left: 0;
        padding-right: 0;
        padding-top: 6px;
        padding-bottom: 2px;
        box-sizing: border-box;
        overflow: hidden;
        overflow-x: scroll;

        @include custom_scroll;

        &::-webkit-scrollbar {
          width: 5px !important;
        }

        &::-moz-scrollbar {
          width: 5px !important;
        }

        &::-webkit-scrollbar-thumb {
          visibility: hidden !important;
          opacity: 0 !important;
        }

        &::-moz-scrollbar-thumb {
          visibility: hidden !important;
          opacity: 0 !important;
        }

        &:hover {
          &::-webkit-scrollbar-thumb {
            visibility: hidden !important;
            opacity: 0 !important;
          }

          &::-moz-scrollbar-thumb {
            visibility: hidden !important;
            opacity: 0 !important;
          }
        }
      }
    }

    .header_options {
      top: unset;
      left: 8px;
      right: unset;
      bottom: calc(100% + 1px);
    }

    .content {
      .header_picker {
        width: 100px;

        .icon {
          &_active {
            transform: rotate(0deg);
          }

          &_inactive {
            transform: rotate(-180deg);
          }
        }
      }

      .icon_button {
        &:hover,
        &:active {
          background: transparent !important;
        }

        &_active {
          background: rgba(
            $color: $app_darkmode_text1,
            $alpha: 0.25
          ) !important;
        }
      }

      .fourth {
        .tables {
          // todo
          // no tables mobile
          // display: none;
        }
      }
    }

    .set_link {
      top: unset;
      bottom: calc(100% + 14px);
      left: 25%;
    }

    .align_options {
      top: unset;
      left: 25%;
      bottom: calc(100% + 10px);
    }
  }
}

@media screen and (max-width: 660px) {
  .formats_toolbar {
    .content {
      display: flex;
      justify-content: center;

      .first {
        display: none;
      }
    }

    .set_link {
      & > input[type='text'] {
        width: 120px;
      }

      .actions {
        & > button {
          padding: 2px 4px;
        }
      }
    }
  }
}

@media screen and (max-width: 570px) {
  .formats_toolbar {
    display: flex;
    justify-content: center;
    align-items: center;

    .content {
      display: flex;
      justify-content: center;
      align-items: center;
      padding-bottom: 4px;

      .first,
      .third {
        display: none;
      }
    }
  }
}

@media screen and (max-width: 460px) {
  .formats_toolbar {
    .content {
      padding-left: 40px;
      box-sizing: border-box;
    }
  }
}

@media screen and (max-width: 400px) {
  .formats_toolbar {
    .content {
      padding-left: 60px;

      .second {
        .underline_icon {
          display: none;
        }
      }
    }
  }
}

@media screen and (max-width: 340px) {
  .formats_toolbar {
    .content {
      padding-left: 90px;
      box-sizing: border-box;
    }
  }
}
