@import '../../theme/commons.module.scss';
@import '../../theme/color.scss';

.wrapper {
  // no css worth putting yet
  box-sizing: border-box;
}

@media screen and (max-width: 900px) {
  .wrapper {
    width: 100%;
    flex-basis: 100%;
    overflow-x: hidden;
  }
}
