@import '../../../theme/color.scss';
@import '../../../theme/assets.scss';
@import '../../../theme/commons.module.scss';

.comment_entity {
  position: relative;
  width: 100%;
  padding: 10px 6px;
  padding-bottom: 8px;
  box-sizing: border-box;
  align-items: flex-start;

  .comment_avatar,
  .content {
    position: relative;
    box-sizing: border-box;
  }

  .comment_avatar {
    margin-left: 4px;

    & > div {
      color: #fff !important;
    }
  }

  .content {
    margin-left: 12px;
    vertical-align: top;
    width: calc(100% - 70px);
    // border: 2px solid red;
    box-sizing: border-box;

    .name {
      position: relative;
      margin-top: 2px;
      width: 100%;

      & > h5:nth-child(1) {
        position: relative;
        font-weight: unset;
        font-family: 'WorkSans-Medium';
        font-size: 92%;
        display: inline-block;
        vertical-align: middle;
        margin-right: 4px;
      }

      & > div:nth-child(2),
      & > div:nth-child(3) {
        position: relative;
        display: inline-block;
        vertical-align: middle;
      }
    }

    .created {
      position: relative;
      margin-top: 2px;
      font-weight: unset;
      display: inline-block;
      vertical-align: middle;
      font-size: 80%;

      p,
      span {
        font-weight: unset;
        font-family: 'WorkSans-Medium';
      }
    }

    .edited {
      position: relative;
      display: inline-block;
      margin-top: 2px;
      vertical-align: middle;
      margin-left: 5px;
      font-weight: unset;
      font-family: 'WorkSans-SemiBold';
    }

    & > textarea {
      min-height: 35px;
      resize: none;
      font-size: 85%;
      font-family: 'WorkSans-SemiBold';
    }

    & > textarea:nth-child(4) {
      position: relative;
      margin-top: 10px;
      width: 100%;
      overflow: hidden;
      background: transparent;
      box-sizing: border-box;
      margin-left: -2px;
    }

    & > textarea:nth-child(5) {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      overflow: scroll;
      resize: none;
      visibility: hidden;
      padding: 0 4px;
    }

    .no_edit {
      border: none;
      outline: none;
    }

    .is_editing {
      outline: unset;
      border-radius: 3px;
      padding: 0 4px;
      border: 1.5px solid rgba($color: $app_gray_shade4, $alpha: 0.7);
    }
  }

  .comment_input {
    position: relative;
    width: 100%;
    box-sizing: border-box;
    margin-top: 12px;
  }

  .spinner_wrap {
    position: relative;
    display: inline-block;
    text-align: left;
    margin-left: 52px;
    margin-top: 10px;

    .raw {
      position: relative;
      display: inline-block;
      text-align: center;
    }

    &_dark {
      svg {
        circle {
          stroke: $app_white_light;
        }
      }
    }
  }
}
