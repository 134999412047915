@import '../../theme/color.scss';
@import '../../theme/commons.module.scss';

.pdf_viewer {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  box-sizing: border-box;
  align-items: flex-start;

  .content {
    position: relative;
    height: 92vh;
    width: 50vw;
    margin-top: 3vh;
  }

  .close {
    position: absolute;
    top: 8px;
    right: 16px;
    z-index: calc(#{$high_front} + 7);

    & > button {
      height: 32px;
      width: 32px;
      background: #fbfcfe;
      padding: 0;
      box-sizing: border-box;
    }
  }
}

@media screen and (max-width: 900px) {
  .pdf_viewer {
    justify-content: center;

    .content {
      margin-top: 0%;
      height: 100%;
      width: 99.9%;
      box-sizing: border-box;
      display: flex;
      justify-content: center;
    }

    .close {
      top: 1px;
      right: 2px;
    }
  }
}
