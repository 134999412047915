@import '../../theme/color.scss';
@import '../../theme/commons.module.scss';

@keyframes editFrame {
  0% {
    margin-left: calc(100% - 25px);
  }

  100% {
    margin-left: 3px;
  }
}

@keyframes viewFrame {
  0% {
    margin-left: 3px;
  }

  100% {
    margin-left: calc(100% - 25px);
  }
}

.view_edit_switch {
  position: relative;

  .track {
    position: relative;
    height: 28px;
    width: 78px;
    border-radius: 100px;
    cursor: pointer;
    justify-content: flex-start;

    &_edit {
      background: #6283fa;
    }

    &_view {
      background: #2d3748;
    }

    .circle {
      position: relative;
      height: 22px;
      width: 22px;
      background: $app_white_absolute;
      border-radius: 50%;

      &_view {
        margin-left: calc(100% - 25px);
        animation: ease;
        animation-name: viewFrame;
        animation-duration: 0.3s;
      }

      &_edit {
        margin-left: 3px;
        animation: ease;
        animation-name: editFrame;
        animation-duration: 0.3s;
      }
    }

    .text {
      position: absolute;
      height: 100%;
      top: 0;
      left: 0;
      box-sizing: border-box;

      & > p {
        line-height: 100%;
        font-size: 95%;
        color: $app_white_absolute;
        font-weight: unset;
        font-family: 'WorkSans-Regular';
      }

      &_edit {
        left: 38px;
      }

      &_view {
        left: 13px;
      }
    }
  }
}

@media screen and (max-width: 450px) {
  .view_edit_switch {
    .track {
      width: 64px;

      .circle {
        height: 20px;
        width: 20px;

        svg {
          height: 14px;
          width: 14px;
        }
      }

      .text {
        & > p {
          font-size: 85%;
        }

        &_edit {
          left: 30px;
        }

        &_view {
          left: 9px;
        }
      }
    }
  }
}
