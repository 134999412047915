@import '../../theme/commons.module.scss';
@import '../../theme/color.scss';

.login {
  position: relative;
  width: 100%;
  flex-direction: column;
  align-items: flex-start;

  .logo {
    width: 100%;
    position: relative;
  }

  .message {
    position: relative;
    width: 100%;
    box-sizing: border-box;
    padding: 0 16px;
    margin: 16px 0;

    & > h2 {
      position: relative;
      font-weight: unset;
      font-family: 'WorkSans-SemiBold';
      font-size: 36px;
    }

    &_dark {
      & > h2 {
        color: #fff;
      }
    }
  }

  .username {
    margin-top: 25px;
    justify-content: flex-start;
  }

  .username,
  .password {
    position: relative;
    padding: 0 16px;
    width: 100%;
    box-sizing: border-box;

    & > div:nth-child(1) {
      position: relative;
      margin-bottom: 15px;
      width: 100%;

      div {
        & > p:nth-child(2) {
          box-sizing: border-box;
          font-family: 'WorkSans-Medium' !important;
          font-size: 90% !important;
          width: calc(100% - 30px) !important;
        }
      }

      label {
        font-weight: unset;
        font-family: 'WorkSans-Medium' !important;
        font-size: 90% !important;
      }
    }

    input[type='text'],
    input[type='password'] {
      font-size: 80% !important;
      font-weight: unset;
      font-family: 'WorkSans-Regular' !important;
      height: 40px;
    }
  }

  .password {
    input[type='text'],
    input[type='password'] {
      padding-right: 40px;
      box-sizing: border-box;
    }

    &_show {
      position: absolute;
      top: 36px;
      right: 26px;
      box-sizing: border-box;

      svg {
        fill: $app_gray_shade4;

        path {
          fill: $app_gray_shade4;
        }
      }
    }
  }

  .username,
  .password {
    &_dark {
      label,
      span {
        color: $app_darkmode_text1 !important;
      }

      input[type='text'],
      input[type='password'] {
        background: transparent;
        border: 1px solid #28303b;
        color: $app_darkmode_text1;

        &:focus,
        &:active {
          background: transparent;
        }
      }
    }
  }

  .actions {
    position: relative;
    display: inline-block;
    padding: 0 15px;
    width: 100%;
    box-sizing: border-box;

    .remember_login {
      position: relative;
      display: inline-block;
      width: 100%;
      box-sizing: border-box;

      svg {
        path {
          fill: white;
        }
      }

      label {
        span {
          font-weight: unset;
          font-family: 'WorkSans-Regular' !important;
        }
      }

      &_dark {
        label {
          & > div {
            background: transparent !important;

            svg {
              fill: transparent !important;
              path {
                fill: transparent !important;
              }
            }
          }
        }

        p {
          font-weight: unset;

          color: rgba($color: $app_darkmode_text1, $alpha: 0.7);
          & > a,
          & > span {
            color: $app_darkmode_text1;
          }
        }

        span {
          color: $app_darkmode_text1 !important;
        }
      }

      &_active_dark {
        label {
          & > div {
            background: $app_blue_shade3 !important;

            svg {
              fill: $app_white_shade3 !important;

              path {
                fill: $app_white_shade3 !important;
              }
            }
          }
        }

        span {
          color: $app_darkmode_text1 !important;
        }
      }

      &_light {
        color: $app_black_shade1 !important;

        p {
          color: $app_black_shade1 !important;
        }

        & > a,
        & > span {
          color: $app_black_shade1;
        }

        span {
          color: $app_black_shade1;
        }
      }
    }

    .status {
      position: relative;
      width: 100%;
      display: inline-block;
      text-align: center;
      margin: 16px 0;

      & > div:nth-child(1) {
        display: inline;
        text-align: center;

        & > h5 {
          font-weight: unset;
          font-family: 'WorkSans-Medium';
          color: #df3e4a;
        }
      }
    }

    .forgot_password {
      position: relative;
      width: 100%;
      display: inline-block;
      text-align: center;
      margin-top: 17px;
      font-size: 100%;

      & > button {
        position: relative;
        text-align: center;
        font-size: 100%;
        font-weight: unset;
        font-family: 'WorkSans-Medium';
        cursor: pointer;
      }

      &_dark {
        & > button {
          color: $app_darkmode_text1 !important;
          background: transparent !important;

          &:hover,
          &:active {
            background: rgba(
              $color: $app_darkmode_text1,
              $alpha: 0.2
            ) !important;
          }
        }
      }
    }

    .redirect_signup {
      position: relative;
      width: 100%;
      text-align: center;
      margin-top: 14px;
      box-sizing: border-box;

      & > p {
        text-align: center;
        font-size: 100%;
        font-family: 'WorkSans-Medium';

        span {
          cursor: pointer;
          font-family: 'WorkSans-Medium';

          &:hover {
            text-decoration: underline;
          }
        }
      }

      &_dark {
        & > p {
          color: $app_darkmode_text1;
          span {
            color: $app_darkmode_text1;
          }
        }
      }
    }

    .redirect {
      position: relative;
      width: 100%;
      margin-top: 15px;
      display: inline-block;
      text-align: center;

      & > h5 {
        font-weight: unset;
        font-family: 'WorkSans-Medium';
        line-height: 30px;
        text-align: center;

        p {
          color: $app_gray_shade2;

          a {
            color: $app_gray_shade2;
            text-decoration: none;

            &:hover {
              text-decoration: underline;
            }
          }
        }
      }

      &_dark {
        & > h5 {
          color: $app_darkmode_text1;

          p {
            color: $app_darkmode_text1;

            a {
              color: $app_darkmode_text1;
            }
          }
        }
      }
    }

    .confirm {
      position: relative;
      width: 100%;
      height: 40px;
      font-size: 100%;
      margin-top: 16px;

      & > p,
      & > h5 {
        font-size: 90%;
        font-family: 'WorkSans-Regular';
      }

      &_with_status {
        margin-top: 12px;
      }

      &_without_status {
        margin-top: 25px;
      }
    }
  }
}
