@import '../../theme/color.scss';
@import '../../theme/commons.module.scss';

.not_found {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  box-sizing: border-box;
  z-index: calc(#{$high_front} + 4);

  &_background {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    box-sizing: border-box;

    & > img {
      position: relative;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    &_dark {
      background: #13171c;
    }
  }

  .content {
    position: relative;
    width: 450px;
    padding: 50px;
    box-sizing: border-box;
    background: $app_white_absolute;
    -webkit-box-shadow: 0px 4px 50px rgba(16, 24, 64, 0.04);
    -moz-box-shadow: 0px 4px 50px rgba(16, 24, 64, 0.04);
    box-shadow: 0px 4px 50px rgba(16, 24, 64, 0.04);
    border-radius: 10px;

    &_dark {
      border: 0.5px solid rgba($color: $app_darkmode_text1, $alpha: 0.1);
      -moz-box-shadow: none;
      -webkit-box-shadow: none;
      box-shadow: none;
      background: $app_darkmode_background_header1;
    }

    .logo {
      position: relative;
      width: 100%;
      display: flex;
      justify-content: flex-start;
    }

    .texts {
      position: relative;
      width: 100%;
      margin-top: 24px;

      & > h2 {
        position: relative;
        font-size: 34px;
        font-weight: bold;
        font-family: 'WorkSans-Bold';
        color: $app_black_shade1;
      }

      & > p:nth-child(2) {
        position: relative;
        color: #718096;
        font-size: 16px;
        font-weight: unset;
        font-family: 'WorkSans-Medium';
        margin: 4px 0;
      }

      & > section {
        position: relative;
        width: 100%;
        margin-top: 16px;

        & > p {
          color: #2d3748;
          font-size: 14px;
        }

        & > ul {
          position: relative;
          width: 100%;
          margin-top: 8px;
          padding-left: 20px;
          box-sizing: border-box;

          & > li {
            position: relative;
            margin-top: 8px;
            font-size: 14px;
            color: #2d3748;
          }
        }

        & > p:last-child {
          margin-top: 16px;
        }
      }

      &_dark {
        & > h2 {
          color: #fff;
        }

        & > p:nth-child(2) {
          color: $app_darkmode_text1;
        }

        & > section {
          & > p {
            color: $app_darkmode_text1;
          }

          & > ul {
            & > li {
              color: $app_darkmode_text1;
            }
          }
        }
      }
    }

    .cta {
      position: relative;
      width: 100%;
      margin-top: 24px;

      & > button {
        position: relative;
        height: 44px;
        width: 100%;
        padding: 10px 24px;
        box-sizing: border-box;
        background: #6283fa;
        border-radius: 6px;

        & > p {
          font-family: 'WorkSans-SemiBold';
          font-size: 17px;
          color: $app_white_absolute;
        }
      }

      .return {
        position: relative;
        margin-top: 16px;
        justify-content: flex-start;

        & > a {
          color: #4769e4;
          font-size: 16px;
          font-weight: unset;
          font-family: 'WorkSans-Medium';
          text-decoration: none;

          &:hover {
            text-decoration: underline;
          }
        }

        & > svg {
          fill: #4769e4;
          margin-left: 6px;
          margin-top: 1px;
        }
      }
    }
  }
}

@media screen and (max-width: 480px) {
  .not_found {
    .content {
      width: 90vw;
    }
  }
}
