@import '../../theme/commons.module.scss';
@import '../../theme/color.scss';

.wrapper {
  display: flex;
  flex-direction: column;
  border-right: 1.2px solid rgba(224, 224, 224, 0.6);
  border-bottom: 1.2px solid rgba(224, 224, 224, 0.6);
  min-height: 100%;
  box-sizing: border-box;
}

@media screen and (max-width: 900px) {
  .wrapper {
    position: relative;
    width: 100%;
  }
}
