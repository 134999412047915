@import '../theme/color.scss';
@import '../theme/commons.module.scss';

.container {
  position: relative;
  min-height: 100%;
  width: 100%;
  top: 0;
  margin: 0;
  box-sizing: border-box;
}

.darkmode_theme {
  background: $app_darkmode_background1;

  * {
    color: $app_darkmode_text1;
  }
}

@media screen and (max-width: 900px) {
  .container {
    position: absolute;
    width: 100%;
    height: 100%;
    min-height: unset;
    max-height: unset;
    padding-top: 0;
    top: 0;
    left: 0;
    box-sizing: border-box;
    overflow: hidden;
    overflow-y: scroll;

    &_ios {
      background-origin: border-box;

      &_mobile_app {
        background-origin: border-box;
        padding-top: 40px;
      }
    }
  }
}
