@import '../../../theme/commons.module.scss';
@import '../../../theme/color.scss';
@import '../layout/commons.module.scss';

.pricing_wrap {
  position: relative;
  width: 100%;
  padding-top: 40px;
  padding-bottom: 20px;
  box-sizing: border-box;
  margin-top: 80px;
  min-height: 200px;
  display: inline-block;
  text-align: center;
  margin-bottom: 40px;

  .pricing_wrap_gradient_background {
    position: absolute;
    width: 100%;
    top: 0;
    left: 0;
    overflow: visible;
    box-sizing: border-box;

    & > img {
      position: relative;
      height: 600px;
      width: 100%;
      top: 0;
      left: 0;
      object-position: center;
      object-fit: cover;
    }
  }

  .pricing_title {
    position: relative;
    width: 100%;
    box-sizing: border-box;

    & > h1 {
      position: relative;
      font-style: normal;
      font-family: 'WorkSans-SemiBold';
      font-size: 64px;
      line-height: 87px;
      text-align: center;
      // border: 1px solid red;
      box-sizing: border-box;
      display: inline-block;
      text-align: left;
      max-width: 78%;
      color: #2d3748;
      color: $app_blue_shade1;
    }
  }

  .list {
    position: relative;
    width: 100%;
    margin-top: 40px;
    margin-bottom: 30px;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: flex-start;

    & > ul {
      position: relative;
      list-style-type: none;
      list-style: none;
      display: flex;
      justify-content: center;
      align-items: flex-start;
      width: 950px;

      & > li {
        position: relative;
        margin-right: 16px;
      }
    }
  }

  .pricing_custom,
  .price_value {
    position: relative;
    width: 100%;
    margin-top: 24px;
    height: 100px;
  }

  .pricing_custom {
    text-align: left;

    & > h5 {
      font-family: 'WorkSans-SemiBold';
      font-size: 75%;
      line-height: 170%;
      color: $app_white_absolute;
    }

    & > h3 {
      font-weight: unset;
      font-family: 'WorkSans-Bold';
      font-size: 116%;
      line-height: 25px;
      color: $app_white_absolute;
    }
  }

  .price_value {
    .rate {
      position: relative;
      width: 100%;
      text-align: left;

      & > h4 {
        position: relative;
        color: $app_black_shade4;
        font-weight: unset;
        font-family: 'WorkSans-SemiBold';
        font-size: 120%;
      }

      span {
        color: $app_black_shade4;
        font-weight: unset;
        font-family: 'WorkSans-Medium';
        font-size: 70%;
        margin-left: 4px;
      }
    }

    .rate_desc {
      position: relative;
      width: 100%;
      box-sizing: border-box;
      margin-top: 4px;
      // border: 1px solid red;

      & > p {
        text-align: left;
        font-size: 80%;
        color: $app_black_shade4;
        opacity: 0.8;
      }
    }
  }

  .pricing_card {
    position: relative;
    background: $app_white_absolute;
    border: 1px solid #e2e8f0;
    box-sizing: border-box;
    border-radius: 15px;
    min-height: 860px;
    width: 300px;
    padding: 32px 24px;
    padding-bottom: 50px; //height 38px button + padding 22px;
    transition: border 0.1s ease;
    cursor: pointer;
    overflow: hidden;

    &:hover {
      border: 1px solid #e2e8ff;
    }

    .title {
      position: relative;
      text-align: left;
      font-weight: unset;
      font-family: 'WorkSans-Medium';
      font-size: 120%;
      line-height: 160%;
      color: $app_black_shade4;
    }

    .desc {
      position: relative;
      text-align: left;
      width: 100%;
      margin-top: 8px;
      font-weight: unset;
      font-family: 'WorkSans-Regular';
      font-size: 95%;
      line-height: 130%;
    }
  }

  .benefits {
    position: relative;
    width: 100%;
    margin-top: 32px;
    margin-bottom: 20px;
    box-sizing: border-box;

    & > ul {
      width: 100%;
      list-style: none;
      list-style-type: none;

      & > li {
        text-align: left;
        font-size: 105%;

        .check,
        .benefit_desc {
          position: relative;
          display: inline-block;
          text-align: center;
          vertical-align: top;
        }

        .check {
          height: 16px;
          width: 16px;

          svg {
            fill: $app_dominant_purple;
          }
        }

        .benefit_desc {
          margin-left: 14px;
          max-width: calc(100% - 30px);
          font-weight: unset;
          font-family: 'WorkSans-Regular';
          font-size: 88%;
          line-height: 110%;
          color: $app_black_shade4;
          text-align: left;
        }

        & > h5:nth-child(3) {
          position: relative;
          width: 100%;
          font-weight: unset;
          font-family: 'WorkSans-Regular';
          font-size: 75%;
          line-height: 110%;
          color: $app_black_shade4;
          box-sizing: border-box;
          padding-left: 32px;
        }
      }

      & > li:not(:nth-child(1)) {
        margin-top: 14px;
      }
    }
  }

  .benefits_free {
    margin-top: 50px;
  }

  .override_enterprise_card {
    background-color: #25187a;

    .title,
    .desc {
      color: $app_white_absolute;
    }

    .benefits {
      & > ul {
        li {
          svg {
            fill: $app_white_absolute;
          }

          .benefit_desc {
            color: $app_white_absolute;
          }
        }
      }
    }
  }

  .card_action {
    position: relative;
    width: 100%;
    padding: 0;
    box-sizing: border-box;
    margin: 8px 0;

    &_button {
      position: relative;
      height: 40px;
      width: 100%;
      padding: 0;
      box-sizing: border-box;
      display: flex;
      justify-content: center;
      align-items: center;
      background: #6283fa !important;
      color: #fff;

      &:active,
      &:hover {
        background: #6283fa !important;
        color: #fff;
        opacity: 0.9;
      }

      & > a,
      & > p {
        position: absolute;
        height: 100%;
        width: 100%;
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
        text-decoration: none;
        color: #fff;
        font-size: 110%;
        font-weight: unset;
        font-family: 'WorkSans-Regular';
      }

      svg {
        circle {
          stroke: #fff;
        }
      }
    }

    &_premium {
      position: relative;
      margin-top: 0;
    }

    &_personal {
      margin-top: 0;
    }

    &_soon {
      margin-top: 0;
    }
  }
}

@media screen and (min-width: $max_width_fix_home) {
  .pricing_wrap {
    margin-left: calc((100% - #{$max_width_fix_home}) / 2);
    width: $max_width_fix_home;
  }
}

@media screen and (max-width: 1200px) {
  .pricing_wrap {
    .list {
      width: 100%;
      text-align: center;
      box-sizing: border-box;

      & > ul {
        width: 100%;
        display: inline-block;
        text-align: center;

        & > li {
          // float: unset;
          display: inline-block;
          vertical-align: top;
          margin-top: 20px;
        }
      }
    }
  }
}

@media screen and (max-width: 900px) {
  .pricing_wrap {
    width: 100%;

    .pricing_title {
      & > h1 {
        font-size: 50px;
        max-width: 100%;
        padding: 4px 24px;
        line-height: 65px;
        box-sizing: border-box;
      }
    }

    .list {
      width: 100%;
      box-sizing: border-box;

      & > ul {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        box-sizing: border-box;

        & > li {
          float: unset;
          margin-right: 0;
          margin-top: 12px;
        }
      }
    }

    .pricing_card {
      width: 400px;
    }
  }
}

@media screen and (max-width: 420px) {
  .pricing_wrap {
    .pricing_card {
      width: 304px;
    }
  }
}
