@import '../../../../theme/commons.module.scss';
@import '../../../../theme/color.scss';
@import '../../layout/commons.module.scss';

$header_prompt_height: 35px;
$header_only_height: 80px;

.header_wrap {
  position: fixed;
  width: 100%;
  box-sizing: border-box;
  top: 0;
  border-bottom: 1.5px solid transparent;
  transition: border 0.1s ease-in;
  z-index: $high_front;
  background: transparent;

  &_scrolling {
    background: rgba($color: #fff, $alpha: 0.9);
    backdrop-filter: blur(2px);
    border-bottom: 1.2px solid rgba($color: $app_white_shade3, $alpha: 0.4);
    box-shadow: 0px 4px 50px rgba(230, 232, 240, 0.6);
    -moz-box-shadow: 0px 4px 50px rgba(230, 232, 240, 0.6);
    -webkit-box-shadow: 0px 4px 50px rgba(230, 232, 240, 0.6);
  }

  .header_raw {
    position: relative;
    height: 100%;
    width: 100%;

    &_content {
      position: relative;
      width: 100%;
      height: $header_only_height !important;

      .logo_wrap {
        position: absolute;
        top: 0;
        left: 22px;
        height: 50px;
        margin-top: calc((#{$header_only_height} - 50px) / 2);

        .link {
          position: absolute;
          top: 0;
          left: 0;

          a {
            text-decoration: none;
            position: absolute;
            height: 100%;
            width: 100%;
            left: 0;
            top: 0;
          }
        }

        a,
        p {
          color: $app_black_shade1;
        }
      }

      .actions_wrap {
        position: absolute;
        top: 0;
        height: 50px;
        right: 25px;
        margin-top: calc((#{$header_only_height} - 45px) / 2);

        .pricing {
          position: relative;
          height: 100%;
          margin-right: 4px;

          &_button_wrap {
            position: relative;
            padding: 0 20px;
            border: none;

            a {
              position: absolute;
              height: 100%;
              width: 100%;
              top: 0;
              left: 0;
              right: 0;
            }

            h2 {
              height: 100%;
              text-align: center;
              font-weight: unset;
              font-family: 'WorkSans-Regular';
              font-size: 130%;
            }
          }
        }

        .pricing,
        .login {
          &_button_wrap {
            background: transparent;

            button {
              background: transparent;
            }

            &:hover {
              background: transparent;
            }

            button,
            a,
            h2 {
              color: $app_black_shade1;
            }
          }
        }

        .login {
          position: relative;
          height: 100%;
          margin-right: 15px;

          &_button_wrap {
            position: relative;
            padding: 0 20px;
            border: none;

            h2 {
              height: 100%;
              text-align: center;
              font-weight: unset;
              font-family: 'WorkSans-Regular';
              font-size: 130%;
            }

            a {
              position: absolute;
              height: 100%;
              width: 100%;
              top: 0;
              left: 0;
            }
          }
        }

        .user_avatar {
          position: relative;
          height: 40px;
          width: 40px;
          border-radius: 50%;
          font-size: 105%;
          cursor: pointer;

          span {
            color: #fff;

            & > div {
              color: #fff;
            }
          }
        }

        .getting_started {
          position: relative;
          height: 100%;

          &_button {
            position: relative;
            height: 48px;
            padding: 10px 25px;
            border-radius: 100px;

            p {
              font-size: 120%;
              font-weight: unset;
              font-family: 'WorkSans-Regular';
            }
          }
        }
      }

      .actions_wrap_mobile {
        position: absolute;
        top: 0;
        height: 0;
        right: 15px;
        display: none;
        overflow: hidden;
        visibility: hidden;

        .login_mobile {
          position: relative;
          height: 100%;
          margin: 0;

          button {
            background: transparent;
          }

          &:hover {
            background: transparent;
          }

          &_button_wrap {
            position: relative;
            padding: 0 20px;
            border: none;

            h2 {
              text-align: center;
              font-weight: unset;
              font-family: 'WorkSans-Medium';
              font-size: 125%;
            }

            button,
            a,
            h2 {
              color: $app_black_shade1;
            }
          }
        }
      }
    }
  }

  &_noprompt {
    height: calc(#{$header_only_height}+ 10px);
  }

  &_prompt {
    height: calc(#{$header_prompt_height} + #{$header_only_height} + 10px);
  }
}

@media screen and (min-width: $max_width_fix_home) {
  .header_wrap {
    .header_raw {
      margin-left: calc((100% - #{$max_width_fix_home}) / 2);
      width: $max_width_fix_home;
    }
  }
}

@media screen and (max-width: 600px) {
  .header_wrap {
    .header_raw {
      position: relative;

      &_content {
        .logo_wrap {
          position: absolute;
          left: 5px;
          width: 180px;
        }

        .actions_wrap {
          .pricing {
            overflow: hidden;
            display: none;
          }

          .login {
            display: none;
            height: 0;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 430px) {
  .header_wrap {
    .header_raw {
      &_content {
        .actions_wrap {
          .getting_started,
          .pricing,
          .login {
            display: none;
            height: 0;
            overflow: hidden;
          }
        }

        .actions_wrap_mobile {
          display: flex;
          visibility: visible;
          height: 50px;
          margin-top: calc((#{$header_only_height} - 45px) / 2);
          overflow: unset;

          .login_mobile {
            &_button_wrap {
              border: none;

              .link {
                position: absolute;
                height: 100%;
                width: 100%;
                top: 0;
                left: 0;

                a {
                  position: absolute;
                  height: 100%;
                  width: 100%;
                  top: 0;
                  left: 0;
                  text-decoration: none;
                }
              }
            }
          }
        }
      }
    }
  }
}
