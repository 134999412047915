@import '../../theme/color.scss';
@import '../../theme/commons.module.scss';

.checkbox {
  position: relative;
  box-sizing: border-box;
  cursor: pointer;

  input[type='checkbox'] {
    position: relative;
    height: 18px;
    width: 18px;
    box-sizing: border-box;
    -moz-appearance: none;
    -webkit-appearance: none;
    -o-appearance: none;
    border: none;
    outline: none;
    box-shadow: none;
    background: transparent;
    border: 2px solid #e2e8f0;
    cursor: pointer;

    &:checked {
      border: 2px solid #4769e4;
      background-color: #4769e4;
      background: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjAiIGhlaWdodD0iMjAiIHZpZXdCb3g9IjAgMCAyMCAyMCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHJlY3Qgd2lkdGg9IjIwIiBoZWlnaHQ9IjIwIiByeD0iMiIgZmlsbD0iIzQ3NjlFNCIvPgo8cGF0aCBkPSJNOC42NjY0OSAxMi4xMTVMMTQuNzk0NSA1Ljk4NjMzTDE1LjczNzggNi45Mjg5OUw4LjY2NjQ5IDE0LjAwMDNMNC40MjM4MyA5Ljc1NzY2TDUuMzY2NDkgOC44MTQ5OUw4LjY2NjQ5IDEyLjExNVoiIGZpbGw9IndoaXRlIi8+Cjwvc3ZnPgo=);
      background-position: center;
      background-size: 105% 105%;
      background-repeat: no-repeat;
    }
  }

  & > p,
  & > h5 {
    position: relative;
    font-size: 95%;
    margin-left: 8px;
    text-align: left;
    font-weight: unset;
    font-family: 'WorkSans-Regular';
    max-width: calc(100% - 30px);
  }

  & > h5 {
    & > p {
      position: relative;
      font-size: 100%;
      text-align: left;
      font-weight: unset;
      font-family: 'WorkSans-Regular';
      max-width: 100%;
    }
  }

  &_with_label {
    justify-content: flex-start;
  }

  &_light {
    p,
    h5 {
      color: #323333 !important;
    }
  }
}

.checkbox_dark {
  input[type='checkbox'] {
    border-width: 1px;

    &:checked {
      border: 1px solid #4769e4;
      background-color: #4769e4;
      background: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjAiIGhlaWdodD0iMjAiIHZpZXdCb3g9IjAgMCAyMCAyMCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHJlY3Qgd2lkdGg9IjIwIiBoZWlnaHQ9IjIwIiByeD0iMiIgZmlsbD0iIzQ3NjlFNCIvPgo8cGF0aCBkPSJNOC42NjY0OSAxMi4xMTVMMTQuNzk0NSA1Ljk4NjMzTDE1LjczNzggNi45Mjg5OUw4LjY2NjQ5IDE0LjAwMDNMNC40MjM4MyA5Ljc1NzY2TDUuMzY2NDkgOC44MTQ5OUw4LjY2NjQ5IDEyLjExNVoiIGZpbGw9IndoaXRlIi8+Cjwvc3ZnPgo=);
      background-position: center;
      background-size: 110% 110%;
      background-repeat: no-repeat;
    }
  }

  & > p,
  & > h5 {
    color: $app_darkmode_text1 !important;
  }

  & > h5 {
    color: $app_darkmode_text1;

    & > p {
      color: $app_darkmode_text1;

      a,
      span {
        color: #fff;
        text-decoration: none;
      }

      a {
        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
}
