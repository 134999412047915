@import '../../theme/color.scss';
@import '../../theme/commons.module.scss';

.theme_switch {
  position: relative;
  padding: 8px 0px;
  border-radius: 8px;
  cursor: pointer;

  .track {
    position: relative;
    height: 28px;
    width: 58px;
    padding: 0 2px;
    box-sizing: border-box;
    border-radius: 100px;
    cursor: pointer;
    justify-content: space-between;

    &_light {
      -moz-box-shadow: none;
      -webkit-box-shadow: none;
      box-shadow: none;
      background: #2d3748;
    }

    &_dark {
      -moz-box-shadow: none;
      -webkit-box-shadow: none;
      box-shadow: none;
      background: #fff;
    }
  }

  .mode {
    position: relative;
    height: 24px;
    width: 24px;
    border-radius: 50%;
    overflow: hidden;
  }

  .moon {
    &_dark {
      background: #2d3748;

      svg {
        path {
          fill: #fff;
        }
      }
    }

    &_light {
      background: transparent;

      svg {
        path {
          fill: #fff;
        }
      }
    }
  }

  .sun {
    &_dark {
      background: transparent;

      svg {
        path {
          fill: #2d3748;
        }
      }
    }

    &_light {
      background: #fff;

      svg {
        path {
          fill: #2d3748;
        }
      }
    }
  }
}
