@import '../../../theme/color.scss';
@import '../../../theme/commons.module.scss';

.faster_search {
  position: relative;
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;

  &_raw {
    position: relative;
    flex-direction: column;
    justify-content: center;
    box-sizing: border-box;
    overflow: hidden;
    width: 1280px;
    min-height: 400px;
  }

  .content {
    position: relative;
    width: 100%;
    max-height: 384px;
    min-height: 384px;
    padding: 16px 20px;
    border-radius: 30px;
    box-sizing: border-box;
    background: linear-gradient(
      298.34deg,
      rgba(121, 58, 200, 0.06) 6.09%,
      rgba(152, 181, 255, 0.21) 48.16%,
      rgba(240, 242, 249, 0.25) 87.39%
    );

    .image,
    .texts {
      position: relative;

      & > p {
        position: relative;
        width: 100%;
        font-size: 110%;
        margin-top: 16px;
        line-height: 140%;
      }

      & > h1 {
        position: relative;
        font-size: 320%;
        width: 100%;
        font-weight: unset;
        font-family: 'WorkSans-Bold';
      }
    }

    .image {
      width: calc(60% - 20px);

      & > img {
        position: relative;
        height: auto;
        max-width: 100%;
        object-fit: contain;
        object-position: center;
      }
    }

    .texts {
      width: 35%;
      margin-right: 10px;
    }
  }
}

@media screen and (max-width: 1300px) {
  .faster_search {
    &_raw {
      width: 95vw;
    }
  }
}

@media screen and (max-width: 1150px) {
  .faster_search {
    &_raw {
      width: 90vw;
    }
  }
}

@media screen and (max-width: 920px) {
  .faster_search {
    &_raw {
      width: 92vw;
    }
  }
}
@media screen and (max-width: 900px) {
  .faster_search {
    &_raw {
      .content {
        flex-direction: column;
        padding: 30px 30px;
        max-height: unset;
        min-height: 384px;

        .image,
        .texts {
          width: 100%;
        }

        .texts {
          margin-right: 0;

          & > h1 {
            font-size: 260%;
          }

          & > p {
            font-size: 105%;
          }
        }

        .image {
          margin: 0;
          margin-top: 8px;
          width: 100%;
          min-width: 100%;
        }
      }
    }
  }
}

@media screen and (max-width: 420px) {
  .faster_search {
    &_raw {
      .content {
        .texts {
          & > h1 {
            font-size: 250%;
          }

          & > p {
            font-size: 95%;
          }
        }

        .image {
          width: 110%;
        }
      }
    }
  }
}
