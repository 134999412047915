@import '../../theme/color.scss';
@import '../../theme/commons.module.scss';

@mixin editor_component {
  & > p {
    & > strong {
      font-size: 105%;
      font-family: 'WorkSans-SemiBold';
    }
  }

  & > p {
    font-size: 100%;
    line-height: 165%;
  }

  & > blockquote {
    padding-top: 4px;
    padding-bottom: 6px;
    padding-left: 24px;
    border-left: 8px solid #e3e9fb;
  }

  span[data-type='mention'] {
    color: $app_dominant_purple;
    font-weight: unset;
    font-family: 'WorkSans-Medium';
  }

  div[data-type='video'] {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 4px 0;

    video {
      width: 400px;
      height: 260px;
    }
  }

  div[data-type='pdfviewer'] {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px 8px;
    background: rgba(145, 175, 220, 0.05);
    box-sizing: border-box;

    & > div {
      display: flex;
      justify-content: flex-start;
    }

    & > div:nth-child(1) {
      width: calc(65% - 8px);

      & > p,
      & > a {
        font-size: 95%;
        font-weight: unset;
        font-family: 'WorkSans-Medium';
        max-width: 100%;
        text-align: left;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        color: #718096;
      }

      & > a {
        text-decoration: none;
      }
    }

    & > div:nth-child(2) {
      display: flex;
      justify-content: flex-end;
      width: calc(35% - 8px);

      & > button {
        display: flex;
        justify-content: center;
        align-items: center;
        background: none;
        border: none;
        -webkit-box-shadow: none;
        box-shadow: none;
        cursor: pointer;
        height: 34px;
        width: 34px;
        position: relative;

        div {
          position: absolute;
          height: 34px;
          width: 34px;
          top: 0;
          left: 0;
        }
      }
    }
  }

  div[data-type='folder'] {
    width: 100%;
    display: flex;
    justify-content: space-between;

    & > div {
      position: relative;
      display: flex;
      justify-content: flex-start;
      align-items: center;
    }
  }

  & > img {
    object-fit: contain;
    object-position: center;
    width: 320px;
    height: auto;
    margin-left: calc((100% - 380px) / 2);
    cursor: zoom-in;
  }

  div[data-type='loomPreview'] {
    position: relative;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-start;
  }

  div[data-type='twitter'] {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    width: 100%;
    max-width: 100%;
    box-sizing: border-box;

    & > div:nth-child(1) {
      max-width: 100%;
    }

    div[data-twitterloading='twitterloading'] {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 30px;
      width: 30px;
    }
  }

  div[data-type='file'] {
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-sizing: border-box;
    padding: 8px 8px;
    margin-top: 4px;

    & > div {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    div[data-fileloading='fileloading'] {
      height: 30px;
      width: 30px;
    }

    & > div:nth-child(2) {
      box-sizing: border-box;
      width: calc(35% - 8px);
      display: flex;
      justify-content: flex-end;

      & > button {
        display: flex;
        justify-content: center;
        align-items: center;
        background: none;
        border: none;
        -webkit-box-shadow: none;
        box-shadow: none;
        cursor: pointer;
        height: 34px;
        width: 34px;
        position: relative;

        div,
        a {
          position: absolute;
          height: 34px;
          width: 34px;
          top: 0;
          left: 0;
          text-decoration: none;
        }
      }

      & > button:nth-child(1) {
        svg {
          fill: #718096;
        }
      }
    }

    & > div:nth-child(1) {
      width: calc(65% - 8px);
      display: flex;
      justify-content: flex-start;
      align-items: center;
      cursor: pointer;

      & > div:nth-child(1) {
        display: flex;
        justify-content: center;
        align-items: center;
        box-sizing: border-box;

        img {
          height: 36px;
          width: 36px;
          object-fit: contain;
          object-position: center;
        }
      }

      & > div:nth-child(2) {
        margin-left: 8px;
        width: calc(100% - 48px);
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        & > p,
        & > a {
          position: relative;
          font-size: 95%;
          font-weight: unset;
          font-family: 'WorkSans-Medium';
          max-width: 100%;
          text-align: left;
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
        }

        & > p:nth-child(2) {
          font-size: 85%;
          margin-top: 4px;
          opacity: 0.8;
          color: #718096;
        }

        & > a {
          text-decoration: none;
        }
      }
    }
  }

  ol {
    padding-left: 20px;
    box-sizing: border-box;

    & > li {
      padding-left: 8px;
      box-sizing: border-box;
    }

    & > li:not(:nth-child(1)) {
      margin-top: 8px;
    }
  }

  ul {
    padding-left: 20px;
    box-sizing: border-box;

    ul {
      margin-top: 8px;
    }

    & > li {
      padding-left: 8px;
      box-sizing: border-box;
    }

    & > li:not(:nth-child(1)) {
      margin-top: 8px;
    }
  }

  ul[data-type='taskList'] {
    li {
      padding-left: 0;
      align-items: center;
      display: flex;
      justify-content: flex-start;

      &::before {
        display: none !important;
        content: ' ' !important;
      }

      & > label {
        input[type='checkbox'] {
          height: 24px;
          width: 24px;
          margin-top: 2px;
          -moz-appearance: none;
          -webkit-appearance: none;
          -o-appearance: none;
          border: 2px solid #e2e8f0;
          border-radius: 2px;
          cursor: pointer;

          &:checked {
            border: 2px solid #00cc8f;
            background: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjAiIGhlaWdodD0iMjAiIHZpZXdCb3g9IjAgMCAyMCAyMCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHJlY3Qgd2lkdGg9IjIwIiBoZWlnaHQ9IjIwIiByeD0iMiIgZmlsbD0iIzAwQ0M4RiIvPgo8cGF0aCBkPSJNOC42NjY0OSAxMi4xMTVMMTQuNzk0NSA1Ljk4NjMzTDE1LjczNzggNi45Mjg5OUw4LjY2NjQ5IDE0LjAwMDNMNC40MjM4MyA5Ljc1NzY2TDUuMzY2NDkgOC44MTQ5OUw4LjY2NjQ5IDEyLjExNVoiIGZpbGw9IndoaXRlIi8+Cjwvc3ZnPgo=);
            background-position: center;
            background-size: cover;
            background-repeat: no-repeat;
          }
        }
      }

      & > div:nth-child(2) {
        margin-left: 8px;
      }
    }
  }

  & > h1 {
    font-family: 'WorkSans-Bold';
  }

  & > p {
    a {
      color: blue;
    }
  }

  & > a {
    color: blue;
  }

  & > blockquote {
    & > a {
      color: blue;
    }

    & > p {
      & > a {
        color: blue;
      }
    }
  }

  & > blockquote {
    & > p {
      & > h1 {
        font-family: 'WorkSans-Bold';
      }
    }
    & > h1 {
      font-family: 'WorkSans-Bold';
    }
  }

  & > pre {
    position: relative;
    background: #0d0d0d;
    color: #fff;
    padding: 8px 16px;
    border-radius: 0.5rem;
    box-sizing: border-box;
    font-family: 'JetBrainsMono', monospace;
    // word-break: break-all;
    // overflow-wrap: break-word;

    code {
      position: relative;
      color: inherit;
      padding: 0;
      background: none;
      overflow-wrap: break-word;
      white-space: pre-wrap;
      // word-break: break-all;
      // white-space: break-spaces;
    }
  }

  td,
  th,
  table {
    & > img {
      max-width: 98%;
      height: auto;
      margin: auto;
      object-position: center;
      object-fit: contain;
    }

    iframe {
      width: 100% !important;
      max-width: 100% !important;
    }
  }
}

@mixin editor_component_dark {
  div[data-type='file'] {
    & > div:nth-child(2) {
      & > button:nth-child(1) {
        svg {
          fill: #fff !important;
        }
      }

      & > button:nth-child(2) {
        svg {
          fill: #fff !important;
          path {
            fill: #fff !important;
          }
        }
      }
    }
  }

  div[data-type='pdfviewer'] {
    & > div {
      p,
      a {
        color: #fff !important;
      }

      svg {
        fill: #fff;

        path {
          fill: #fff;
        }
      }
    }
  }
}

@mixin editor_component_mobile {
  & > img {
    object-fit: contain;
    object-position: center;
    height: auto;
    width: 84vw;
    margin-left: 5vw;
    cursor: zoom-in;
  }
}
