@import '../../../theme/color.scss';
@import '../../../theme/commons.module.scss';

.reminders {
  position: absolute;
  top: calc(100% - 8px);
  right: 2px;
  width: 320px;
  background: $app_white_absolute;
  border: 1px solid rgba(113, 128, 150, 0.05);
  -webkit-box-shadow: 0px 4px 8px rgba(16, 24, 64, 0.1);
  box-shadow: 0px 4px 8px rgba(16, 24, 64, 0.1);
  border: 2px solid #f4f7fb;
  border-radius: 8px;
  padding: 16px 12px;
  padding-top: 8px;
  box-sizing: border-box;
  display: inline-block;
  z-index: calc(#{$high_front} + 10);

  .calendar {
    position: relative;
    box-sizing: border-box;
    width: 100%;

    & > p {
      position: relative;
      font-weight: unset;
      margin-right: 8px;
      font-family: 'WorkSans-Medium';
    }

    & > div:nth-child(2) {
      position: relative;
      width: 100%;
      margin-top: 4px;
      box-sizing: border-box;

      & > div:nth-child(1) {
        position: relative;
        width: 100%;
        height: 35px;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        justify-content: center;

        button {
          background: transparent;
          border: none;
          padding: 8px 0;
          position: relative;
          box-sizing: border-box;
          cursor: pointer;

          &:hover {
            background: rgba($color: $app_darkmode_text1, $alpha: 0.2);
          }
          // color: $app_darkmode_text1 !important;
        }

        & > button:nth-child(1),
        & > button:nth-child(2),
        & > button:nth-child(4),
        & > button:nth-child(5) {
          width: 16px;
        }

        & > button:nth-child(3) {
          position: relative;
        }
      }
      & > div:nth-child(2) {
        button {
          background: transparent;
          border: none;
          box-sizing: border-box;
          cursor: pointer;
          padding: 4px 0;

          &:hover {
            background: rgba($color: $app_darkmode_text1, $alpha: 0.2);
          }

          &:disabled {
            opacity: 0.5;
          }
        }
      }
    }

    &_dark {
      & > p {
        color: #fff;
      }
    }
  }

  .check {
    position: relative;
    justify-content: flex-start;
    width: 100%;
    margin-top: 8px;

    & > p {
      margin-left: 8px;
    }
  }

  .time {
    position: relative;
    box-sizing: border-box;
    width: 100%;
    justify-content: flex-start;
    margin-bottom: 8px;
    margin-top: 4px;
    height: 40px;

    & > p {
      position: relative;
      font-weight: unset;
      margin-right: 8px;
      font-family: 'WorkSans-Medium';
    }

    & > div:nth-child(2) {
      height: 100%;

      & > div {
        border: none;
        outline: none;
        background: transparent;
        font-size: 95%;

        & > div {
          & > span:nth-child(3) {
            margin-left: 3px;
            margin-right: 3px;
          }

          & > span:nth-child(4),
          & > span:nth-child(2) {
            margin-bottom: 1px;
          }
        }
      }
    }

    &_dark {
      & > p {
        color: #fff;
      }

      & > div {
        option {
          color: #000 !important;
        }
      }
    }
  }

  &_dark {
    background: #1a1e24;
    border: 1px solid transparent;
    -webkit-box-shadow: none;
    box-shadow: none;
  }
}
