@import '../../theme/color.scss';
@import '../../theme/commons.module.scss';
@import './EditorComponent.module.scss';

.table_cell_cta {
  position: absolute;
  height: 40px;
  width: 40px;
  top: 0;
  right: 0;
  border: 2px solid red;
  box-sizing: border-box;
  z-index: calc(#{$high_front} + 9);
}

.editor {
  position: relative;
  width: 100%;
  box-sizing: border-box;
  margin-top: 16px;

  .table_formats_menu {
    position: fixed;
    background: $app_white_absolute;
    border: 1px solid rgba(113, 128, 150, 0.05);
    -webkit-box-shadow: 0px 4px 8px rgba(16, 24, 64, 0.1);
    box-shadow: 0px 4px 8px rgba(16, 24, 64, 0.1);
    border: 1px solid #f4f7fb;
    padding: 12px 16px;
    top: 100px;
    left: 12px;
    border-radius: 8px;
    display: inline-block;
    z-index: calc(#{$high_front} + 8);

    & > div:nth-child(1) {
      position: relative;
      align-items: flex-start;

      & > div {
        position: relative;
        cursor: pointer;
        width: 100%;

        & > p {
          position: relative;
          font-weight: unset;
          font-family: 'WorkSans-Medium';
        }
      }

      & > div:not(:nth-child(1)) {
        margin-top: 8px;
      }
    }
  }

  .invisible {
    opacity: 0;
  }

  &_loading {
    position: absolute;
    top: 20px;
    width: 100%;
    left: 0;
    flex-direction: column;
    align-items: flex-start;

    .skeleton {
      height: 28px;
      background: #f1f2f2;
      border-radius: 6px;
      animation: opacity-load 2s ease-in-out infinite;

      &_dark {
        background: #1a1e24;
      }
    }

    .skeleton_one {
      position: relative;
      width: 60%;
      margin-top: 4px;
      height: 36px;
    }

    .skeleton_two {
      position: relative;
      width: 40%;
      margin-top: 16px;
    }

    .skeleton_three {
      position: relative;
      height: 80px;
      width: 90%;
      margin-top: 16px;
    }
  }

  .scrolling_container {
    position: relative;
    width: 100%;

    &_view {
      margin-top: 30px;
    }
  }

  .editor_raw {
    position: relative;
    width: 100%;
    font-size: 100%;

    & > div:nth-child(1) {
      position: relative;
      border: none;
      outline: none;
      min-height: 40vh;
      width: 100%;
      font-size: 105%;

      @include editor_component;

      & > p:nth-child(1) {
        &::before {
          content: attr(data-placeholder);
          float: left;
          opacity: 0.6;
          pointer-events: none;
          height: 0;
        }
      }
    }

    &_dark {
      @include editor_component_dark;
    }

    &_view {
      & > div:nth-child(1) {
        & > p {
          word-break: keep-all;
          overflow-wrap: break-word;
        }
      }
    }
  }

  .editing {
    & > div:nth-child(1) {
      // min-height: 90vh;
      min-height: 40vh;
    }
  }

  .viewing {
    & > div:nth-child(1) {
      div[data-type='file'] {
        & > div:nth-child(2) {
          & > button:nth-child(2) {
            display: none;
          }
        }
      }

      div[data-type='pdfviewer'] {
        & > div:nth-child(2) {
          & > button:nth-child(2) {
            display: none;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 900px) {
  .editor {
    .editor_raw {
      box-sizing: border-box;

      & > div:nth-child(1) {
        min-height: 85vh;
        @include editor_component;
        @include editor_component_mobile;

        div[data-type='video'] {
          display: flex;
          justify-content: center;
          align-items: center;
          margin: 4px 0;

          video {
            width: 80vw;
            height: 55vw;
          }
        }
      }

      &_dark {
        @include editor_component_dark;
      }
    }

    .editing {
      & > div:nth-child(1) {
        min-height: 85vh;
      }
    }
  }
}
