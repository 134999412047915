@import '../../../theme/color.scss';
@import '../../../theme/commons.module.scss';

.navigation {
  position: relative;
  width: 100%;
  box-sizing: border-box;
  background: #fff;

  .content {
    position: relative;
    width: 100%;
    background: #fff;
    box-sizing: border-box;
  }
}
