@import '../../theme/color.scss';
@import '../../theme/commons.module.scss';

.header {
  position: absolute;
  height: 90px;
  width: 100%;
  top: 0;
  margin-top: 0;

  position: fixed;
  margin: 0;
  top: 0;
  left: 0;

  padding: 0 24px;
  //margin-top: 10px;
  z-index: $high_front;
  background: $app_white_absolute;
  border-bottom: 1.2px solid rgba($color: $app_white_shade3, $alpha: 0.6);
  box-shadow: 0px 4px 50px rgba(230, 232, 240, 0.6);
  -moz-box-shadow: 0px 4px 50px rgba(230, 232, 240, 0.6);
  -webkit-box-shadow: 0px 4px 50px rgba(230, 232, 240, 0.6);
  justify-content: space-between;
  box-sizing: border-box;

  .right,
  .left {
    position: relative;
    width: 320px;
    justify-content: flex-start;
    box-sizing: border-box;
  }

  .left {
    .logo {
      position: relative;
      height: 44px;
      width: 100%;
      padding: 4px 2px;
      box-sizing: border-box;
      background: transparent;

      .arrow_back {
        position: relative;
        transform: rotate(-90deg);
        box-sizing: border-box;
        margin-right: 4px;

        svg {
          height: 20px;
          width: 20px;
        }

        &_light {
          svg {
            path {
              fill: #718096;
            }
          }
        }

        &_dark {
          svg {
            path {
              fill: #fff;
            }
          }
        }
      }

      &_dark {
        background: transparent !important;

        &:hover,
        &:active {
          background: rgba(
            $color: $app_darkmode_text1,
            $alpha: 0.05
          ) !important;
        }
      }
    }

    .link {
      position: absolute;
      box-sizing: border-box;

      a {
        position: absolute;
        height: 100%;
        width: 100%;
        top: 0;
        left: 0;
        text-decoration: none;
      }
    }

    .logo_texts {
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      margin-left: 8px;
      width: calc(100% - 50px);

      & > h5 {
        position: relative;
        font-weight: unset;
        margin: 0;
        padding: 0;
        font-size: 100%;
        max-width: 130px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        line-height: 130%;
        box-sizing: border-box;
      }

      & > h5:nth-child(2) {
        margin-top: 4px;
        font-size: 105%;
        font-weight: unset;
        box-sizing: border-box;
        max-width: 100%;
        overflow: hidden;
        font-family: 'WorkSans-Medium';
      }
    }
  }

  .right {
    position: relative;
    box-sizing: border-box;
    justify-content: flex-end;

    .user {
      position: relative;
      cursor: pointer;
      justify-content: flex-end;

      .avatar {
        position: relative;
        height: 40px;
        width: 40px;
        box-sizing: border-box;

        span {
          & > div {
            color: #fff;
            font-size: 90%;
          }
        }
      }

      .name {
        position: relative;
        margin: 0 8px;

        & > p {
          position: relative;
          font-weight: unset;
          font-size: 100%;
          line-height: 200%;
          font-family: 'WorkSans-Regular';
          text-align: left;
          max-width: 120px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          user-select: none;
        }
      }

      .arrow {
        position: relative;
        transition: transform 0.15s ease-in;

        &_active {
          transform: rotate(-180deg);
        }
      }
    }
  }

  .user_options {
    position: absolute;
    right: 4px;
    left: unset;
  }

  &_dark {
    background: $app_darkmode_background_header1;
    -moz-box-shadow: none;
    -webkit-box-shadow: none;
    box-shadow: none;
    border-bottom: 2px solid #28303b;
  }

  &_ios_app {
    height: 115px;
    padding-top: 35px;
    box-sizing: border-box;
  }
}

@media screen and (max-width: 1150px) {
  .header {
    padding: 0 16px;

    .left,
    .right {
      width: 200px;
    }
  }
}

@media screen and (max-width: 900px) {
  .header {
    position: fixed !important;
    margin: 0;
    top: 0;
    left: 0;
    right: 0;
    box-sizing: border-box;
    height: 80px;
    padding: 0 14px;
    padding-left: 6px;

    &_ios_app {
      height: 115px;
      padding-top: 35px;
      box-sizing: border-box;
    }
  }
}

@media screen and (max-width: 500px) {
  .header {
    .left {
      width: 200px;
    }

    .right {
      width: 65px;

      .user {
        .name {
          display: none;
        }
      }
    }
  }
}
