@import '../../../theme/color.scss';
@import '../../../theme/commons.module.scss';

.wrapper {
  position: relative;
  padding: 0 90px;
  padding-bottom: 183px;
  box-sizing: border-box;
  width: 100%;

  & > p {
    position: relative;
    width: 100%;
    box-sizing: border-box;
    text-align: left;
    line-height: 160%;
  }

  & > img {
    position: relative;
    width: 100%;
    object-fit: contain;
    height: auto;
  }
}

@media screen and (max-width: 900px) {
  .wrapper {
    width: 100%;
    padding: 0 30px;
    padding-bottom: 50px;
    box-sizing: border-box;

    & > img {
      position: relative;
      box-sizing: border-box;
      width: 100%;
      margin: 0;
      display: none;
    }
  }
}
