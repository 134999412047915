@import '../../../theme/color.scss';
@import '../../../theme/commons.module.scss';

.wrapper {
  position: relative;
  padding: 0 90px;
  padding-bottom: 183px;
  box-sizing: border-box;
  width: 100%;

  & > img {
    position: relative;
    width: 100%;
    object-fit: contain;
    height: auto;
  }

  & > ol {
    padding-left: 120px;

    & > li {
      font-size: 105%;
    }
  }
}

@media screen and (max-width: 900px) {
  .wrapper {
    width: 100%;
    padding: 0 30px;
    padding-bottom: 50px;
    box-sizing: border-box;

    & > img {
      position: relative;
      box-sizing: border-box;
      width: 90%;
      margin: 0;
    }

    & > ol {
      padding-left: 28px;
    }
  }
}
