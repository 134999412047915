@import '../../theme/color.scss';
@import '../../theme/commons.module.scss';

.auth_page {
  position: relative;
  width: 100%;
  align-items: flex-start;

  .content {
    position: relative;
    width: 480px;
    margin-top: 84px;
    margin-bottom: 50px;
  }
}

@media screen and (max-width: 600px) {
  .auth_page {
    width: 100%;

    .content {
      padding: 4px 16px;
      box-sizing: border-box;
    }
  }
}
