@import '../../../theme/color.scss';
@import '../../../theme/commons.module.scss';
@import './NotesList.commons.module.scss';

.notes_list {
  position: relative;
  width: 100%;
  box-sizing: border-box;
  margin-top: 20px;
  padding-right: 16px;
  padding-left: 24px;

  .notes_loading {
    position: relative;
    width: 100%;
    margin: 16px 0;
    margin-top: 40px;
    box-sizing: border-box;

    & > div {
      position: relative;
      width: 100%;
      padding-left: 0;
      padding-right: 16px;
      box-sizing: border-box;
    }

    & > div:nth-child(1) {
      justify-content: flex-start;
    }
  }

  .list_container {
    position: relative;
    width: 100%;
    box-sizing: border-box;
    border: 1px solid #f4f7fb;
    // border-radius: 4px;
    padding-bottom: 16px;
    margin-top: 8px;
    // display: none;

    .page_info {
      position: relative;
      width: 100%;
      height: 50px;
      padding: 8px 4px;
      box-sizing: border-box;
      justify-content: flex-end;

      &_dark {
        & > nav {
          ul {
            & > li {
              button {
                &:hover {
                  background: rgba(
                    $color: $app_darkmode_text1,
                    $alpha: 0.05
                  ) !important;
                }

                color: $app_darkmode_text1 !important;
              }

              [aria-current='true'] {
                background: rgba(
                  $color: $app_darkmode_text1,
                  $alpha: 0.1
                ) !important;
              }
            }
          }
        }
      }
    }

    .list_label {
      position: relative;
      width: 100%;
      padding: 4px 8px;
      background-color: $app_white_shade4;
      height: 44px;
      box-sizing: border-box;
      user-select: none;
      justify-content: flex-start;

      &_dark {
        background: $app_darkmode_background4;
        background: #1f242b;
      }

      & > div {
        & > p {
          font-size: 90%;
        }
      }
    }

    .list_content {
      position: relative;
      width: 100%;
      box-sizing: border-box;

      & > ul {
        position: relative;
        width: 100%;
        list-style: none;
        list-style-type: none;

        & > li {
          position: relative;
          width: 100%;
        }

        & > li:not(:nth-child(1)) {
          margin-top: 4px;
        }
      }
    }

    .mobile_page_info {
      display: none;
    }

    &_dark {
      border: 1.5px solid $app_darkmode_border1;
      background: transparent;
    }
  }
}

@media screen and (max-width: 800px) {
  .notes_list {
    padding: 0;
    margin-top: 0;
    height: 100%;

    .notes_loading {
      & > div {
        padding: 0 8px;
      }
    }

    .list_container {
      padding-bottom: 4px;
      height: 100%;
      border: none;
      background: transparent;
      // border: 2px solid red;
      box-sizing: border-box;

      .page_info {
        display: none;
        width: 0;
      }

      .list_label {
        display: none;
      }

      .list_content {
        & > ul {
          & > li:not(:nth-child(1)) {
            margin-top: 16px;
          }
        }
      }

      .mobile_page_info {
        position: relative;
        bottom: 0;
        left: 0;
        display: flex;
        // border: 2px solid red;
        box-sizing: border-box;
        margin-top: 16px;
        width: 100%;
        padding: 8px 16px;
        height: 40px;
        margin-bottom: 16px;
      }
    }
  }
}
