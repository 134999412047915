@import '../../theme/color.scss';
@import '../../theme/commons.module.scss';

.remove_tag {
  position: relative;
  width: 100%;
  padding: 0;
  box-sizing: border-box;

  & > div:nth-child(1) {
    position: relative;
    display: inline-block;
    width: 100%;
    text-align: center;

    & > p {
      width: 100%;
      text-align: center;
      display: inline-block;
      font-weight: unset;
      font-family: 'WorkSans-Medium';
      font-size: 110%;

      span {
        font-weight: unset;
        font-family: 'WorkSans-SemiBold';
      }
    }

    .loading {
      position: relative;
      width: 100%;
      margin-top: 16px;
      margin-bottom: 8px;
      padding: 0 4px;
      box-sizing: border-box;

      &_dark {
        svg {
          circle {
            stroke: $app_white_light;
          }
        }
      }
    }

    .actions {
      position: relative;
      width: 100%;
      margin-top: 16px;

      .cancel,
      .confirm {
        position: relative;
        display: flex;
        width: 120px;
        height: 38px;
        box-sizing: border-box;
        font-size: 90%;
      }

      .cancel {
        background: $app_gray_shade5 !important;

        &:hover,
        &:active {
          background: $app_gray_shade5 !important;
          filter: grayscale(0.3);
        }

        h4 {
          font-weight: unset;
          font-family: 'WorkSans-SemiBold';
        }

        &_dark {
          border: none;
          background: rgba(
            $color: $app_darkmode_text1,
            $alpha: 0.02
          ) !important;

          &:hover {
            background: rgba(
              $color: $app_darkmode_text1,
              $alpha: 0.1
            ) !important;
          }

          h4 {
            color: $app_darkmode_text1;
          }
        }
      }

      .confirm {
        margin-left: 14px;
      }
    }
  }

  &_dark {
    & > div:nth-child(1) {
      & > p:nth-child(1) {
        color: $app_darkmode_text1;
        span {
          color: $app_darkmode_text1;
        }
      }
    }
  }
}
