@import '../../theme/color.scss';
@import '../../theme/commons.module.scss';

.title_content {
  position: relative;
  width: 100%;
  align-items: flex-start;
  box-sizing: border-box;
  // border: 10px solid blue;

  &_loading {
    .skeleton {
      height: 28px;
      // background: #fcfafa;
      background: #f1f2f2;
      border-radius: 6px;
      animation: opacity-load 2s ease-in-out infinite;

      &_dark {
        background: #1a1e24;
      }
    }

    .skeleton_one {
      position: relative;
      width: 160px;
      margin-top: 4px;
    }

    .skeleton_two {
      position: relative;
      width: 260px;
      margin-top: 16px;
    }
  }

  .priority {
    position: relative;
    width: 100%;
    justify-content: space-between;

    .priority_preview {
      position: relative;

      & > button {
        height: 30px;
        width: 30px;
        padding: 0;

        &:hover,
        &:active {
          background: transparent;
        }
      }

      .priority_options {
        position: absolute;
        top: 100%;
        left: -4px;
        display: inline-block;
        background: $app_white_absolute;
        border: 1px solid rgba(113, 128, 150, 0.05);
        -webkit-box-shadow: 0px 4px 8px rgba(16, 24, 64, 0.1);
        box-shadow: 0px 4px 8px rgba(16, 24, 64, 0.1);
        padding: 8px 4px;
        border-radius: 8px;

        &_content {
          justify-content: flex-start;

          & > button {
            position: relative;
            padding: 4px 8px;
            box-sizing: border-box;
            width: 100%;

            & > p {
              position: relative;
              margin-left: 8px;
              font-size: 95%;
              font-weight: unset;
              font-family: 'WorkSans-Medium';
            }
          }
        }
      }
    }

    .left {
      box-sizing: border-box;
      width: calc(100% - 136px);
      justify-content: flex-start;
      box-sizing: border-box;

      .tags {
        position: relative;
        width: calc(100% - 44px);
        margin-left: 4px;
        box-sizing: border-box;
      }

      &_expand {
        width: 100%;
      }
    }

    .right {
      width: 130px;
      justify-content: flex-end;
      box-sizing: border-box;

      .views {
        position: relative;
        width: 100%;
        display: flex;
        justify-content: flex-end;

        & > p {
          position: relative;
          max-width: 100%;
          font-size: 90%;
          position: relative;
          color: #718096;
          font-weight: unset;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          font-family: 'WorkSans-Medium';
        }

        &_dark {
          & > p {
            color: #fff;
          }
        }
      }
    }
  }

  .title {
    position: relative;
    width: 100%;
    margin-top: 16px;
    height: 48px;

    & > input[type='text'] {
      position: relative;
      height: 100%;
      width: 100%;
      padding-right: 8px;
      box-sizing: border-box;
      outline: none;
      border: none;
      background: transparent;
      box-shadow: none;
      padding-left: 2px;
      font-size: 210%;
      text-align: left;
      font-weight: unset;
      font-family: 'WorkSans-Bold';
    }

    &_view {
      height: unset;
      min-height: 50px;
      max-height: 120px;
      display: flex;
      box-sizing: border-box;
      justify-content: flex-start;
    }

    .read_only {
      position: relative;
      min-height: 50px;
      max-height: 120px;
      overflow-wrap: break-word;
      text-overflow: ellipsis;
      white-space: pre-wrap;
      font-weight: unset;
      font-size: 205%;
      font-family: 'WorkSans-Bold';

      &_light {
        color: #000;
      }

      &_dark {
        color: #fff;
      }
    }
  }

  .users {
    position: relative;
    width: 100%;
    margin-top: 16px;
    justify-content: space-between;

    .author {
      position: relative;
      width: 50%;
      justify-content: flex-start;
      box-sizing: border-box;

      .avatar {
        position: relative;

        .avatar_premium {
          position: absolute;
          height: 22px;
          width: 22px;
          border-radius: 50%;
          background: linear-gradient(135deg, #ffc83a 0%, #e4a647 100%);
          border: 1px solid #fff;
          box-sizing: border-box;
          bottom: -6px;
          right: -6px;
        }

        .avatar_premium_tip {
          display: none;
          left: 10%;
        }

        &:hover {
          .avatar_premium_tip {
            display: flex !important;
          }
        }

        span {
          & > div {
            color: #fff;
          }
        }
      }

      .texts {
        position: relative;
        margin-left: 10px;
        align-items: flex-start;

        .name {
          position: relative;

          & > p {
            color: $app_black_shade1;
            font-size: 95%;
          }
        }

        .date_created {
          position: relative;

          & > p {
            color: $app_black_shade1;
            opacity: 0.6;
            font-size: 85%;
          }
        }

        .name,
        .date_created {
          &_dark {
            & > p {
              color: #fff;
            }
          }
        }
      }
    }

    .subscribers {
      position: relative;
      width: 45%;
      justify-content: flex-end;
      box-sizing: border-box;

      &_expand {
        position: relative;
        width: 70%;
        margin-left: 30%;
      }
    }
  }
}

@media screen and (max-width: 900px) {
  .title_content {
    .title {
      height: 40px;

      & > input[type='text'] {
        padding-right: 0;
        font-size: 150%;
      }

      &_view {
        height: unset;
        min-height: 50px;
        display: flex;
        box-sizing: border-box;
        justify-content: flex-start;
      }

      .read_only {
        position: relative;
        min-height: 50px;
        max-height: 120px;
        width: 100%;
        box-sizing: border-box;
        text-overflow: ellipsis;
        font-weight: unset;
        font-size: 205%;
        font-family: 'WorkSans-Bold';

        &_light {
          color: #000;
        }

        &_dark {
          color: #fff;
        }
      }
    }

    .priority {
      .left {
        width: calc(100% - 40px);

        &_expand {
          width: 100%;
        }
      }

      .right {
        display: none;

        .views {
          display: none;
        }
      }
    }
  }
}
