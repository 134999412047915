@import '../../theme/commons.module.scss';
@import '../../theme/color.scss';

.logo_loadv2 {
  position: absolute;
  height: 100vh;
  width: 100%;
  top: 0;
  left: 0;

  &_dark {
    background: $app_darkmode_background1;
  }
}
