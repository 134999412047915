@import '../../../theme/color.scss';
@import '../../../theme/commons.module.scss';
@import '../../../theme/scroll.scss';

@keyframes runMobile {
  0% {
    bottom: -90vh;
  }
  100% {
    bottom: 0;
  }
}

.subscribers_modal {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;

  .content {
    position: relative;
    justify-content: flex-start;
    width: 390px;
    padding: 16px 24px;
    box-sizing: border-box;
    background: #fff;
    border-radius: 8px;
    box-shadow: 0px 4px 50px rgba(16, 24, 64, 0.04);
    -webkit-box-shadow: 0px 4px 50px rgba(16, 24, 64, 0.04);
    -moz-box-shadow: 0px 4px 50px rgba(16, 24, 64, 0.04);

    &_dark {
      border: none;
      border: 0.5px solid rgba($color: $app_darkmode_text1, $alpha: 0.1);
      -moz-box-shadow: none;
      -webkit-box-shadow: none;
      box-shadow: none;
      background: $app_darkmode_background_header1;
    }

    & > ul {
      position: relative;
      list-style: none;
      list-style-type: none;
      width: 100%;
      align-items: flex-start;
      box-sizing: border-box;
      margin-top: 26px;

      & > li {
        position: relative;
        width: 100%;
        padding: 4px 0;
        justify-content: space-between;
        box-sizing: border-box;

        .avatar {
          position: relative;
          justify-content: flex-start;
          width: 100%;
          margin-right: 4px;
          box-sizing: border-box;

          span {
            & > div {
              font-size: 70%;
              color: #fff;
            }
          }

          & > p {
            position: relative;
            margin-left: 8px;
            max-width: calc(100% - 40px);
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }

          &_with_actions {
            width: calc(100% - 48px);
          }
        }

        .actions {
          position: relative;
          height: 40px;
          width: 60px;

          .remove {
            position: relative;
            height: 30px;
            overflow: hidden;
            box-sizing: border-box;
            font-family: 'WorkSans-Regular' !important;
            color: #000;

            &_dark {
              background: transparent !important;
              color: $app_darkmode_text1 !important;

              &:hover {
                background: rgba(
                  $color: $app_darkmode_text1,
                  $alpha: 0.05
                ) !important;
              }
            }
          }
        }
      }

      & > li:not(:nth-child(1)) {
        margin-top: 8px;
      }
    }

    .close {
      position: absolute;
      top: 16px;
      right: 16px;

      & > button {
        background: #fbfcfe;
        height: 30px;
        width: 30px;
        padding: 0;
        box-sizing: border-box;

        svg {
          fill: #718096;

          path {
            fill: #718096;
          }
        }
      }

      &_dark {
        & > button {
          background: transparent !important;
          color: $app_darkmode_text1 !important;

          &:hover,
          &:active {
            background: rgba(
              $color: $app_darkmode_text1,
              $alpha: 0.05
            ) !important;
          }

          svg {
            fill: $app_darkmode_text1;

            path {
              fill: $app_darkmode_text1;
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 900px) {
  .subscribers_modal {
    .content {
      position: absolute;
      height: 75vh;
      width: 100%;
      bottom: 0;
      left: 0;
      padding: 24px 16px;
      box-sizing: border-box;
      border-radius: unset;
      overflow: hidden;
      overflow-y: scroll;
      justify-content: flex-start;
      align-items: center;
      animation: ease;
      animation-name: runMobile;
      animation-duration: 0.25s;
      border-top-right-radius: 4px;
      border-top-left-radius: 4px;

      @include custom_scroll;

      .close {
        right: 8px;
      }
    }
  }
}
