@import '../../theme/commons.module.scss';
@import '../../theme/color.scss';

.forgot_password {
  position: relative;
  width: 100%;
  flex-direction: column;
  align-items: flex-start;

  .logo {
    width: 100%;
    position: relative;
  }

  .success {
    position: relative;
    width: 100%;
    padding: 16px 8px;
    box-sizing: border-box;
    flex-direction: column;

    & > p {
      position: relative;
      margin-top: 16px;
      font-size: 120%;
      font-family: 'WorkSans-Regular';
    }

    svg {
      height: 30px;
      width: 30px;
    }
  }

  .message {
    position: relative;
    width: 100%;
    box-sizing: border-box;
    padding: 0 16px;
    margin: 16px 0;

    & > h2 {
      position: relative;
      font-weight: unset;
      font-family: 'WorkSans-Medium';
      font-size: 105%;
    }

    &_dark {
      & > h2 {
        color: $app_darkmode_text1;
      }
    }
  }

  .cta {
    position: relative;
    padding: 0 16px;
    width: 100%;
    box-sizing: border-box;

    .to_login {
      position: relative;
      width: 100%;
      margin-top: 16px;

      & > a {
        position: relative;
        font-size: 100%;
      }
    }
  }

  .email {
    position: relative;
    padding: 0 16px;
    width: 100%;
    box-sizing: border-box;

    & > div:nth-child(1) {
      position: relative;
      margin-bottom: 15px;
      width: 100%;

      div {
        & > p:nth-child(2) {
          box-sizing: border-box;
          font-family: 'WorkSans-Medium' !important;
          font-size: 90% !important;
          width: calc(100% - 30px) !important;
        }
      }

      label {
        font-weight: unset;
        font-family: 'WorkSans-Medium' !important;
        font-size: 90% !important;
      }
    }

    input[type='text'],
    input[type='password'] {
      font-size: 80% !important;
      font-weight: unset;
      font-family: 'WorkSans-Regular' !important;
      height: 40px;
    }

    &_dark {
      label,
      span {
        color: $app_darkmode_text1 !important;
      }

      input[type='text'],
      input[type='password'] {
        background: transparent;
        border: 1px solid #28303b;
        color: $app_darkmode_text1;

        &:focus,
        &:active {
          background: transparent;
        }
      }
    }
  }

  .confirm {
    position: relative;
    width: 100%;
    height: 40px;
    font-size: 100%;
    margin-top: 16px;

    & > p,
    & > h5 {
      font-size: 90%;
      font-family: 'WorkSans-Regular';
    }
  }
}
