.react-calendar__tile--active {
  background: #f5f9ff !important;
  border: 1px solid #6283fa !important;
  border-radius: 4px !important;
  box-sizing: border-box;
  color: black !important;

  abbr {
    color: black !important;
  }
}
