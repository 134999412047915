@import '../../theme/commons.module.scss';
@import '../../theme/color.scss';

$default: #dcf2ea;
$subscribed: #d6e0ff;

.custom_badge {
  position: relative;
  border-radius: 4px;
  padding: 4px 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;

  & > h5 {
    position: relative;
    font-weight: unset;
    font-family: 'WorkSans-Regular';
    font-size: 85%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: inline-block;
    text-align: center;
    max-width: 220px;
  }

  &_default {
    background: $default;

    & > h5 {
      color: #317159;
    }
  }

  &_author {
    background: $app_blue_shade3;

    & > h5 {
      color: $app_white_absolute;
    }
  }

  &_subscribed {
    background: $subscribed;
    & > h5 {
      color: #2952cc;
    }
  }

  &_personal_tag {
    background: #474d66;

    & > h5 {
      color: white;
    }
  }

  &_space_name {
    background: $app_white_shade6;

    & > h5,
    & > p {
      color: #474d66;
    }
  }
}

@media screen and (min-width: 1910px) {
  .custom_badge {
    & > h5 {
      font-size: 12px;
    }
  }
}

@media screen and (min-width: 1680px) {
  .custom_badge {
    & > h5 {
      font-size: 10.6px;
    }
  }
}

@media screen and (max-width: 900px) {
  .custom_badge {
    & > h5 {
      font-size: 11px;
    }
  }
}
