@import '../../../../theme/commons.module.scss';
@import '../../../../theme/color.scss';

.item {
  position: relative;
  width: 100%;
  box-sizing: border-box;

  &_title {
    position: relative;
    width: 100%;

    & > h1 {
      position: relative;
      font-weight: unset;
      font-family: 'WorkSans-SemiBold';
      color: $app_black_shade4;
      display: inline-block;
      vertical-align: middle;
      text-align: left;
      width: calc(100% - 54px);
      //   border: 1px solid red;
      font-size: 125%;
      cursor: pointer;
    }

    .icon_expand {
      position: relative;
      display: inline-block;
      vertical-align: middle;
      margin-left: 8px;
      transition: transform 0.2s ease;

      &_active {
        transform: rotate(-180deg);
      }
    }
  }

  .answer {
    position: relative;
    width: 100%;
    display: inline-block;
    text-align: left;
    margin-top: 20px;
    padding-right: 14px;
    box-sizing: border-box;

    & > h5 {
      position: relative;
      font-size: 100%;
      font-weight: unset;
      font-family: 'WorkSans-Regular';
    }
  }
}

.faqs_wrap {
  position: relative;
  width: 100%;
  text-align: left;
  display: inline-block;
  box-sizing: border-box;
  margin-top: 120px;
  padding: 0 24px;
  //   border: 1px solid red;

  .title {
    position: relative;
    width: 100%;
    text-align: center;

    & > h1 {
      position: relative;
      font-style: normal;
      font-weight: unset;
      font-family: 'WorkSans-SemiBold';
      font-size: 40px;
      line-height: 130%;
      text-align: center;
      // border: 1px solid red;
      box-sizing: border-box;
      display: inline-block;
      text-align: center;
      color: $app_blue_shade1;
    }
  }

  .list {
    position: relative;
    width: 100%;
    // border: 1px solid red;
    // box-sizing: border-box;
    display: inline-block;
    text-align: center;
    margin-top: 40px;

    & > ul {
      position: relative;
      width: 700px;
      list-style-type: none;
      list-style: none;
      box-sizing: border-box;
      display: inline-block;

      & > li {
        position: relative;
        width: 100%;
        text-align: left;
        // border: 1px solid red;
        box-sizing: border-box;
      }

      & > li:not(:nth-child(1)) {
        margin-top: 20px;
      }
    }
  }
}

@media screen and (max-width: 900px) {
  .faqs_wrap {
    .list {
      & > ul {
        width: 90%;
      }
    }
  }
}
