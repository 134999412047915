@import '../../../theme/commons.module.scss';
@import '../../../theme/color.scss';
@import '../../../theme/scroll.scss';
@import '../../../theme/task.module.scss';

.comment_input {
  position: relative;
  width: 100%;
  display: inline-block;
  // border: 2px solid red;
  box-sizing: border-box;
  display: inline-block;
  padding-top: 12px;
  border-radius: 8px;
  border: 1px solid transparent;

  .formats {
    position: relative;
    min-height: 30px;
    width: 100%;
    left: 0;
    // border: 2px solid red;
    box-sizing: border-box;
    margin-top: 5px;
    padding: 4px 0;
    padding-left: 4px;
    justify-content: space-between;

    .left_actions {
      position: relative;
      display: inline-block;
      text-align: left;
      min-width: 80px;
      box-sizing: border-box;

      .emoji,
      .gif {
        position: relative;
        display: inline-block;
        text-align: center;
        box-sizing: border-box;

        .action_tip {
          position: absolute;
          display: none;
          top: unset;
          bottom: calc(100% - 10px);
          left: 20px;
        }

        &:hover {
          .action_tip {
            display: flex;

            h5 {
              white-space: nowrap;
            }
          }
        }
      }

      .emoji {
        & > button {
          svg {
            height: 16px;
            width: 16px;
            fill: $app_black_shade1;
          }
        }
      }

      .gif {
        margin-left: 2px;

        & > button {
          & > p {
            position: relative;
            color: #718096;
            font-family: 'WorkSans-SemiBold';
          }
        }

        &_dark {
          & > button {
            color: $app_white_light2;

            & > p {
              color: $app_white_light2;
            }
          }
        }
      }

      button {
        padding: 0;

        svg {
          height: 14px;
          width: 16px;
        }
      }

      &_dark {
        button {
          &:hover,
          &:active {
            background: rgba(
              $color: $app_darkmode_text1,
              $alpha: 0.05
            ) !important;
          }

          color: $app_darkmode_text1 !important;

          svg {
            color: $app_white_light2;
            fill: $app_white_light2;

            path {
              color: $app_white_light2;
              fill: $app_white_light2;
            }
          }
        }
      }
    }

    .right_actions {
      position: relative;
      display: inline-block;
      box-sizing: border-box;

      .discard,
      .submit {
        position: relative;
        display: inline-block;
        vertical-align: middle;
        margin-right: 6px;
        padding: 0 16px;
      }

      .discard {
        margin-right: 8px;

        &_dark {
          border: none;
          background: rgba(
            $color: $app_darkmode_text1,
            $alpha: 0.02
          ) !important;

          &:hover {
            background: rgba(
              $color: $app_darkmode_text1,
              $alpha: 0.1
            ) !important;
          }
        }
      }
    }
  }

  .edit {
    position: relative;
    width: 100%;
    justify-content: flex-start;
    margin: 8px 0;
    margin-top: 12px;
    margin-left: -8px;
    padding: 0;
    box-sizing: border-box;

    &_button {
      padding-left: 4px;
      padding-right: 7px;
      margin: 0;
      background: rgba($color: #f4f5f9, $alpha: 0.6) !important;

      svg {
        height: 14px;
        width: 14px;
      }

      & > p {
        font-size: 106%;
        margin-left: 8px;
        font-weight: unset;
        font-family: 'WorkSans-Regular';
      }

      &:hover,
      &:active {
        background: rgba($color: #f4f5f9, $alpha: 1) !important;
      }

      &_dark {
        background: rgba($color: $app_darkmode_text1, $alpha: 0.03) !important;

        &:hover,
        &:active {
          background: rgba(
            $color: $app_darkmode_text1,
            $alpha: 0.05
          ) !important;
        }

        color: $app_darkmode_text1 !important;

        svg {
          color: $app_white_light2;
          fill: $app_white_light2;

          path {
            color: $app_white_light2;
            fill: $app_white_light2;
          }
        }
      }
    }
  }

  .scrolling_container {
    position: relative;
    width: 100%;
    padding-left: 10px;
    padding-right: 4px;
    box-sizing: border-box;
    display: block;
    -webkit-user-drag: none;
    font-size: 100%;

    .editable {
      position: relative;
      // height: 100%;
      width: 100%;
      min-height: 80px;
      box-sizing: border-box;
      font-size: 100%;

      & > div:nth-child(1) {
        width: 100%;
        padding: 0 !important;
        margin: 0 !important;

        @include task_description;

        & > p,
        p {
          font-size: 100% !important;
          font-weight: unset;
          font-family: 'WorkSans-Regular' !important;
        }

        &::after,
        &::before {
          font-family: 'WorkSans-Medium';
          font-size: 90%;
          font-style: normal;
          position: absolute;
          left: 0;
          top: 4px;
          color: $app_gray_shade2;
        }

        /** start of editor styles **/

        ul,
        ol {
          padding-left: 5px;

          & > li {
            overflow-wrap: break-word;
            line-height: 26px;
            margin-bottom: 3px;

            &::before {
              font-family: 'WorkSans-SemiBold';
              font-size: 100% !important;
            }
          }
        }

        ol {
          & > li {
            position: relative;

            &::before {
              font-weight: unset;
              font-family: 'WorkSans-Medium';
            }
          }
        }

        a {
          color: $app_purple_shade1;
          text-decoration: underline;

          & > em,
          & > strong {
            font-weight: unset;
            font-family: 'WorkSans-SemiBold';
            color: $app_purple_shade1;

            s,
            b {
              font-family: 'WorkSans-SemiBold';
              color: $app_purple_shade1;
            }
          }
        }

        /** end of editor styles **/
      }

      &_not_edit_mode {
        max-height: unset;
        box-sizing: border-box;

        & > div:nth-child(1) {
          overflow-y: visible;
          overflow: visible;
        }
      }

      &_edit_mode {
        max-height: 300px;

        & > div:nth-child(1) {
          min-height: 80px;
          max-height: 300px;
          overflow: hidden;
          overflow-y: scroll;

          @include custom_scroll;
        }

        &_dark {
          & > div:nth-child(1) {
            @include custom_scroll_dark;
          }
        }

        &_mobile {
          max-height: 240px;

          & > div:nth-child(1) {
            min-height: 135px;
            max-height: 240px;
          }
        }
      }

      &_gif {
        opacity: 0;
        min-height: 10px;
        max-height: 10px;

        & > div:nth-child(1) {
          min-height: 10px;
          max-height: 10px;
        }

        .editable_edit_mode_mobile {
          min-height: 10px;
          max-height: 10px;

          & > div:nth-child(1) {
            min-height: 10px;
            max-height: 10px;
          }
        }
      }
    }
  }

  &_editing {
    transition: border 0.15s ease;
  }

  &_not_editing {
    padding: 0;

    .scrolling_container {
      padding-left: 0;
      padding-right: 0;
    }
  }

  &_blur {
    border: 1px solid rgba($color: $app_white_shade3, $alpha: 0.5);

    &_dark {
      border: 1px solid rgba($color: $app_gray_shade4, $alpha: 0.15);
    }
  }

  &_focus {
    border: 1px solid rgba($color: $app_gray_shade4, $alpha: 0.7);

    &_dark {
      border: 1px solid rgba($color: $app_white_light2, $alpha: 0.5);
    }
  }
}

@media screen and (max-width: 900px) {
  .comment_input {
    .scrolling_container {
      .editable {
        & > div:nth-child(1) {
          & > p,
          p {
            font-size: 95% !important;
          }

          &::after,
          &::before {
            font-size: 95%;
            left: 0;
            top: 6px;
          }
        }

        &_edit_mode {
          max-height: 70px;
          min-height: 70px;
          height: 70px;

          & > div:nth-child(1) {
            min-height: 70px;
            max-height: 70px;
            height: 70px;
            overflow: hidden;
            overflow-y: scroll;
          }

          &_mobile {
            min-height: 70px;
            max-height: 70px;
            height: 70px;

            & > div:nth-child(1) {
              min-height: 70px;
              max-height: 70px;
              height: 70px;
              overflow: hidden;
              overflow-y: scroll;
            }
          }
        }

        &_gif {
          opacity: 0;
          min-height: 2px;
          max-height: 2px;
          height: 2px;

          & > div:nth-child(1) {
            min-height: 2px;
            max-height: 2px;
            height: 2px;
          }

          .editable_edit_mode_mobile {
            min-height: 2px;
            max-height: 2px;
            height: 2px;

            & > div:nth-child(1) {
              min-height: 2px;
              max-height: 2px;
              height: 2px;
            }
          }
        }
      }
    }
  }
}
