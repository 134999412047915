html {
  scrollbar-color: #e8e8e8 transparent;
}

body {
  padding: 0;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  scroll-behavior: smooth;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

* {
  padding: 0;
  margin: 0;
  color: #323333;
  font-family: 'WorkSans-Regular';
}

.darkmode_background {
  background: #13171c !important;
}

.overflow_hidden {
  overflow: hidden !important;
}

.overflow_unset {
  overflow: unset;
}

#editorComponentFileInputUniqueId {
  position: absolute;
  top: -400px;
  left: -400px;
  opacity: 0;
}

#spaceAvatarFileInput1 {
  position: absolute;
  top: -400px;
  left: -400px;
  opacity: 0;
}

@media screen and (max-width: 900px) {
  body {
    font-size: 13.5px;
    height: 100vh;
    min-height: unset;
    overflow-x: unset;
    overflow: unset;
  }

  html {
    font-size: 13.5px;
  }

  #root {
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    padding: 0;
    font-size: 13.5px;
    box-sizing: border-box;
  }
}
