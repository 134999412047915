@import '../../theme/commons.module.scss';
@import '../../theme/color.scss';
@import '../../theme/scroll.scss';

.search_modal {
  position: relative;
  width: 100%;

  &_content {
    position: relative;
    width: 100%;
    background: transparent;
    border-radius: 6px;

    .search {
      position: relative;
      height: 52px;
      width: 100%;
      box-sizing: border-box;

      .icon {
        position: relative;
        height: 100%;
        width: 46px;
        box-sizing: border-box;

        svg {
          fill: #718096;
        }

        &_dark {
          svg {
            fill: $app_darkmode_text1;
          }
        }
      }

      .input {
        position: relative;
        width: calc(100% - 60px);
        margin-left: 8px;
        height: 100%;

        input[type='text'] {
          position: relative;
          border: none;
          outline: none;
          height: 100%;
          width: 100%;
          padding: 8px 0;
          box-sizing: border-box;
          font-size: 110%;
        }

        &_dark {
          input[type='text'] {
            color: $app_darkmode_text1;
            background: transparent;
          }
        }
      }
    }

    .loading {
      position: relative;
      width: 100%;
      margin: 16px 0;
      margin-top: 20px;

      &_dark {
        svg {
          circle {
            stroke: $app_white_light;
          }
        }
      }
    }

    .saved_search {
      position: relative;
      width: 100%;
      margin-top: 10px;
      list-style: none;
      list-style-type: none;

      & > ul {
        position: relative;
        width: 100%;
        list-style-type: none;
        list-style: none;
      }

      & > ul:nth-child(1) {
        & > li {
          position: relative;
          display: inline-block;
          margin-top: 3px;
        }

        & > li:not(:last-child) {
          margin-right: 8px;
        }
      }

      & > ul:nth-child(2) {
        margin: 16px 0;

        & > li:not(:nth-child(1)) {
          margin-top: 16px;
        }
      }
    }

    .search_results {
      position: relative;
      width: 100%;
      box-sizing: border-box;
      margin-top: 16px;
      padding: 8px 0;
      display: inline-block;
      overflow: hidden;
      overflow-y: scroll;
      max-height: 320px;
      // border: 3px solid red;
      box-sizing: border-box;

      @include custom_scroll;

      &_dark {
        @include custom_scroll_dark;
      }

      & > ul {
        position: relative;
        width: 100%;
        list-style: none;
        list-style-type: none;

        & > li {
          position: relative;
          width: 100%;
        }

        & > li:not(:nth-child(1)) {
          margin-top: 16px;
        }
      }
    }
  }

  &_dark {
    background: transparent;
  }
}

// @media screen and (max-width: 900px) {
//   .search_modal {
//     top: 0;
//     left: 0;
//     border: none;
//     border-radius: none;
//     box-sizing: border-box;

//     &_ios_app {
//       position: absolute;
//       top: 0;
//       left: 0;
//       padding: 0;
//       padding-top: 16px;
//       height: 100%;
//       width: 100%;
//       box-sizing: border-box;
//     }

//     &_light {
//       background: $app_white_absolute;
//     }

//     &_dark {
//       background: $app_darkmode_background_header1;
//     }

//     &_content {
//       height: 100%;
//       width: 100%;
//       padding: 8px 0;
//       border: none;
//       border-radius: none;
//       box-shadow: none;
//       -webkit-box-shadow: none;
//       -moz-box-shadow: none;

//       &_ios_app {
//         top: 0;
//         padding-top: 16px;
//         box-sizing: border-box;
//       }

//       .search {
//         &_light {
//           box-sizing: border-box;
//           border-bottom: 1px solid #f4f7fb;
//         }

//         &_dark {
//           box-sizing: border-box;
//           border-bottom: 1px solid #28303b;
//         }
//       }

//       .search_results {
//         margin-top: 8px;
//         padding: 8px 16px;
//         max-height: unset;
//         box-sizing: border-box;
//       }

//       .saved_search {
//         padding: 4px 8px;
//         margin-top: 8px;
//         box-sizing: border-box;

//         & > ul:nth-child(2) {
//           padding: 0 4px;
//           box-sizing: border-box;
//         }
//       }
//     }
//   }
// }
