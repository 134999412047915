@import '../../../theme/color.scss';
@import '../../../theme/commons.module.scss';

.product_hunt_show {
  position: relative;
  width: 100%;
  padding: 16px 8px;
  margin-top: 20px;
  margin-bottom: 8px;
  box-sizing: border-box;
}
