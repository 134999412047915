@import '../../../theme/color.scss';
@import '../../../theme/commons.module.scss';
@import '../../../theme/scroll.scss';

.image_viewer {
  position: fixed;
  height: 100vh;
  width: 100%;
  top: 0;
  bottom: 0;
  box-sizing: border-box;
  overflow: hidden;
  z-index: calc(#{$high_front} + 9);
  background: rgba($color: #000000, $alpha: 0.65);

  .content {
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    overflow: hidden;

    .images {
      position: relative;
      opacity: 0;
    }
  }
}

@media screen and (max-width: 900px) {
  .image_viewer {
    height: 100%;
  }
}
