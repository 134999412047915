@import '../../theme/color.scss';
@import '../../theme/commons.module.scss';

.member {
  position: relative;
  width: 100%;
  display: inline-block;
  padding: 4px 0px;
  // border: 1px solid red;
  box-sizing: border-box;

  .avatar,
  .role {
    display: inline-block;
    box-sizing: border-box;
  }

  .role {
    position: absolute;
    right: -1px;
    margin-top: 4px;
    box-sizing: border-box;

    &_raw {
      position: relative;
      display: inline-block;
      vertical-align: middle;

      & > h5 {
        position: relative;
        display: inline-block;
        font-weight: unset;
        font-family: 'WorkSans-Regular';
        font-size: 85%;
        box-sizing: border-box;
        max-width: 140px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        // min-width: 60px;
        // border: 1px solid red;
      }

      &_tip {
        position: absolute;
        left: unset;
        top: 95%;
        right: 20%;
        display: none;
      }

      &:hover {
        .role_raw_tip {
          display: flex;
        }
      }
    }

    .more {
      position: relative;
      display: inline-block;
      vertical-align: middle;
      // border: 1px solid red;
      box-sizing: border-box;
      margin-left: 4px;
      padding: 0;

      button {
        box-sizing: border-box;
        padding: 0;
      }

      svg {
        height: 12px;
        width: 12px;
        margin-bottom: 2px;
        transition: transform 0.2s ease;
      }

      &_expand {
        button {
          svg {
            transform: rotate(180deg);
          }
        }
      }

      &_dark {
        & > button {
          background: transparent;
          color: $app_darkmode_text1;

          &:hover,
          &:active {
            background: rgba($color: $app_darkmode_text1, $alpha: 0.05);
          }

          svg {
            fill: $app_darkmode_text1;
          }
        }
      }
    }

    .loading {
      position: relative;
      display: inline-block;
      vertical-align: middle;
      margin-left: 8px;
      text-align: center;

      & > div:nth-child(1) {
        position: relative;
        margin-top: 1px;
      }
    }

    &_actions {
      position: absolute;
      display: inline-block;
      right: 40px;
      top: 40%;
      padding: 4px 4px;
      box-sizing: border-box;
      border-radius: 4px;
      // border: 1px solid red;
      min-width: 120px;
      min-height: 40px;
      background: $app_white_absolute;
      box-shadow: 0px 4px 50px rgba(0, 0, 0, 0.05);
      -webkit-box-shadow: 0px 4px 50px rgba(0, 0, 0, 0.05);
      -moz-box-shadow: 0px 4px 50px rgba(0, 0, 0, 0.5);
      border: 1.5px solid $app_white_shade3;
      border-radius: 4px;
      z-index: $high_front;
      margin-bottom: 16px;

      .assign_roles {
        position: relative;
        width: 100%;
        box-sizing: border-box;

        &_title {
          position: relative;
          width: 100%;
          margin: 8px 0;

          & > h5 {
            position: relative;
            font-size: 80%;
            font-weight: unset;
            font-family: 'WorkSans-SemiBold';
            color: $app_black_shade4;
            margin-left: 7px;
          }
        }

        & > button {
          display: flex;
          justify-content: flex-start;
          width: 100%;
          padding: 0;
          margin: 0;
          padding-left: 12px;
          box-sizing: border-box;

          & > h5 {
            font-size: 105%;
            font-weight: unset;
            font-family: 'WorkSans-Regular';
            margin-left: 14px;
            padding-right: 8px;
            box-sizing: border-box;
          }

          svg {
            fill: $app_black_shade4;
            path {
              fill: $app_black_shade4;
            }
          }
        }
      }

      .remove,
      .leave {
        position: relative;
        width: 100%;
        justify-content: flex-start;
        // padding: 0 2px;

        svg {
          height: 14px;
          width: 14px;
        }

        svg,
        path {
          fill: $app_red_shade3;
        }

        & > h5 {
          position: relative;
          font-size: 105%;
          font-weight: unset;
          font-family: 'WorkSans-Regular';
          margin-left: 4px;
          display: inline-block;
          color: $app_red_shade3;
        }

        &_dark {
          background: transparent;

          &:hover,
          &:active {
            background: rgba($color: $app_darkmode_text1, $alpha: 0.05);
          }
        }
      }

      .leave {
        svg,
        path {
          fill: $app_red_shade3;
        }

        & > h5 {
          color: $app_red_shade3;
        }

        &_dark {
          & > h5 {
            position: relative;
          }
        }
      }

      &_dark {
        border: 1px solid rgba($color: $app_darkmode_text1, $alpha: 0.05);
        background: $app_darkmode_background_header1;
        box-shadow: none;
        -webkit-box-shadow: none;
        -moz-box-shadow: none;

        .assign_roles {
          &_title {
            & > h5 {
              color: $app_darkmode_text1;
            }
          }

          & > button {
            background: transparent;

            &:hover,
            &:active {
              background: rgba($color: $app_darkmode_text1, $alpha: 0.05);
            }

            & > h5 {
              color: $app_darkmode_text1;
            }
          }
        }
      }
    }
  }

  .avatar {
    position: relative;
    // border: 1px solid red;
    box-sizing: border-box;
    width: calc(100% - 110px);
    // border: 1px solid red;

    &_placeholder {
      position: relative;
      display: inline-block;

      & > div:nth-child(1) {
        position: relative;
        display: inline-block;
        vertical-align: middle;
        height: 30px;
        width: 30px;
        border-radius: 50%;
        background: $app_gray_shade1;
        animation: opacity-load 2s ease-in-out infinite;
      }

      & > div:nth-child(2) {
        position: relative;
        display: inline-block;
        vertical-align: middle;
        height: 8px;
        border-radius: 20px;
        width: 36px;
        background: $app_gray_shade1;
        animation: opacity-load 2s ease-in-out infinite;
        margin-left: 6px;
      }
    }

    .name {
      position: relative;
      display: inline-block;
      vertical-align: middle;
      text-align: left;
      margin-left: 8px;
      width: 75%;

      & > h5 {
        position: relative;
        font-weight: unset;
        font-family: 'WorkSans-Regular';
        display: inline-block;
        vertical-align: middle;
        overflow: hidden;
        white-space: nowrap;
        text-align: left;
        text-overflow: ellipsis;

        span,
        i {
          position: relative;
          display: inline-block;
          vertical-align: middle;
          margin-left: 4px;
          font-weight: unset;
          font-family: 'WorkSans-Medium';
          font-size: 12px;
          margin-bottom: 2px;
        }
      }
    }

    &_image {
      position: relative;
      height: 35px;
      width: 35px;
      text-align: center;
      border-radius: 50%;
      overflow: hidden;
      display: inline-block;
      vertical-align: middle;

      img {
        position: relative;
        height: 100%;
        width: 100%;
        object-fit: cover;
        left: 0;
      }

      .initials {
        position: absolute;
        height: 100%;
        width: 100%;
        top: 0;
        border-radius: 50%;
        text-align: center;
        background: $app_black_shade1;

        & > h5 {
          position: relative;
          text-align: center;
          font-weight: unset;
          font-family: 'WorkSans-Medium';
          font-size: 95%;
          color: $app_white_absolute;
        }
      }
    }
  }

  &_dark {
    .name {
      & > h5 {
        color: $app_darkmode_text1;

        & > i,
        & > span {
          color: $app_darkmode_text1;
        }
      }
    }

    .role {
      &_raw {
        & > h5 {
          color: $app_darkmode_text1;
        }
      }
    }
  }
}

@media screen and (max-width: 800px) {
  .member {
    .role {
      box-sizing: border-box;
      margin-top: 11px;

      &_raw {
        padding-right: 8px;
        box-sizing: border-box;

        & > h5 {
          font-size: 85%;
        }

        &:hover {
          .role_raw_tip {
            display: none;
          }
        }

        &:active {
          .role_raw_tip {
            display: flex;
          }
        }
      }
    }

    .avatar {
      width: calc(100vw - 155px);
    }
  }
}

@media screen and (max-width: 400px) {
  .member {
    .role {
      &_raw {
        box-sizing: border-box;
        max-width: 105px;
      }
    }
  }
}
