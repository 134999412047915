@import '../../../theme/color.scss';
@import '../../../theme/commons.module.scss';

.delete_note {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  box-sizing: border-box;

  .content {
    position: relative;
    width: 400px;
    padding: 24px 24px;
    box-sizing: border-box;
    border-radius: 10px;
    background: #fff;
    box-shadow: 0px 4px 50px rgba(16, 24, 64, 0.04);
    -webkit-box-shadow: 0px 4px 50px rgba(16, 24, 64, 0.04);
    -moz-box-shadow: 0px 4px 50px rgba(16, 24, 64, 0.04);
    align-items: flex-start;
    justify-content: flex-start;

    .loading {
      position: relative;
      width: 100%;
      padding: 8px 16px;
      box-sizing: border-box;

      &_dark {
        svg {
          circle {
            stroke: $app_white_light;
          }
        }
      }
    }

    .title {
      margin-top: 8px;

      & > h3 {
        position: relative;
        font-weight: unset;
        font-family: 'WorkSans-Medium';
        font-size: 105%;
      }
    }

    .message {
      margin-top: 16px;

      & > p {
        font-weight: unset;
        color: #2d3748;
        font-size: 90%;
      }
    }

    .title,
    .message {
      position: relative;
      width: 100%;

      &_dark {
        & > h3,
        & > p {
          color: #fff;
        }
      }
    }

    .actions {
      position: relative;
      width: 100%;
      margin-top: 24px;
      justify-content: flex-end;

      & > button {
        & > p {
          font-size: 110%;
        }
      }

      .cancel {
        background: #fbfcfe;
        border: 1px solid #e2e8f0;
        border-radius: 4px;

        &:active,
        &:hover {
          background: #fbfcfe;
        }

        &_dark {
          border: none;
          background: rgba($color: $app_darkmode_text1, $alpha: 0.1);

          &:hover,
          &:active {
            background: rgba($color: $app_darkmode_text1, $alpha: 0.05);
          }
        }
      }

      .confirm {
        margin-left: 10px;
        background: #e53e3e;

        &:active,
        &:hover {
          background: #e53e3e;
        }

        & > p {
          color: $app_white_absolute;
        }
      }
    }

    .close {
      position: absolute;
      top: 16px;
      right: 16px;

      & > button {
        background: #fbfcfe;
        height: 30px;
        width: 30px;
        padding: 0;
        box-sizing: border-box;

        svg {
          fill: #718096;

          path {
            fill: #718096;
          }
        }
      }

      &_dark {
        & > button {
          background: transparent !important;

          &:hover,
          &:active {
            background: rgba(
              $color: $app_darkmode_text1,
              $alpha: 0.05
            ) !important;
          }

          svg {
            fill: $app_darkmode_text1;

            path {
              fill: $app_darkmode_text1;
            }
          }
        }
      }
    }

    &_dark {
      border: 1px solid rgba($color: $app_darkmode_text1, $alpha: 0.1);
      -moz-box-shadow: none;
      -webkit-box-shadow: none;
      box-shadow: none;
      background: $app_darkmode_background_header1;
    }
  }
}
