@import '../../../theme/commons.module.scss';
@import '../../../theme/color.scss';

.img_wrapper {
  width: 100%;
  display: flex;
  justify-content: center;

  & > img {
    width: 490px;
    height: 349px;
  }
}

.wrapper {
  position: relative;
  padding: 0 90px;
  padding-bottom: 183px;
  box-sizing: border-box;
}

@media screen and (max-width: 900px) {
  .img_wrapper {
    margin: 8px 0;

    & > img {
      width: 90vw;
      height: auto;
    }
  }

  .wrapper {
    width: 100%;
    padding: 0 30px;
    padding-bottom: 50px;
  }
}
