@import '../../theme/commons.module.scss';
@import '../../theme/color.scss';

.search_unit {
  position: relative;
  width: 100%;
  box-sizing: border-box;

  .icon {
    position: relative;
    height: 46px;
    width: 46px;
    margin-right: 16px;
    border-radius: 4px;

    &_light {
      background: #f9fafc;
    }

    &_dark {
      background: $app_darkmode_background3;
    }
  }

  .space_logo {
    position: relative;
    height: 46px;
    width: 46px;
    border-radius: 4px;
    overflow: hidden;

    & > img {
      position: relative;
      height: 100%;
      width: 100%;
      object-fit: cover;
      object-position: center;
    }

    &_default {
      position: relative;
      height: 46px;
      width: 46px;
      background: #7088dd;

      & > h5 {
        position: relative;
        font-size: 108%;
        font-weight: unset;
        font-family: 'WorkSans-SemiBold';
        color: #fff;
        text-align: center;
      }
    }
  }

  .file_icon {
    position: relative;
    height: 30px;
    width: 30px;
    object-fit: contain;
    object-position: center;
  }

  .info {
    position: relative;
    width: calc(100% - 62px);
    box-sizing: border-box;

    .title {
      position: relative;
      width: 100%;
      justify-content: flex-start;
      cursor: pointer;

      & > h5 {
        position: relative;
        font-size: 105%;
        font-weight: unset;
        font-family: 'WorkSans-Regular';
        max-width: 100%;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        color: #2d3748;
      }

      &_dark {
        & > h5 {
          color: #c3d0e5;
        }
      }
    }

    & > div:nth-child(2) {
      position: relative;
      width: 100%;
      margin-top: 16px;
      justify-content: flex-start;
      overflow: hidden;
      box-sizing: border-box;
    }

    .author_avatar {
      position: relative;
      height: 30px;
      width: 30px;
      margin-right: 8px;
    }

    .texts {
      position: relative;
      width: calc(100% - 40px);
      box-sizing: border-box;
      justify-content: flex-start;
      overflow: hidden;
      box-sizing: border-box;
    }

    .author,
    .space,
    .date {
      position: relative;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      font-size: 88%;
      font-weight: unset;
      font-family: 'WorkSans-Regular';
      color: #718096;

      &_dark {
        color: #c3d0e5;
        opacity: 0.8;
      }
    }

    .date,
    .space {
      margin-left: 5px;
    }

    .space {
      margin-left: 4px;
    }
  }
}
