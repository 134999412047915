@import '../../../../theme/commons.module.scss';
@import '../../../../theme/color.scss';
@import './Assets.scss';
@import '../commons.module.scss';

.footer_wrap {
  position: relative;
  width: 100%;
  // margin-top: 20px;
  padding-top: 40px;
  padding-bottom: 8px;
  // background: rgba($color: #111, $alpha: 1);
  // background: $app_dominant_purple;
  &::before {
    position: absolute;
    background: linear-gradient(
      48.43deg,
      rgba(90, 70, 222, 0.1) 42.45%,
      rgba(90, 70, 222, 0) 68.4%
    );
    opacity: 0.2;
    content: ' ';
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: inline-block;
  }

  .footer_raw {
    padding: 0 50px;
    width: 100%;
    box-sizing: border-box;

    &_first {
      position: relative;
      width: 100%;
      box-sizing: border-box;
      display: flex;
      justify-content: space-between;
      align-items: flex-start;

      &_quick_link {
        position: relative;
        display: inline-block;
        vertical-align: top;
        text-align: right;

        .quick_link_title {
          position: relative;
          text-align: right;
          // border: 1px solid red;

          & > h1 {
            font-family: 'WorkSans-SemiBold';
            font-size: 110%;
            line-height: 19px;
            color: $app_blue_shade1;
          }
        }

        .links {
          position: relative;
          min-width: 200px;
          display: inline-block;
          // border: 1px solid red;

          & > ul {
            list-style-type: none;
            list-style: none;
            & > li {
              position: relative;
              margin-top: 12px;

              & > h5 {
                color: $app_blue_shade1;
                font-weight: unset;
                font-family: 'WorkSans-Regular';
                font-size: 100%;
                cursor: pointer;
              }

              a {
                position: absolute;
                text-decoration: none;
                height: 100%;
                width: 100%;
                left: 0;
                top: 0;
              }
            }

            & > li:last-child {
              & > h5,
              & > a {
                color: #4769e4;
                font-family: 'WorkSans-SemiBold';
              }
            }
          }
        }
      }

      &_logo {
        position: relative;
        display: inline-block;
        vertical-align: top;
        min-height: 40px;
        // border: 1px solid red;

        p,
        h5 {
          color: $app_blue_shade1;
        }

        &_actual,
        &_msg {
          // border: 1px solid red;
          vertical-align: top;
        }

        &_actual {
          position: relative;
          display: inline-block;

          &_raw {
            position: relative;
          }
        }

        &_msg {
          position: relative;
          margin: 0;
          margin-top: 8px;
          max-width: 260px;
          overflow-wrap: break-word;

          & > p {
            color: $app_blue_shade1;
            font-size: 95%;
          }
        }
      }
    }

    &_resources {
      position: relative;
      width: 100%;
      margin-top: 40px;
      margin-bottom: 20px;
      box-sizing: border-box;
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      // border: 1px solid red ;

      .soc_med,
      .credits {
        position: relative;
        display: inline-block;
        vertical-align: top;
        // border: 1px solid red;
      }

      .soc_med {
        & > ul {
          position: relative;
          display: inline-block;
          vertical-align: middle;
          min-width: 200px;
          list-style: none;
          list-style-type: none;

          & > li {
            position: relative;
            float: left;

            & > a {
              position: absolute;
              height: 100%;
              width: 100%;
              top: 0;
              left: 0;
              text-decoration: none;
            }

            .soc_med_ic {
              height: 24px;
              width: 24px;
              position: relative;
              box-sizing: border-box;
            }

            .fb {
              background: url($facebook_ic_black);
              background-size: cover;
              background-position: center;
              background-repeat: no-repeat;
            }

            .twitter {
              background: url($twitter_ic_black);
              background-size: cover;
              background-position: center;
              background-repeat: no-repeat;
            }

            .insta {
              background: url($insta_ic_black);
              background-size: cover;
              background-position: center;
              background-repeat: no-repeat;
            }
          }

          & > li:not(:nth-child(1)) {
            margin-left: 8px;
          }
        }
      }

      .credits {
        min-width: 200px;
        text-align: right;

        .rights,
        & > ul {
          position: relative;
          display: inline-block;
          vertical-align: middle;
          text-align: center;
        }

        & > ul {
          list-style: none;
          list-style-type: none;
          margin-right: 15px;
          // border: 1px solid red;

          & > li {
            position: relative;
            // border: 1px solid red;
            float: left;
            padding: 4px 8px;
            box-sizing: border-box;

            a {
              font-family: 'WorkSans-Regular';
              font-size: 100%;
              text-decoration: none;
              display: inline-block;
              color: $app_blue_shade1;
            }
          }

          & > li:not(:nth-child(1)) {
            margin-left: 12px;

            &::before {
              position: absolute;
              height: calc(100% - 12px);
              border-left: 2px solid $app_white_absolute;
              border-radius: 4px;
              top: 6px;
              left: -7px;
              content: ' ';
            }
          }
        }

        .rights {
          & > h5 {
            font-size: 95%;
            font-weight: unset;
            color: $app_blue_shade1;

            p,
            span {
              color: $app_blue_shade1;
            }
          }
        }
      }
    }
  }
}

@media screen and (min-width: $max_width_fix_home) {
  .footer_wrap {
    .footer_raw {
      margin-left: calc((100% - #{$max_width_fix_home}) / 2);
      width: $max_width_fix_home;
    }
  }
}

@media screen and (max-width: 720px) {
  .footer_wrap {
    .footer_raw {
      padding: 0 16px;

      &_first {
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-start;
        // border: 1px solid red;

        &_quick_link {
          margin-top: 20px;
          text-align: left;

          .quick_link_title {
            text-align: left;
          }
        }
      }

      &_resources {
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-start;

        .credits {
          text-align: left;
          margin-top: 16px;
          // border: 1px solid red;

          & > ul,
          .rights {
            display: block;
            // border: 1px solid red;
            float: left;

            li {
              padding: 0;
            }
          }

          .rights {
            margin-top: 2px;
          }
        }
      }
    }
  }
}
