@import '../../theme/commons.module.scss';
@import '../../theme/color.scss';

.delete_note {
  position: relative;
  width: 100%;

  .actual {
    position: relative;
    width: 100%;
    box-sizing: border-box;
    background: transparent;

    & > h4:nth-child(1) {
      font-size: 105%;
      font-weight: unset;
      font-family: 'WorkSans-Regular';
      max-width: 95%;
      margin-left: 5px;
      margin-top: 8px;
      text-align: center;
      box-sizing: border-box;
    }

    .spinner_wrap {
      position: relative;
      width: 100%;
      margin-top: 14px;
      display: inline-block;

      .raw {
        position: relative;
        width: 100%;
        text-align: center;
      }

      &_dark {
        .raw {
          svg {
            circle {
              stroke: $app_white_light;
            }
          }
        }
      }
    }

    .actions {
      position: relative;
      margin-top: 40px;

      .confirm {
        height: 40px;
        width: 120px;

        &:hover {
          div {
            box-shadow: none;
          }
        }
      }

      .confirm,
      .cancel {
        p {
          font-family: 'WorkSans-Medium';
          font-size: 110%;
        }
      }

      .cancel {
        position: relative;
        height: 40px;
        width: 120px;
        overflow: hidden;
        border-radius: 6px;
        margin-right: 8px;

        &_raw {
          position: relative;
          height: 40px;
          width: 120px;

          & > p {
            position: relative;
            font-size: 105%;
            font-weight: unset;
            font-family: 'WorkSans-SemiBold';
            text-align: center;
          }
        }
      }
    }
  }

  &_dark {
    .actual {
      background: transparent;

      & > h4:nth-child(1) {
        color: $app_darkmode_text1;
      }

      .cancel {
        border: none;
        background: rgba($color: $app_darkmode_text1, $alpha: 0.1);

        &:hover,
        &:active {
          background: rgba($color: $app_darkmode_text1, $alpha: 0.05);
        }

        & > h4,
        & > h3,
        & > h5,
        p {
          color: $app_darkmode_text1;
          opacity: 1;
          font-weight: unset;
          font-family: 'WorkSans-SemiBold';
        }
      }
    }
  }
}
