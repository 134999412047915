@import '../../theme/commons.module.scss';
@import '../../theme/color.scss';

.tags_list_empty {
  position: relative;
  width: 100%;
  display: inline-block;
  padding: 8px 4px;
  margin-bottom: 8px;
  margin-top: 30px;
  box-sizing: border-box;

  & > div {
    position: relative;
    width: calc(100% - 8px);
    // margin-left: -6px;
  }

  & > div:nth-child(1) {
    box-sizing: border-box;

    svg {
      height: 24px;
      width: 24px;
      fill: $app_black_shade3;

      path {
        fill: $app_black_shade3;
      }
    }
  }

  & > div:nth-child(2) {
    margin-top: 8px;
    flex-direction: column;

    & > h5,
    & > h4 {
      font-weight: unset;
      color: $app_black_shade1;
    }

    & > h4 {
      // letter-spacing: 0.4px;
      font-size: 108%;
      font-weight: unset;
      font-family: 'WorkSans-Regular';
      opacity: 0.9;
      color: $app_black_shade5;
    }

    & > h5 {
      position: relative;
      max-width: 100%;
      box-sizing: border-box;
      margin-top: 4px;
      font-weight: unset;
      font-family: 'WorkSans-Regular';
      font-size: 85%;
      opacity: 0.8;
      text-align: center;
    }
  }

  &_dark {
    & > div:nth-child(1) {
      svg {
        fill: $app_darkmode_text1;

        path {
          fill: $app_darkmode_text1;
        }
      }
    }

    & > div:nth-child(2) {
      & > h5,
      & > h4 {
        color: $app_darkmode_text1;
      }

      & > h4 {
        color: #fff;
      }
    }
  }
}
