@import '../../theme/color.scss';
@import '../../theme/commons.module.scss';

.tags_preview {
  position: relative;
  width: 100%;

  & > ul {
    position: relative;
    list-style: none;
    list-style-type: none;
    width: 100%;
    box-sizing: border-box;

    & > li {
      position: relative;
      display: inline-block;
      vertical-align: middle;
      margin-right: 6px;
      margin-top: 3px;
      box-sizing: border-box;

      .add_tag {
        position: relative;
        background: #f5f9ff;
        border-color: #f5f9ff;
        height: 33px;
        padding: 2px 16px;
        border-radius: 4px;
        box-sizing: border-box;
        overflow: hidden;

        &:active,
        &:hover {
          background: #f5f9ff;
          border-color: #f5f9ff;
        }

        & > p {
          position: relative;
          color: #6283fa;
          font-size: 105%;
          font-weight: unset;
          overflow: hidden;
          font-family: 'WorkSans-Regular';
        }

        &_dark {
          background: #2d3748 !important;
          border-color: transparent;

          &:active,
          &:focus,
          &:hover {
            border-color: transparent;
            background: rgba($color: #2d3748, $alpha: 0.8) !important;
          }

          & > p {
            font-family: 'WorkSans-Regular';
            color: #fff;
          }
        }
      }
    }
  }
}
