@import '../../theme/color.scss';
@import '../../theme/commons.module.scss';

.radio_button {
  position: relative;
  box-sizing: border-box;
  cursor: pointer;

  .icon {
    position: relative;
    height: 16px;
    width: 16px;
    border-radius: 50%;
    overflow: hidden;
    box-sizing: border-box;

    .inner {
      position: relative;
      height: 100%;
      width: 100%;
      border-radius: 50%;
      box-sizing: border-box;
      border: 10px solid #4769e4;
      top: 0;
      left: 0;

      &_inactive {
        border: 4px solid transparent;
      }

      &_active {
        border: 4px solid #4769e4;
      }
    }

    &_active {
      border: 2px solid #4769e4;
    }

    &_inactive {
      border: 2px solid rgba($color: #e2e8f0, $alpha: 1);
    }

    &_dark {
      background: #222a34;

      &_inactive {
        border-width: 2px;
      }

      &_active {
        border: 2px solid #4769e4;
      }
    }
  }

  & > p,
  & > h5 {
    position: relative;
    font-size: 100%;
    margin-left: 8px;
    text-align: left;
    font-weight: unset;
    font-family: 'WorkSans-Regular';
    max-width: calc(100% - 30px);
  }

  & > h5 {
    & > p {
      position: relative;
      font-size: 100%;
      text-align: left;
      font-weight: unset;
      font-family: 'WorkSans-Regular';
      max-width: 100%;
    }
  }

  &_with_label {
    justify-content: flex-start;
  }

  &_dark {
    .icon {
      &_inactive {
        border: 2px solid rgba($color: #e2e8f0, $alpha: 0.5);
      }
    }

    & > p,
    & > h5 {
      color: $app_darkmode_text1 !important;
    }

    & > h5 {
      color: $app_darkmode_text1;

      & > p {
        color: $app_darkmode_text1;

        a,
        span {
          color: #fff;
          text-decoration: none;
        }

        a {
          &:hover {
            text-decoration: underline;
          }
        }
      }
    }
  }
}
