@import '../../theme/color.scss';
@import '../../theme/commons.module.scss';

.table_formats_menu {
  position: fixed;
  top: 100px;
  left: 24px;
  padding: 2px;
  width: unset;
  box-sizing: border-box;
  z-index: calc(#{$high_front} + 8);

  &_cta {
    position: relative;
    box-sizing: border-box;
    height: 26px;
    width: 26px;

    & > button {
      position: relative;
      box-sizing: border-box;
      padding: 0 !important;
      height: 24px;
      width: 24px;
      outline: none;
      border-radius: 50%;
      background: $app_white_absolute;
      -webkit-box-shadow: 0px 4px 8px rgba(16, 24, 64, 0.1);
      box-shadow: 0px 4px 8px rgba(16, 24, 64, 0.1);
      border: 1px solid #f4f7fb;
      cursor: pointer;

      svg {
        height: 12px;
        width: 12px;
        margin: 0;
        padding: 0;

        path {
          fill: #718096;
        }
      }
    }
  }

  &_options {
    position: relative;
    border-radius: 8px;
    background: $app_white_absolute;
    border: 1px solid rgba(113, 128, 150, 0.05);
    -webkit-box-shadow: 0px 4px 8px rgba(16, 24, 64, 0.1);
    box-shadow: 0px 4px 8px rgba(16, 24, 64, 0.1);
    border: 1px solid #f4f7fb;
    padding: 12px 16px;
    z-index: calc(#{$high_front} + 8);

    & > div:nth-child(1) {
      position: relative;
      align-items: flex-start;

      & > div {
        position: relative;
        cursor: pointer;
        width: 100%;

        & > p {
          position: relative;
          font-weight: unset;
          font-size: 94%;
          font-family: 'WorkSans-Regular';
        }
      }

      & > div:not(:nth-child(1)) {
        margin-top: 8px;
      }
    }

    &_dark {
      -webkit-box-shadow: none;
      box-shadow: none;
      background: #1a1e24;
      border: 1px solid transparent;
    }

    &_hidden {
      height: 0;
      width: 0;
      padding: 0;
      opacity: 0;
      overflow: hidden;
      -webkit-box-shadow: none;
      box-shadow: none;
      border: none;
      background: transparent;
    }
  }

  &_hidden {
    top: -1000px !important;
    left: -1000px !important;
    opacity: 0;
  }
}
