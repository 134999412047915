@import '../../../theme/commons.module.scss';

$max_width_fix_home: 1250px;

.wrapper {
  position: relative;
  margin: unset;
  height: 100%;
  width: 100%;
  // padding-bottom: 20px;
  padding-bottom: 0;
  box-sizing: border-box;
}

.adjustable_absolute {
  position: relative;
  min-height: 100%;
  width: 100%;
  display: inline-block;
  top: 0;
  left: 0;
  padding: 0;
  // border: 10px solid blue;
  box-sizing: border-box;
  overflow-x: hidden;
}

@media screen and (max-width: 900px) {
  .adjustable_absolute {
    overflow-x: unset;
  }
}
