@import '../../theme/color.scss';
@import '../../theme/commons.module.scss';

.priority {
  position: relative;

  & > button {
    height: 30px;
    width: 30px;
    padding: 0;

    &:hover,
    &:active {
      background: transparent;
    }
  }

  .priority_button_dark {
    background: transparent;

    &:hover,
    &:active,
    &:focus {
      background: rgba($color: $app_darkmode_text1, $alpha: 0.05) !important;
    }
  }

  .priority_options {
    position: absolute;
    top: 100%;
    left: -4px;
    display: inline-block;
    background: $app_white_absolute;
    border: 1px solid rgba(113, 128, 150, 0.05);
    -webkit-box-shadow: 0px 4px 8px rgba(16, 24, 64, 0.1);
    box-shadow: 0px 4px 8px rgba(16, 24, 64, 0.1);
    padding: 8px 4px;
    border-radius: 8px;
    box-sizing: border-box;
    z-index: calc(#{$high_front} + 7);

    &_dark {
      background: #1a1e24;
      border: 1px solid transparent;
      -webkit-box-shadow: none;
      box-shadow: none;
    }

    &_content {
      justify-content: flex-start;

      & > button {
        position: relative;
        padding: 4px 8px;
        padding-right: 16px;
        box-sizing: border-box;
        width: 100%;
        justify-content: flex-start;

        & > p {
          position: relative;
          margin-left: 10px;
          font-size: 105%;
          font-weight: unset;
          font-family: 'WorkSans-Regular';
        }
      }

      & > button:not(:nth-child(1)) {
        margin-top: 4px;
      }

      &_dark {
        & > button {
          background: transparent !important;

          &:hover,
          &:active {
            background: rgba(
              $color: $app_darkmode_text1,
              $alpha: 0.05
            ) !important;
          }
        }
      }
    }
  }
}
