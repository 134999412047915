.code_block {
  position: relative;

  select {
    position: absolute;
    top: 0;
    background: white;
    right: 0;
  }
}
