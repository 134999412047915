@import '../../theme/color.scss';
@import '../../theme/commons.module.scss';

.drag_file_container {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
}

.drag_file {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  border: 2px dashed #d8dae4;
  border-radius: 4px;
  box-sizing: border-box;
  background: linear-gradient(0deg, #fafbff, #fafbff), #ffffff;
  cursor: pointer;

  &_dark {
    background: transparent;
    background: rgba($color: #1a1e24, $alpha: 0.8);
  }

  .content {
    position: relative;
    width: 100%;
    margin-top: 120px;

    .icon {
      position: relative;

      svg {
        height: 36px;
        width: 36px;
        fill: $app_gray_shade7;
      }

      &_dark {
        svg {
          fill: $app_darkmode_text1;
        }
      }
    }

    &_dark {
      position: relative;
    }

    & > h1 {
      position: relative;
      margin-top: 16px;
      text-align: center;
      font-size: 120%;
      line-height: unset;
      font-weight: unset;
      font-family: 'WorkSans-Medium';
    }
  }
}
