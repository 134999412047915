@import '../../theme/color.scss';
@import '../../theme/commons.module.scss';

.create_tag {
  position: relative;
  width: 100%;
  padding: 4 px 0;
  box-sizing: border-box;

  .label {
    position: relative;
    width: 100%;
    display: inline-block;
    margin-top: 10px;

    & > h4 {
      font-size: 105%;
      font-weight: unset;
      font-family: 'WorkSans-Medium';
    }

    &_dark {
      & > h4 {
        color: #fff !important;
      }
    }
  }

  .tag_input {
    position: relative;
    width: 100%;
    margin-top: 20px;

    & > div:nth-child(1) {
      margin: 0;

      label {
        display: none;
      }

      p {
        font-weight: unset;
        font-family: 'WorkSans-Medium' !important;
        font-size: 90% !important;
      }
    }

    input {
      height: 40px;
      font-size: 80% !important;
      font-weight: unset;
      font-family: 'WorkSans-Regular' !important;
    }

    &_dark {
      input[type='text'],
      input[type='password'] {
        background: transparent !important;
        border: 2px solid #28303b;
        color: $app_darkmode_text1;

        &:focus,
        &:active {
          background: transparent !important;
        }
      }
    }
  }

  .actions_wrap {
    position: relative;
    height: 35px;
    width: 100%;
    margin-top: 25px;

    & > div:nth-child(1) {
      position: absolute;
      right: 0;
      height: 100%;

      .spinner_wrap {
        position: relative;
        display: inline-block;
        margin-top: 4px;

        &_raw {
          position: relative;
          display: inline-block;
        }

        &_dark {
          svg {
            circle {
              stroke: $app_white_light;
            }
          }
        }
      }

      .confirm {
        display: inline-block;
        vertical-align: middle;
        background: $app_dominant_purple;
        padding: 0px 20px;
        height: 38px;
        box-sizing: border-box;

        & > h5,
        & > h4 {
          position: relative;
          font-weight: unset;
          font-family: 'WorkSans-Regular';
          font-size: 110%;
          color: $app_white_absolute;
        }
      }
    }
  }
}
