@import '../../theme/color.scss';
@import '../../theme/commons.module.scss';

.subscribers_preview {
  position: relative;
  width: 100%;
  padding-top: 8px;
  padding-bottom: 12px;
  box-sizing: border-box;
  justify-content: flex-end;

  & > ul {
    position: relative;
    box-sizing: border-box;
    max-width: calc(100% - 35px);
    padding: 4px 0;
    padding-right: 4px;
    box-sizing: border-box;
    list-style: none;
    list-style-type: none;

    .remove {
      position: absolute;
      height: 20px;
      width: 20px;
      top: -8px;
      right: -4px;
      border-radius: 50%;
      background: #fff;
      overflow: hidden;
      box-shadow: 0px 4px 50px rgba(16, 24, 64, 0.04);
      -webkit-box-shadow: 0px 4px 50px rgba(16, 24, 64, 0.04);
      -moz-box-shadow: 0px 4px 50px rgba(16, 24, 64, 0.04);
      z-index: calc(#{$high_front} + 9);
      display: none;

      & > button {
        position: relative;
        height: 20px;
        width: 20px;
        padding: 0;
        border: 50%;
        box-sizing: border-box;

        svg {
          height: 10px;
          width: 10px;
          fill: #718096;

          path {
            fill: #718096;
          }
        }
      }
    }

    .default_avatar {
      position: relative;
      height: 30px;
      width: 30px;
      border-radius: 50%;
      overflow: hidden;
      background: $app_black_shade1;

      svg {
        fill: #fff;
        path {
          fill: #fff;
        }
      }
    }

    .avatar {
      position: relative;
      height: 30px;
      width: 30px;
      cursor: pointer;

      span {
        & > div {
          font-size: 70%;
          color: #fff;
        }
      }
    }

    & > li {
      position: relative;
      display: inline-block;
      vertical-align: middle;
      height: 30px;
      width: 30px;
      box-sizing: border-box;

      &:hover,
      &:active {
        & > div:last-child {
          display: flex !important;
        }
      }
    }

    & > li:not(:nth-child(1)) {
      margin-left: -5px;
    }
  }

  .can_modify {
    & > li {
      display: unset;
      float: left;
    }

    & > li:not(:nth-child(1)) {
      margin-left: 0;
    }
  }

  .more {
    position: relative;
    height: 30px;
    width: 30px;
    margin-left: 4px;

    & > button {
      position: relative;
      padding: 0;
      box-sizing: border-box;
      height: 30px;
      width: 30px;
    }

    &_dark {
      & > button {
        color: $app_darkmode_text1 !important;
        background: transparent !important;

        &:hover,
        &:active {
          background: rgba($color: $app_darkmode_text1, $alpha: 0.2) !important;
        }
      }
    }
  }
}

@media screen and (max-width: 900px) {
  .subscribers_preview {
    & > ul {
      .remove {
        display: flex;
      }

      .default_avatar {
        height: 34px;
        width: 34px;
      }
    }
  }
}
