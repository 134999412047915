@import '../../theme/color.scss';

.modal_header {
  & > h5 {
    position: relative;
    font-size: 105%;
    font-weight: unset;
    font-family: 'Satoshi-Medium';
    text-align: left;
  }
}

.modal_close_dark {
  background: transparent !important;

  &:hover,
  &:active {
    background: rgba($color: $app_darkmode_text1, $alpha: 0.05) !important;
  }

  svg {
    fill: $app_darkmode_text1;

    path {
      fill: $app_darkmode_text1;
    }
  }
}
