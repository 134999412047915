@import '../../theme/color.scss';
@import '../../theme/commons.module.scss';

.header {
  position: fixed;
  height: 64px;
  width: 100%;
  top: 0;
  left: 0;
  box-sizing: border-box;
  padding: 0 24px;
  background: $app_white_absolute;
  border-bottom: 2px solid #f4f7fb;
  z-index: calc(#{$high_front} + 9);

  .left,
  .right {
    position: relative;
    width: 300px;
    top: 0;
    box-sizing: border-box;
    justify-content: flex-start;
  }

  .left {
    .logo {
      position: relative;
      height: 40px;
      width: 99%;
      padding: 4px 2px;
      box-sizing: border-box;
      background: transparent;
      justify-content: flex-start;

      &_dark {
        background: transparent;

        &:hover,
        &:active {
          background: rgba($color: $app_darkmode_text1, $alpha: 0.05);
        }
      }

      .arrow_back {
        position: relative;
        height: 100%;
        width: 100%;
        transform: rotate(-90deg);
        box-sizing: border-box;

        svg {
          height: 16px;
          width: 16px;
        }

        &_light {
          svg {
            path {
              fill: #718096;
            }
          }
        }

        &_dark {
          svg {
            path {
              fill: #fff;
            }
          }
        }
      }
    }

    .logo_texts {
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      margin-left: 8px;
      width: calc(100% - 50px);

      & > h5 {
        position: relative;
        font-weight: unset;
        margin: 0;
        padding: 0;
        line-height: 100%;
        font-size: 100%;
        max-width: 130px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      & > h5:nth-child(2) {
        margin-top: 4px;
        font-size: 105%;
        font-weight: unset;
        box-sizing: border-box;
        max-width: 100%;
        overflow: hidden;
        font-family: 'WorkSans-Medium';
      }
    }
  }

  .content {
    position: relative;
    width: calc(100% - 600px);
    justify-content: space-between;
    box-sizing: border-box;

    &_loading {
      position: relative;
      width: 100%;
      height: 30px;
      box-sizing: border-box;

      .skeleton {
        height: 28px;
        background: #f1f2f2; // #f9fafc;
        border-radius: 6px;
        width: 100%;
        position: relative;
        animation: opacity-load 2s ease-in-out infinite;

        &_dark {
          background: #1a1e24;
        }
      }
    }
  }

  .content_left {
    position: relative;

    .undo {
      margin-left: 8px;
    }
  }

  .common_button {
    position: relative;
    padding: 4px 8px;
    background: #fbfcfe !important;
    border: 1px solid #e2e8f0 !important;
    border-radius: 4px;
    margin: 0 4px;
    box-sizing: border-box;

    & > p {
      font-size: 110%;
      font-weight: unset;
      font-family: 'WorkSans-Regular';
      margin-left: 8px;
    }

    &_dark {
      background: #222a34 !important;
      border: 1px solid #222a34 !important;

      &:hover,
      &:active,
      &:focus {
        background: rgba($color: #222a34, $alpha: 0.8) !important;
      }

      svg {
        fill: $app_darkmode_text1;
        path {
          fill: $app_darkmode_text1;
        }
      }

      & > p {
        color: $app_darkmode_text1;
      }
    }
  }

  .content_actions {
    position: relative;

    .copy_link,
    .properties {
      position: relative;

      & > p {
        font-weight: unset;
        font-family: 'WorkSans-Regular';
      }
    }

    .more_mobile_options {
      display: none;
    }

    .more_mobile,
    .share_mobile {
      display: none;
    }

    .create,
    .done {
      margin-left: 8px;
      padding-left: 12px;
      padding-right: 12px;

      & > p,
      & > h5 {
        font-weight: unset;
        font-family: 'WorkSans-Regular';
      }
    }

    .done {
      &_active {
        // background: #00cc8f !important;
        background: #26c84d !important;
      }

      & > div:nth-child(1) {
        svg {
          fill: #fff;
        }
      }
    }

    .link_copied {
      position: absolute;
      top: calc(100% + 10px);
      right: 50%;
      left: unset;
      z-index: calc(#{$high_front} + 7);

      h5 {
        white-space: nowrap;
      }
    }
  }

  .right {
    box-sizing: border-box;
    justify-content: flex-end;

    .user {
      position: relative;
      cursor: pointer;

      .avatar {
        position: relative;
        height: 40px;
        width: 40px;

        span {
          color: #fff;

          & > div {
            color: #fff;
          }
        }
      }

      .name {
        position: relative;
        margin: 0 8px;

        & > p {
          position: relative;
          font-weight: unset;
          font-size: 100%;
          line-height: 200%;
          font-family: 'WorkSans-Regular';
          text-align: left;
          max-width: 120px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          user-select: none;
        }

        &_dark {
          & > p {
            color: $app_darkmode_text1;
          }
        }
      }

      .arrow {
        position: relative;
        transition: transform 0.15s ease-in;

        &_active {
          transform: rotate(-180deg);
        }

        &_dark {
          svg {
            fill: $app_darkmode_text1;
            path {
              fill: $app_darkmode_text1;
            }
          }
        }
      }
    }

    .cta {
      position: relative;

      .login,
      .signup {
        position: relative;
        padding-left: 12px;
        padding-right: 12px;

        & > p {
          font-weight: unset;
          font-size: 115%;
          font-family: 'WorkSans-Medium';
        }
      }

      .login {
        &_dark {
          color: $app_darkmode_text1 !important;

          background: rgba($color: $app_darkmode_text1, $alpha: 0.05);
          &:hover {
            background: rgba($color: $app_darkmode_text1, $alpha: 0.1);
          }

          & > p,
          & > h5 {
            color: $app_darkmode_text1 !important;
          }
        }
      }

      .signup {
        margin-left: 8px;
      }
    }
  }

  &_dark {
    background: $app_darkmode_background_header1;
    border-bottom: 2px solid #28303b;
  }

  &_ios_app {
    height: 99px;
    box-sizing: border-box;
    padding-top: 35px;
  }
}

@media screen and (max-width: 1160px) {
  .header {
    .content_left {
      // hide redo & undo texts
      .undo,
      .redo {
        & > p {
          display: none;
        }
      }
    }
  }
}

@media screen and (max-width: 1150px) {
  .header {
    padding: 0 16px;

    .left,
    .right {
      width: 200px;
    }

    .content {
      width: calc(100% - 400px);
    }
  }
}

@media screen and (max-width: 900px) {
  .header {
    width: 100vw;
    padding: 0 8px;

    .right {
      display: none;
      height: 0;
    }

    .left {
      width: 150px;
      box-sizing: border-box;
    }

    .content {
      width: calc(100% - 160px);
      margin-left: 10px;
    }

    .content_actions {
      .properties {
        & > p {
          display: none;
          height: 0;
        }
      }

      .more_mobile {
        display: flex;
      }

      .done {
        margin-left: 4px;
      }

      .more_mobile_options {
        position: absolute;
        top: calc(100% + 20px);
        right: 8px;
        display: flex;
        background: $app_white_absolute;
        border: 1px solid #f4f7fb;
        -webkit-box-shadow: 0px 4px 8px rgba(16, 24, 64, 0.1);
        box-shadow: 0px 4px 8px rgba(16, 24, 64, 0.1);
        padding: 8px 0px;
        border-radius: 6px;

        &_dark {
          -webkit-box-shadow: none;
          box-shadow: none;
          background: #1a1e24;
          border: 1px solid transparent;
        }

        &_content {
          position: relative;
          justify-content: flex-start;
        }

        .item {
          position: relative;
          width: 100%;
          margin-top: 2px;

          & > button {
            position: relative;
            padding: 8px 16px;
            font-size: 100%;
            width: 100%;
            box-sizing: border-box;
            justify-content: flex-start;
            background: transparent !important;

            &:hover,
            &:active {
              background: transparent;
            }

            & > p {
              position: relative;
              font-size: 100%;
              font-weight: unset;
              font-family: 'WorkSans-Regular';
              margin-left: 8px;
              color: #2d3748;
            }
          }

          &_dark {
            & > button {
              svg {
                fill: rgba(195, 208, 229, 0.8);

                path {
                  fill: rgba(195, 208, 229, 0.8);
                }
              }

              & > p {
                color: $app_darkmode_text1;
              }
            }
          }
        }
      }

      .share_mobile {
        display: flex;

        &_light {
          svg {
            & > path:nth-child(1) {
              fill: none;
            }

            & > path:nth-child(2) {
              fill: #718096;
            }
          }
        }

        &_dark {
          svg {
            & > path:nth-child(1) {
              fill: none;
            }

            & > path:nth-child(2) {
              fill: $app_darkmode_text1;
            }
          }
        }
      }
    }

    &_ios_app {
      height: 99px;
      box-sizing: border-box;
      padding-top: 35px;
    }
  }
}

@media screen and (max-width: 600px) {
  .header {
    width: 100vw;
    padding-left: 0;

    .left {
      width: 46px;
      margin-right: 6px;

      .logo_texts {
        display: none;
      }
    }

    .content {
      width: calc(100% - 60px);
      margin-left: 0;
    }
  }
}

@media screen and (max-width: 400px) {
  .header {
    .left {
      margin-right: 2px;
      padding-right: 2px;
    }

    .content {
      box-sizing: border-box;
      width: calc(100% - 58px);

      .content_left {
        &_editmode {
          .undo,
          .redo {
            display: none;
          }
        }
      }
    }
  }
}
