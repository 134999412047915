@import '../../theme/color.scss';
@import '../../theme/commons.module.scss';
@import '../../theme/scroll.scss';

.mobile_filters {
  position: relative;
  width: 100%;
  padding: 8px 0;
  box-sizing: border-box;

  .title {
    position: relative;
    width: 100%;
    margin: 4px 0;
    justify-content: flex-start;

    & > h5 {
      display: inline-block;
      font-weight: unset;
      font-family: 'WorkSans-Medium' !important;
      font-size: 130%;
      margin-right: 8px;
    }

    &_dark {
      & > h5 {
        font-weight: unset;
        color: #fff;
      }

      svg {
        path {
          fill: #fff;
        }
      }
    }
  }

  .tags {
    position: relative;
    width: 100%;
    margin-top: 16px;

    & > ul {
      position: relative;
      list-style: none;
      list-style-type: none;
      width: 100%;

      & > li {
        position: relative;
        display: inline-block;
        vertical-align: middle;
        margin-top: 8px;
        margin-right: 8px;
        box-sizing: border-box;
        cursor: pointer;
      }
    }

    max-height: 240px;
    overflow: hidden;
    overflow-y: scroll;

    @include custom_scroll;

    &_dark {
      @include custom_scroll_dark;
    }
  }

  .cta {
    position: relative;
    width: 100%;
    margin-top: 30px;
    justify-content: center;

    .create_button {
      position: relative;
      height: 40px;
      width: 140px;
      font-size: 100%;

      & > p,
      & > h5 {
        font-size: 90%;
        font-family: 'WorkSans-Regular';
      }
    }
  }
}
