@import '../../theme/color.scss';
@import '../../theme/commons.module.scss';

.mobile_float {
  position: fixed;
  bottom: 20px;
  right: 16px;
  display: none;
  width: 0;

  .content {
    position: relative;
    align-items: flex-end;

    & > button {
      background: transparent;

      &:active,
      &:focus {
        background: #fff;
      }
    }

    .show_comments {
      position: relative;
      height: 55px;
      width: 55px;
      border-radius: 50%;
      background: #fff;
      border: 1px solid #f4f7fb;
      padding: 0;
      box-sizing: border-box;
      box-shadow: 0px 4px 20px rgba(70, 81, 137, 0.16);

      &_dark {
        background: #1a1e24;
        border: 1px solid transparent;
        -moz-box-shadow: 0px 4px 50px #181d23;
        -webkit-box-shadow: 0px 4px 50px #181d23;
        box-shadow: 0px 4px 50px #181d23;

        &:hover,
        &:active {
          background: rgba($color: #1a1e24, $alpha: 0.9);
        }

        & > svg {
          fill: #fff;

          path {
            fill: #fff;
          }
        }
      }
    }

    .back_top {
      background: #fff;
      border: 1px solid #f4f7fb;
      box-sizing: border-box;
      padding: 18px 12px;
      box-shadow: 0px 4px 20px rgba(70, 81, 137, 0.16);
      border-radius: 100px;

      & > p {
        position: relative;
        margin-left: 8px;
        font-weight: unset;
        font-size: 105%;
        font-family: 'WorkSans-Regular';
      }

      &_dark {
        background: #1a1e24;
        border: 1px solid transparent;
        -moz-box-shadow: 0px 4px 50px #181d23;
        -webkit-box-shadow: 0px 4px 50px #181d23;
        box-shadow: 0px 4px 50px #181d23;

        &:hover,
        &:active,
        &:focus {
          background: rgba($color: #1a1e24, $alpha: 0.95);
        }

        & > svg {
          fill: #fff;
          path {
            fill: #fff;
          }
        }

        & > p {
          color: #fff;
        }
      }
    }
  }
}

@media screen and (max-width: 900px) {
  .mobile_float {
    display: inline-block;
    width: unset;

    .content {
      & > button:nth-child(2) {
        margin-top: 8px;
      }
    }
  }
}
