@import '../../../theme/commons.module.scss';

.wrapper {
  position: relative;
  padding: 0 90px;
  padding-bottom: 183px;
  box-sizing: border-box;

  & > h4 {
    font-weight: unset;
    font-family: 'WorkSans-Medium';

    & > p {
      font-weight: unset;
      font-family: 'WorkSans-Medium';
    }
  }

  & > p {
    box-sizing: border-box;
    line-height: 160%;
  }
}

.title {
  font-weight: unset;
  font-family: 'WorkSans-SemiBold';
  font-size: 180%;
  line-height: 210%;
  box-sizing: border-box;
}

.unordered_list {
  padding-left: 24px;

  & > li:not(:nth-child(1)) {
    margin-top: 8px;
  }
}

.last_updated {
  text-align: right;
  padding-right: 150px;
  color: rgba(113, 128, 150, 1);
  font-size: 105%;
  box-sizing: border-box;
  padding: 0;
}

@media screen and (max-width: 900px) {
  .wrapper {
    width: 100%;
    padding: 0 30px;
    padding-bottom: 50px;
  }
}
