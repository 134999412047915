@import '../../../theme/color.scss';
@import '../../../theme/commons.module.scss';

.wrapper {
  position: relative;
  padding: 0 90px;
  padding-bottom: 183px;
  box-sizing: border-box;
  width: 100%;
  & > p {
    a {
      margin-left: 6px;
    }
  }
}

@media screen and (max-width: 900px) {
  .wrapper {
    width: 100%;
    padding: 0 30px;
    padding-bottom: 50px;
    box-sizing: border-box;
  }
}
