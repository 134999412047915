@import '../../theme/color.scss';
@import '../../theme/commons.module.scss';

.files_uploading {
  position: fixed;
  top: 34px;
  width: 350px;
  margin-left: calc((100% - 350px) / 2);
  z-index: calc(#{$high_front} + 10);
  justify-content: flex-start;
  box-sizing: border-box;

  & > ul {
    position: relative;
    list-style: none;
    list-style-type: none;
    width: 100%;

    & > li {
      position: relative;
      width: 100%;
      height: 38px;
      padding: 3px 8px;
      border-radius: 8px;
      box-sizing: border-box;
      background: $app_white_absolute;
      border: 1px solid #e2e8f0;
      box-shadow: 2px -1px 25px 13px rgba(230, 232, 240, 0.4);
      -webkit-box-shadow: 2px -1px 25px 13px rgba(230, 232, 240, 0.4);
      -moz-box-shadow: 2px -1px 25px 13px rgba(230, 232, 240, 0.4);
      justify-content: flex-start;

      .icon {
        position: relative;
        height: 30px;
        width: 30px;

        &_dark {
          svg {
            circle {
              stroke: $app_white_light;
            }
          }
        }
      }

      & > p {
        position: relative;
        margin-left: 8px;
        width: calc(100% - 40px);
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        font-size: 95%;
        font-family: 'WorkSans-Regular';
      }
    }

    & > li:not(:nth-child(1)) {
      margin-top: 10px;
    }
  }

  &_dark {
    position: fixed;

    & > ul {
      & > li {
        box-shadow: none;
        -webkit-box-shadow: none;
        -moz-box-shadow: none;
        background: #1a1e24;
        border: 1px solid transparent;

        & > p {
          color: #fff;
        }
      }
    }
  }
}

@media screen and (max-width: 380px) {
  .files_uploading {
    width: 90vw;
    margin-left: 5vw;
  }
}
