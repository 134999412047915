@import '../../../theme/color.scss';
@import '../../../theme/commons.module.scss';

.saved_changes {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  box-sizing: border-box;
  align-items: flex-start;

  .content {
    position: relative;
    margin-top: 24px;
    width: 100%;
    padding: 8px 4px;
    box-sizing: border-box;
    background: #f0fff4;
    border-radius: 8px;
    max-width: 200px;

    .icon {
      position: relative;
      height: 30px;
      width: 30px;
      margin-right: 4px;
    }

    & > p {
      color: #3a5a43;
    }
  }
}
