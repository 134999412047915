@import '../../../theme/color.scss';
@import '../../../theme/commons.module.scss';

.landing_features {
  position: relative;
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  margin-top: 32px;

  &_raw {
    position: relative;
    flex-direction: column;
    justify-content: flex-start;
    box-sizing: border-box;
    overflow: hidden;
    width: 1280px;
    min-height: 85vh;
    // border: 2px solid blue;
  }

  .first,
  .second,
  .features_title,
  .content {
    position: relative;
    width: 100%;
  }

  .first,
  .second {
    list-style: none;
    list-style-type: none;
    width: 100%;
    justify-content: space-between;

    & > li {
      width: calc(33% - 30px);

      .illustration {
        position: relative;
        height: 140px;
        width: 240px;

        & > img {
          position: relative;
          width: 100%;
          height: 100%;
          object-position: center;
          object-fit: contain;
        }
      }

      .title {
        position: relative;
        margin: 16px 0;
        font-size: 110%;
        font-weight: unset;
        font-family: 'WorkSans-SemiBold';
      }

      .message {
        position: relative;
        margin-top: 8px;
        font-weight: unset;
        font-family: 'WorkSans-Regular';
        font-size: 110%;
        color: #2d3748;
        max-width: 100%;
        line-height: 150%;
      }
    }
  }

  .second {
    margin-top: 24px;

    & > li:nth-child(1) {
      .illustration {
        height: 150px;
        width: 280px;
      }
    }
  }

  .features_title {
    position: relative;
    margin-bottom: 32px;

    & > h3 {
      font-weight: unset;
      font-family: 'WorkSans-SemiBold';
      font-size: 200%;
    }
  }
}

@media screen and (max-width: 1300px) {
  .landing_features {
    &_raw {
      width: 95vw;
    }
  }
}

@media screen and (max-width: 1150px) {
  .landing_features {
    &_raw {
      width: 90vw;

      .first,
      .second {
        & > li {
          .illustration {
            height: 120px;
            width: 220px;
          }

          .title {
            font-size: 105%;
          }

          .message {
            font-size: 95%;
            max-width: 100%;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 920px) {
  .landing_features {
    &_raw {
      width: 92vw;

      .first,
      .second {
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;

        & > li {
          .illustration {
            height: 140px;
            width: 240px;
          }

          width: 88%;
          box-sizing: border-box;
          position: relative;
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;

          .title {
            font-size: 110%;
          }

          .message {
            font-size: 105%;
            text-align: center;
            max-width: 70%;
          }
        }

        & > li:not(:nth-child(1)) {
          margin-top: 30px;
        }
      }

      .second {
        margin-top: 30px;
      }
    }
  }
}

@media screen and (max-width: 480px) {
  .landing_features {
    &_raw {
      width: 93vw;

      .first,
      .second {
        & > li {
          width: 90%;

          .message {
            text-align: center;
            max-width: 85%;
          }
        }
      }
    }
  }
}
