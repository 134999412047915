@import '../../../theme/commons.module.scss';
@import '../../../theme/color.scss';

.notes {
  position: relative;
  width: 100%;
  top: 0;
  box-sizing: border-box;

  .space_info {
    position: relative;
    width: 100%;
    margin-top: 84px;
    // border: 2px solid blue;
    box-sizing: border-box;
  }
}

@media screen and (max-width: 800px) {
  .notes {
    min-height: 100%;
    padding-bottom: 0;
    box-sizing: border-box;

    .space_info {
      display: none;
    }
  }
}
