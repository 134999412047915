@import '../../theme/commons.module.scss';
@import '../../theme/color.scss';

.logo_icon_word {
  position: relative;

  .logo,
  .word {
    position: relative;
  }

  .logo {
    width: 38px;
    height: 38px;

    & > img {
      position: relative;
      height: 100%;
      width: 100%;
      top: 0;
      left: 0;
      object-fit: contain;
      object-position: center;
    }

    &_medium {
      height: 50px;
      width: 50px;
    }

    &_small {
      height: 30px;
      width: 30px;
    }
  }

  .word {
    position: relative;
    height: 44px;
    width: 120px;
    margin-left: 8px;

    & > img {
      height: 100%;
      width: 100%;
      object-fit: contain;
      object-position: center;
    }

    &_medium {
      height: 65px;
      width: 135px;
    }

    &_small {
      height: 40px;
      width: 80px;
    }
  }

  .img_word_dark {
    filter: brightness(0) invert(1);
  }
}
