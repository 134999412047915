@import '../../../theme/commons.module.scss';
@import '../../../theme/color.scss';
@import '../../../theme/assets.scss';
@import '../../../theme/scroll.scss';

// $primary_all_icon_task: 'data:image/svg+xml;base64,PHN2ZyBpZD0iQ2FwYV8xIiBlbmFibGUtYmFja2dyb3VuZD0ibmV3IDAgMCA1MTIgNTEyIiBoZWlnaHQ9IjUxMiIgdmlld0JveD0iMCAwIDUxMiA1MTIiIHdpZHRoPSI1MTIiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZD0ibTUxMiAwaC0zNzQuMjg2djEzNy43MTRoLTEzNy43MTR2Mzc0LjI4NmgzNzQuMjg2di0xMzcuNzE0aDEzNy43MTR6bS0xNjcuNzE0IDQ4MmgtMzE0LjI4NnYtMzE0LjI4NmgxMDcuNzE0djIwNi41NzJoMjA2LjU3MnptMC0xMzcuNzE0aC0xNzYuNTcydi0xNzYuNTcyaDE3Ni41NzJ6bTEzNy43MTQgMGgtMTA3LjcxNHYtMjA2LjU3MmgtMjA2LjU3MnYtMTA3LjcxNGgzMTQuMjg2eiIvPjwvc3ZnPg==';

.dashboard {
  position: relative;
  min-height: 100vh;
  width: 100%;
  flex-direction: column;
  justify-content: flex-start;

  .content {
    position: relative;
    min-height: 100%;
    width: 100%;
    box-sizing: border-box;

    .panel,
    .right {
      box-sizing: border-box;
    }

    .panel {
      position: fixed;
      height: 100%;
      top: 0;
      left: 0;
      bottom: 0;
      box-sizing: border-box;
      transition: overflow 0.2s ease-out;
      transition: all 0.1s ease-out;
      overflow: visible;
      z-index: calc(#{$high_front} + 5);

      &_expand {
        width: 310px;
      }

      &_shrink {
        width: 80px;
      }
    }

    .right {
      position: relative;
      align-items: flex-start;
      justify-content: flex-start;
      min-height: 100%;
      padding-bottom: 40px;
      box-sizing: border-box;

      &_expand {
        width: calc(100% - 318px);
        margin-left: 314px;
      }

      &_shrink {
        width: calc(100% - 88px);
        margin-left: 84px;
      }
    }

    .header_mobile {
      display: none;
    }
  }
}

@media screen and (max-width: 800px) {
  .dashboard {
    position: relative;
    height: 100%;
    width: 100%;
    min-height: unset;
    max-height: unset;
    overflow: hidden;
    min-height: unset;

    .content {
      height: 100%;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;

      .header_mobile {
        position: relative;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        height: 134px;
        background-origin: border-box;
      }

      .panel {
        display: none;
        width: 0;
        height: 0;
        overflow: hidden;
      }

      .right {
        position: relative;
        background-origin: border-box;
        height: calc(100% - 206px);
        // border: 2px solid green;
        min-height: unset;
        width: 100%;
        margin-left: 0;
        padding-bottom: 4px;
        overflow-y: scroll;

        &_light {
          @include custom_scroll;
        }

        &_dark {
          @include custom_scroll_dark;
        }

        &_expand {
          margin-left: 0;
        }

        &_shrink {
          margin-left: 0;
        }

        &_mobile_no_modal {
          height: calc(100% - 160px);
        }
      }
    }
  }
}
