@import '../../theme/color.scss';
@import '../../theme/commons.module.scss';

.tag_unit {
  position: relative;
  background: $app_white_shade8;
  padding: 4px 8px;
  border-radius: 4px;
  box-sizing: border-box;
  border: 1px solid transparent;
  cursor: pointer;

  & > div:nth-child(1) {
    svg {
      fill: #4769e4;
    }
  }

  & > p {
    margin-left: 4px;
  }

  &_active {
    position: relative;
    border: 1px solid #4769e4;

    & > p {
      color: #4769e4;
    }
  }

  &_dark {
    background: #222a34;

    & > p {
      color: rgba(195, 208, 229, 0.75);
    }
  }

  &_dark_active {
    border: 1px solid #fff;

    & > div:nth-child(1) {
      svg {
        fill: #fff;
      }
    }

    & > p {
      color: #fff;
    }
  }
}
