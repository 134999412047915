@import '../../../../theme/color.scss';
@import '../../../../theme/commons.module.scss';

.tag {
  position: relative;
  width: 100%;
  padding: 4px 4px;
  padding-left: 2px;
  border-radius: 4px;
  justify-content: flex-start;
  box-sizing: border-box;

  .tag_icon {
    position: relative;
    height: 30px;
    width: 30px;
    box-sizing: border-box;
    // border: 2px solid red;

    &_selected {
      svg {
        fill: $app_dominant_purple;

        path {
          fill: $app_dominant_purple;
        }
      }
    }

    &_light {
      svg {
        fill: #718096;
        path {
          fill: #718096;
        }
      }
    }

    &_dark {
      svg {
        fill: $app_darkmode_text1;
        path {
          fill: $app_darkmode_text1;
        }
      }
    }
  }

  .input {
    position: relative;
    box-sizing: border-box;
    margin-left: 2px;
    width: calc(100% - 34px);
    justify-content: flex-start;
    cursor: pointer;

    & > p {
      position: relative;
      color: #2d3748;
      width: 100%;
      font-size: 105%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      font-weight: unset;
      font-family: 'WorkSans-Regular';
      box-sizing: border-box;
    }

    &_with_actions {
      width: calc(100% - 102px);
    }

    &_selected {
      & > p {
        color: $app_dominant_purple;
      }
    }

    &_dark {
      & > p {
        color: $app_darkmode_text1;
      }
    }
  }

  .actions {
    position: relative;
    box-sizing: border-box;
    width: 64px;
    justify-content: flex-end;

    & > div {
      & > button {
        position: relative;
        box-sizing: border-box;
        height: 30px;
        width: 30px;
        padding: 0;
      }
    }

    &_dark {
      & > div {
        & > button {
          background: transparent;

          &:hover,
          &:active {
            background: rgba(
              $color: $app_darkmode_text1,
              $alpha: 0.05
            ) !important;
          }

          svg {
            fill: $app_darkmode_text1;
            path {
              fill: $app_darkmode_text1;
            }
          }
        }
      }
    }
  }

  .edit_tag {
    position: absolute;
    height: 48px;
    width: 80%;
    box-sizing: border-box;
    left: 5%;
    top: 80%;
    padding: 4px 8px;
    border-radius: 8px;
    -webkit-box-shadow: 0px 4px 8px rgba(16, 24, 64, 0.05);
    box-shadow: 0px 4px 8px rgba(16, 24, 64, 0.05);
    background: $app_white_absolute;
    border: 1px solid #e6e8f0;
    z-index: calc(#{$high_front} + 6);

    & > input[type='text'] {
      position: relative;
      height: 40px;
      width: 100%;
      font-weight: unset;
      font-family: 'WorkSans-Regular';
    }

    &_dark {
      background: #1a1e24;
      border: 1px solid transparent;
      -webkit-box-shadow: none;
      box-shadow: none;

      & > input[type='text'] {
        background: transparent !important;
        color: #fff !important;
        border: 1px solid #28303b !important;

        &:active,
        &:focus {
          background: transparent !important;
        }
      }
    }
  }
}
