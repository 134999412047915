@import '../../../theme/commons.module.scss';
@import '../../../theme/color.scss';

.note_states {
  position: relative;
  justify-content: flex-start;
  width: 100%;
  padding-left: 20px;
  box-sizing: border-box;
  margin-top: 16px;

  & > ul {
    list-style-type: none;
    list-style: none;
    align-items: flex-start;
    justify-content: flex-start;
    box-sizing: border-box;

    & > li {
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
      box-sizing: border-box;
      border-bottom: solid transparent;
      height: 36px;

      h4 {
        position: relative;
        font-weight: unset;
        font-family: 'WorkSans-Regular';
        font-size: 125%;
        box-sizing: border-box;
      }

      & > button {
        position: relative;
        height: 36px;
      }
    }

    & > li:not(:nth-child(1)) {
      margin-left: 8px;
    }

    .is_active {
      background: transparent;

      button {
        background: $app_button_background;
        background: rgba($color: $app_dominant_purple, $alpha: 0.1) !important;

        & > h4,
        & > h3,
        & > h5 {
          font-family: 'WorkSans-Medium';
          color: $app_dominant_purple;
        }
      }
    }
  }

  &_dark {
    & > ul {
      & > li {
        h4 {
          color: $app_darkmode_text1;
          opacity: 0.3;
          font-weight: unset;
          font-family: 'WorkSans-Medium';
        }

        button {
          background: transparent !important;

          &:hover {
            background: rgba(
              $color: $app_darkmode_text1,
              $alpha: 0.05
            ) !important;
          }
        }
      }

      .is_active {
        button {
          background: rgba(
            $color: $app_darkmode_text1,
            $alpha: 0.15
          ) !important;

          & > h3,
          & > h4,
          & > h5 {
            color: $app_white_light2;
            opacity: 1;
          }

          &:hover {
            background: rgba(
              $color: $app_darkmode_text1,
              $alpha: 0.2
            ) !important;
          }
        }
      }
    }
  }
}

.filters {
  position: relative;
  width: 100%;
  box-sizing: border-box;

  .mobile,
  .desktop {
    position: relative;
    width: 100%;
  }

  .mobile {
    display: none;
  }
}

@media screen and (max-width: 800px) {
  .note_states {
    padding-left: 0;
    padding-right: 0;
    box-sizing: border-box;
    margin-top: 0;
    width: calc(100% - 48px);

    & > ul {
      position: relative;
      box-sizing: border-box;
      padding: 0;
      height: 42px;
      justify-content: center;
      align-items: center;
      border-radius: 60px !important;
      background: rgba($color: $app_button_background, $alpha: 0.8) !important;

      & > li {
        position: relative;
        box-sizing: border-box;
        padding: 0;
        margin: 0;
        margin-top: 2px;
        height: unset;
        vertical-align: middle;

        button {
          height: 40px;
          margin: 0;
          border-radius: 60px !important;
          box-sizing: border-box;
        }

        h4 {
          font-size: 13px;
        }
      }

      & > li:nth-child(1),
      & > li:last-child {
        & > button {
          padding-left: 20px;
          padding-right: 20px;
        }
      }

      & > li:not(:nth-child(1)) {
        margin-left: 2px;
      }
    }

    &_dark {
      & > ul {
        background: rgba($color: $app_darkmode_text1, $alpha: 0.04) !important;
      }
    }
  }

  .filter_button {
    position: relative;
    height: 38px;
    width: 38px;
    padding: 0 !important;
    margin: 0 !important;
    box-sizing: border-box;

    svg {
      height: 24px;
      width: 24px;
    }

    &_dark {
      svg {
        path {
          fill: $app_darkmode_text1;
        }
      }

      background: transparent !important;

      &:active {
        background: rgba($color: $app_darkmode_text1, $alpha: 0.05) !important;
      }

      &:hover {
        background: transparent !important;
      }
    }
  }

  .filters {
    padding: 0 8px;
    padding-right: 6px;
    box-sizing: border-box;
    margin-top: 30px;

    .desktop {
      display: none;
    }

    .mobile {
      display: flex;
      justify-content: space-between;
    }
  }
}
