@import '../../theme/commons.module.scss';
@import '../../theme/color.scss';

.init_user_wrap {
  position: absolute;
  height: 100vh;
  width: 100%;
  background: white;
  top: 0;
  left: 0;
  right: 0;
  box-sizing: border-box;
  overflow: hidden;

  .logo {
    position: relative;

    &_raw {
      height: 50px;
      width: 50px;
    }
  }

  &_dark {
    background: $app_darkmode_background1;
  }

  .spinner {
    position: relative;
    margin-top: 30px;

    &_dark {
      svg {
        circle {
          stroke: $app_white_light;
        }
      }
    }
  }
}
