@import '../../../theme/color.scss';
@import '../../../theme/commons.module.scss';
@import '../layout/commons.module.scss';

.landing {
  position: relative;
  display: inline-block;
  width: 100%;
  box-sizing: border-box;

  .intro {
    position: relative;
    min-height: 95vh;
    width: 100%;
    box-sizing: border-box;
    justify-content: flex-start;

    &_background {
      position: absolute;
      height: 100%;
      width: 70vw;
      top: 0;
      left: 29vw;
      box-sizing: border-box;

      & > img {
        position: relative;
        height: 100%;
        width: 100%;
        top: 0;
        left: 0;
        object-position: center;
        object-fit: cover;
      }
    }

    .content {
      position: relative;
      width: 100%;
      padding-left: 90px;
      box-sizing: border-box;
    }

    &_texts {
      position: relative;
      max-width: 620px;
      box-sizing: border-box;

      & > h2 {
        position: relative;
        font-family: 'WorkSans-Bold';
        font-size: 94px;
        line-height: 136%;
        box-sizing: border-box;
        color: #2d3748;
      }
    }

    .cta {
      position: relative;
      margin-top: 30px;
      justify-content: flex-start;
      width: 99%;

      .create_account {
        position: relative;
        height: 50px;
        background: #6283fa;
        padding: 10px 25px;
        border-radius: 100px;

        & > p {
          color: $app_white_absolute;
          font-size: 18px;
        }

        & > a {
          position: absolute;
          height: 100%;
          width: 100%;
          top: 0;
          left: 0;
        }
      }

      .watch_demo {
        position: relative;
        margin-left: 20px;
        padding: 8px 16px;
        box-sizing: border-box;

        a {
          position: relative;
          text-decoration: none;
          font-size: 16px;

          &:hover {
            text-decoration: underline;
          }

          svg {
            margin-left: 9px;
          }
        }
      }
    }
  }

  .bye_complicated {
    position: relative;
    width: 100%;
    box-sizing: border-box;
    flex-direction: column;
    margin-top: 70px;

    &_texts {
      & > h2,
      & > p {
        position: relative;
        padding: 0 16px;
        box-sizing: border-box;
        text-align: center;
      }
      & > h2 {
        font-size: 40px;
        font-family: 'WorkSans-SemiBold';
      }

      & > p {
        font-size: 17px;
        margin-top: 16px;
      }
    }

    &_create_account {
      height: 45px;
      padding: 10px 30px;
      border: 2px solid #4769e4;
      border-radius: 100px;
      margin-top: 24px;

      a {
        position: absolute;
        height: 100%;
        width: 100%;
        top: 0;
        left: 0;
      }

      p {
        font-size: 18px;
        color: #4769e4;
      }
    }

    .illustration {
      position: relative;
      width: 330px;
      height: 230px;
      margin-top: 24px;

      & > img {
        position: relative;
        height: 100%;
        width: 100%;
        object-fit: contain;
        object-position: center;
      }
    }
  }
}

@media screen and (min-width: $max_width_fix_home) {
  .landing {
    .intro {
      .content {
        padding-left: calc(((100% - #{$max_width_fix_home}) / 2) + 50px);
      }
    }
  }
}

@media screen and (max-width: 900px) {
  .landing {
    .intro {
      &_texts {
        max-width: 65vw;

        & > h2 {
          font-size: 64px;
        }
      }

      .content {
        width: 100%;
        padding-left: 30px;
      }

      .cta {
        position: relative;
        width: 100%;

        .create_account {
          padding: 4px 15px;
          height: 40px;

          & > p {
            font-size: 13.5px;
          }
        }

        .watch_demo {
          position: relative;
          margin-left: 8px;
          padding: 4px 12px;
          box-sizing: border-box;

          a {
            font-size: 13px;

            svg {
              margin-left: 8px;
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 720px) {
  .landing {
    .intro {
      &_background {
        position: absolute;
        height: 100%;
        width: 100%;
        top: 0;
        left: 0;
        box-sizing: border-box;
      }
    }

    .bye_complicated {
      margin-bottom: 30px;

      .illustration {
        position: relative;
        width: 300px;
        height: 200px;
      }
    }
  }
}

@media screen and (max-width: 550px) {
  .landing {
    .intro {
      &_texts {
        max-width: 80vw;

        & > h2 {
          font-size: 54px;
        }
      }
    }
  }
}
