@import '../../../../../theme/color.scss';
@import '../../../../../theme/commons.module.scss';

.empty_list {
  position: relative;
  width: 100%;
  align-items: center;
  padding: 16px 16px;
  margin-top: 10px;
  box-sizing: border-box;

  .message {
    position: relative;
    width: 100%;
    box-sizing: border-box;
    margin-top: 8px;
  }

  .icon {
    flex-direction: column;
    justify-content: flex-start;

    & > h3 {
      position: relative;
      text-align: center;
      margin-top: 8px;
      font-size: 130%;
      font-weight: unset;
      font-family: 'WorkSans-Medium';
    }
  }

  .create_button {
    position: relative;
    margin-left: 4px;
    height: 36px;
    padding-left: 8px;
    padding-right: 8px;
    box-sizing: border-box;
  }
}

@media screen and (max-width: 800px) {
  .empty_list {
    margin-top: 20px;

    .icon {
      svg {
        height: 170px !important ;
        width: 160px !important;
      }
    }

    .create_button {
      position: relative;
      height: 34px;
      width: 34px;
      border-radius: 50%;
      overflow: hidden;
      background: $app_dominant_purple;
      padding: 0;
      border: none;
      box-sizing: border-box;
      box-shadow: 0px 0px 32px rgba(88, 119, 234, 0.3);

      svg {
        fill: $app_white_absolute;
        height: 16px;
        width: 16px;
      }

      &:hover,
      &:active {
        background: $app_dominant_purple;
      }
    }
  }
}
