@import '../../theme/color.scss';
@import '../../theme/commons.module.scss';

.note_view {
  position: relative;
  min-height: 100vh;
  width: 100%;
  padding: 0;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding-bottom: 24px;

  &_content {
    position: relative;
    width: 100%;
    box-sizing: border-box;
    margin-top: 90px;
    padding: 0 24px;
    justify-content: center;
    align-items: flex-start;

    &_left,
    &_right {
      position: relative;
      width: 300px;
      box-sizing: border-box;
    }

    &_middle {
      width: calc(100% - 600px);
      box-sizing: border-box;
    }
  }
}

@media screen and (max-width: 1150px) {
  .note_view {
    &_content {
      &_left,
      &_right {
        width: 200px;
      }

      &_middle {
        width: calc(100% - 400px);
      }
    }
  }
}

@media screen and (max-width: 900px) {
  .note_view {
    position: fixed;
    min-height: unset;
    max-height: unset;
    height: 100%;
    width: 100vw;
    top: 0;
    left: 0;
    right: 0;
    bottom: unset;
    overflow: hidden;
    overflow-y: scroll;
    padding-bottom: 80px;
    box-sizing: border-box;

    &_content {
      box-sizing: border-box;
      width: 100%;
      padding: 0 16px;

      &_left,
      &_right {
        width: 0;
        display: none;
      }

      &_middle {
        width: 100%;
        box-sizing: border-box;
      }
    }
  }
}
