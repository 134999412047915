@import '../../../theme/commons.module.scss';
@import '../../../theme/color.scss';

.wrapper {
  position: relative;
  padding: 0 90px;
  padding-bottom: 183px;
  box-sizing: border-box;
  width: 100%;

  & > p {
    position: relative;
    width: 100%;
    box-sizing: border-box;
    text-align: left;
    line-height: 160%;
  }
}

.img_wrapper {
  display: flex;
  justify-content: center;
  align-items: flex-start;

  & > img {
    width: 300px;
    height: auto;
  }
}

.quote_wrapper {
  text-align: center;
}

.footer_container {
  position: relative;
  width: 100%;
  align-items: start;
  margin-top: 36px;
  box-sizing: border-box;
}

.footer_wrapper {
  position: relative;
  box-sizing: border-box;
  padding: 0;
  width: 25%;
  overflow: hidden;

  &_first {
    width: 20px;
    height: 20px;
    object-fit: contain;
  }

  &_second {
    width: 20px;
    height: 20px;
    box-sizing: border-box;
    object-fit: contain;
  }

  & > p,
  & > h4 {
    position: relative;
    font-weight: unset;
    max-width: 100%;
    text-align: center;
  }

  & > p {
    font-family: 'WorkSans-Regular';
  }

  & > h4 {
    font-weight: unset;
    font-family: 'WorkSans-Medium';
    margin: 16px 0;
  }
}

@media screen and (max-width: 900px) {
  .img_wrapper {
    & > img {
      width: 280px;
      height: auto;
    }
  }

  .wrapper {
    width: 100%;
    padding: 0 30px;
    padding-bottom: 50px;
  }

  .footer_container {
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;

    .footer_wrapper {
      width: 90%;
      margin: 0;
      padding: 0;
      margin-top: 24px;
    }
  }
}
