@import '../../theme/color.scss';
@import '../../theme/commons.module.scss';

.custom_button {
  position: relative;
  padding: 0 4px;
  margin: 0;

  &_secondary {
    background: #f9fafc;

    &:hover,
    &:active {
      background: #f9fafc;
      filter: grayscale(0.2);
      opacity: 0.85;
    }
  }

  &_primaryDiscard {
    background: $app_black_shade2 !important;

    &:hover,
    &:active {
      background: $app_black_shade2 !important;
      filter: grayscale(0.3);
      opacity: 0.9;
    }
  }

  &_secondaryLight {
    background: $app_gray_shade5 !important;

    &:hover,
    &:active {
      background: $app_gray_shade5 !important;
      filter: grayscale(0.3);
      opacity: 0.9;
    }
  }

  &_primaryDanger {
    background: #e53e3e !important;

    &:hover,
    &:active {
      background: rgba($color: #e53e3e, $alpha: 0.9) !important ;
      filter: grayscale(0.3);
      opacity: 0.9;
    }

    & > p {
      color: #fff !important;
    }
  }

  &_disabled {
    cursor: not-allowed;
  }

  &_primaryPurple {
    background: $app_dominant_purple !important;

    &:hover,
    &:active {
      background: $app_dominant_purple !important;
      filter: grayscale(0.2);
      opacity: 0.85;
    }
  }

  &_loading {
    opacity: 0.9;
  }

  &_primaryBlue {
    background: #6283fa !important;

    &:hover,
    &:active {
      background: #6283fa !important;
      filter: grayscale(0.2);
      opacity: 0.85;
    }
  }

  .text {
    position: relative;
    font-weight: unset;
    font-family: 'WorkSans-SemiBold';
    color: $app_black_shade1;
    font-size: 110%;

    &_primaryPurple,
    &_primaryBlue {
      color: $app_white_light !important;
    }

    &_primaryDiscard {
      color: #fff !important;
    }
  }

  .icon {
    position: relative;
    margin-right: 4px;
  }

  .loading {
    // border: 2px solid red;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;

    & > div:nth-child(1) {
      position: relative;
      margin: 0;
      box-sizing: border-box;
      height: 20px;
      width: 20px;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    svg {
      position: relative;
      position: relative;
      height: 16px;
      width: 16px;
    }

    &_primaryPurple {
      svg {
        circle {
          stroke: $app_white_light2 !important;
        }
      }
    }
  }
}
