@import '../../../theme/color.scss';
@import '../../../theme/commons.module.scss';

.note_title {
  position: relative;
  width: calc(100% - 280px - 86px - 66px - 66px - 40px - 40px - 8px);
  margin: 0 4px;
  margin-left: 8px;
  //   border: 2px solid red;
  //   background-origin: border-box;

  .date {
    position: relative;
    width: 100%;
    margin-top: 8px;
    justify-content: flex-start;

    & > p {
      font-size: 88%;
      opacity: 0.8;
    }
  }

  &_dark {
    & > p:nth-child(1) {
      font-size: 110%;
      color: #fff;
    }
  }
}

.note_link {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  cursor: pointer;
  box-sizing: border-box;

  a {
    position: absolute;
    height: 100%;
    width: 100%;
    left: 0;
    top: 0;
  }
}

.note_label {
  width: 280px;
  background-origin: border-box;

  & > ul {
    position: relative;
    width: 100%;
    list-style: none;
    list-style-type: none;

    & > li {
      position: relative;
      margin-top: 4px;
      margin-left: 4px;
      float: left;
    }

    & > li:nth-child(1) {
      margin-left: 0;
    }
  }
}

.note_id {
  width: 86px;
  background-origin: border-box;
}

.note_priority {
  height: 40px;
  width: 66px;
  box-sizing: border-box;
}

.note_author {
  height: 40px;
  width: 66px;
  background-origin: border-box;

  span {
    color: #fff;

    & > div {
      color: #fff;
    }
  }
}

.note_copy_link {
  height: 40px;
  background-origin: border-box;
  width: 40px;
}

.note_actions {
  height: 40px;
  width: 40px;
  background-origin: border-box;
}

@media screen and (min-width: 801px) and (max-width: 1040px) {
  // remove label
  .note_title {
    width: calc(100% - 86px - 66px - 66px - 40px - 40px - 8px);
  }

  .note_label {
    display: none;
  }
}

@media screen and (max-width: 800px) {
  .note_author {
    height: 44px;
    width: 60px;
  }

  .note_label {
    position: relative;
    width: 100%;
    margin-top: 8px;
  }

  .note_title {
    width: calc(100% - 110px);
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }
}
