@import '../../theme/color.scss';
@import '../../theme/commons.module.scss';

.network_error {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  padding: 8px 16px;
  box-sizing: border-box;
  z-index: calc(#{$high_front} + 4);

  &_background {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    box-sizing: border-box;

    & > img {
      position: relative;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .content {
    position: relative;
    width: 450px;
    padding: 50px;
    box-sizing: border-box;
    background: $app_white_absolute;
    -webkit-box-shadow: 0px 4px 50px rgba(16, 24, 64, 0.04);
    -moz-box-shadow: 0px 4px 50px rgba(16, 24, 64, 0.04);
    box-shadow: 0px 4px 50px rgba(16, 24, 64, 0.04);
    border-radius: 10px;

    .logo {
      position: relative;
      width: 100%;
      display: flex;
      justify-content: flex-start;

      &_raw {
        position: relative;

        & > img {
          position: relative;
          object-fit: contain;
          object-position: center;
        }

        & > img:nth-child(1) {
          height: 40px;
          width: 40px;
        }

        & > img:nth-child(2) {
          height: 50px;
          width: 120px;
          margin-left: 8px;
        }
      }
    }

    .texts {
      position: relative;
      width: 100%;
      margin-top: 24px;

      & > h2 {
        position: relative;
        font-size: 30px;
        font-weight: unset;
        font-family: 'WorkSans-SemiBold';
      }

      & > p:nth-child(2) {
        position: relative;
        color: #718096;
        font-size: 16px;
        font-weight: unset;
        font-family: 'WorkSans-Medium';
        margin: 4px 0;
      }

      & > section {
        position: relative;
        width: 100%;
        margin-top: 16px;

        & > p {
          color: #2d3748;
          font-size: 14px;
        }

        & > ul {
          position: relative;
          width: 100%;
          margin-top: 8px;
          padding-left: 20px;
          box-sizing: border-box;

          & > li {
            position: relative;
            margin-top: 8px;
            font-size: 14px;
            color: #2d3748;
          }
        }

        & > p:last-child {
          margin-top: 16px;
        }
      }
    }

    .cta {
      position: relative;
      width: 100%;
      margin-top: 24px;

      & > button {
        position: relative;
        height: 44px;
        width: 100%;
        padding: 10px 24px;
        box-sizing: border-box;
        background: #6283fa;
        border-radius: 6px;

        & > p {
          font-weight: unset;
          font-family: 'WorkSans-Medium';
          font-size: 17px;
          color: $app_white_absolute;
        }
      }
    }
  }
}

@media screen and (max-width: 480px) {
  .network_error {
    .content {
      padding: 40px 30px;
      width: 98vw;
    }
  }
}
