@import '../../theme/color.scss';
@import '../../theme/commons.module.scss';

.subscribers {
  position: relative;
  width: 100%;
  box-sizing: border-box;
  margin-bottom: 24px;
  border-bottom: 2px solid #f4f7fb;
  padding: 16px 0;
  padding-bottom: 32px;
  align-items: flex-start;

  .title,
  .content {
    position: relative;
    width: 100%;
    justify-content: flex-start;
  }

  .title {
    & > p {
      font-weight: unset;
      font-size: 105%;
      font-family: 'WorkSans-SemiBold';
      margin-left: 8px;
    }

    &_dark {
      svg {
        fill: $app_darkmode_text1;
        path {
          fill: $app_darkmode_text1;
        }
      }
    }
  }

  .content {
    margin-top: 8px;

    .input,
    .preview {
      position: relative;
      width: 50%;
      justify-content: flex-start;
    }
  }

  &_dark {
    border-bottom: 2px solid rgba($color: #28303b, $alpha: 0.9);
  }
}

@media screen and (max-width: 900px) {
  .subscribers {
    padding-top: 4px;
    padding-bottom: 4px;

    .content {
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;

      .input,
      .preview {
        margin: 0;
        padding: 0;
        width: 100%;
      }
    }
  }
}
