@import '../../../../theme/color.scss';
@import '../../../../theme/commons.module.scss';

.emojis {
  position: absolute;
  width: 320px;
  border: 2px solid transparent;
  box-sizing: border-box;
  overflow: hidden;
  bottom: calc(100% - 12px);
  overflow: hidden;
  background: $app_white_absolute;
  z-index: calc(#{$high_front} + 1);
  border: 1.5px solid $app_white_shade3;
  border-radius: 10px;
  overflow: hidden;

  & > div {
    position: relative;
    width: 100%;
  }

  > div,
  * {
    width: 100%;
  }

  &_dark {
    background: #1a1e24 !important;
    border: none !important;
    -moz-box-shadow: 0px 4px 50px $app_black_shade5;
    -webkit-box-shadow: 0px 4px 50px $app_black_shade5;
    box-shadow: 0px 4px 50px $app_black_shade5;

    section,
    em-emoji-picker {
      background: #1a1e24 !important;
    }

    > div {
      background: #1a1e24 !important;
    }
  }
}

@media screen and (max-width: 900px) {
  .emojis {
    bottom: calc(100% + 4px);
    left: 2px;
    width: 280px;

    em-emoji-picker {
      max-height: 280px;
      max-width: 280px;
    }
  }
}
