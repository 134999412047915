@import '../../theme/commons.module.scss';

.logo_icon {
  position: relative;
  height: 40px;
  width: 40px;

  & > img {
    position: relative;
    height: 100%;
    width: 100%;
    top: 0;
    object-fit: cover;
    object-position: center;
    display: flex;
  }
}
