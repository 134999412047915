@import '../../theme/commons.module.scss';
@import '../../theme/color.scss';

.lifetimecode {
  position: absolute;
  height: 100vh;
  width: 100%;
  box-sizing: border-box;

  &_background {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    box-sizing: border-box;

    & > img {
      position: relative;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .content {
    position: relative;
    width: 400px;
    padding: 30px 24px;
    box-sizing: border-box;
    flex-direction: column;
    box-sizing: border-box;
    background: $app_white_absolute;
    -webkit-box-shadow: 0px 4px 50px rgba(16, 24, 64, 0.04);
    -moz-box-shadow: 0px 4px 50px rgba(16, 24, 64, 0.04);
    box-shadow: 0px 4px 50px rgba(16, 24, 64, 0.04);
    border-radius: 10px;

    .texts {
      position: relative;
      width: 100%;
      margin-top: 24px;

      & > h2 {
        position: relative;
        font-size: 16px;
        font-family: 'WorkSans-SemiBold';
        opacity: 0.9;
        text-align: center;
        width: 100%;
      }
    }

    .success_redeem {
      position: relative;
      width: 100%;
      flex-direction: column;
      margin: 16px 0;

      & > p {
        margin-top: 8px;
      }
    }

    .input,
    .cta {
      position: relative;
      width: 100%;
      box-sizing: border-box;
      margin-top: 16px;
    }

    .cta {
      margin-top: 8px;

      .confirm {
        height: 36px;
        width: 100%;
      }
    }
  }
}

@media screen and (max-width: 460px) {
  .lifetimecode {
    .content {
      width: 96vw;
    }
  }
}
