@import '../../theme/commons.module.scss';
@import '../../theme/color.scss';

.bottom_bar {
  position: absolute;
  left: 0;
  right: inherit;
  height: 0;
  width: 0;
  display: none;
  overflow: hidden;
}

@media screen and (max-width: 900px) {
  .bottom_bar {
    position: fixed;
    display: flex;
    justify-content: space-evenly;
    overflow: unset;
    height: 72px;
    width: 100%;
    top: calc(100% - 72px);
    padding-bottom: 12px;
    background: $app_white_absolute;
    box-sizing: border-box;
    border-top: 1px solid rgba($color: $app_white_shade3, $alpha: 0.6);

    &_dark {
      border-top: 1px solid rgba($color: $app_darkmode_text1, $alpha: 0.1);
      background: #13171c;
    }

    .account {
      position: relative;
      flex-direction: column;
      justify-content: flex-start;
      box-sizing: border-box;

      .avatar {
        position: relative;
        height: 35px;
        width: 35px;
        border-radius: 50%;
        box-sizing: border-box;
      }

      .avatar_premium {
        height: 20px;
        width: 20px;
        bottom: -3px;
        right: -7px;

        svg {
          height: 12px;
          width: 12px;
        }
      }

      .link {
        a {
          position: absolute;
          height: 100%;
          width: 100%;
          left: 0;
          top: 0;
        }
      }

      .avatar_loading {
        position: relative;
        height: 30px;
        width: 30px;
        border-radius: 50%;
        background: $app_black_shade1;

        svg {
          fill: $app_white_absolute;

          path {
            fill: $app_white_absolute;
          }
        }
      }
    }

    .search {
      position: relative;
      flex-direction: column;
      justify-content: flex-start;
      margin-left: 18px;
    }

    .home,
    .spaces,
    .account,
    .search {
      & > p {
        position: relative;
        font-weight: unset;
        font-family: 'WorkSans-Regular';
        font-size: 13px;
        margin-top: 4px;
        margin: 0;
        padding: 0;
      }

      & > button {
        position: relative;
        background: transparent;
        flex-direction: column;
        align-items: center;
        margin: 0;
        padding: 8px 24px;

        svg {
          height: 24px;
          width: 24px;
        }

        &:hover,
        &:active {
          background: transparent;
        }
      }
    }

    .account {
      & > p {
        margin-top: 2px;
      }
    }

    .home,
    .spaces {
      position: relative;
      box-sizing: border-box;
      display: flex;
      align-items: center;
      flex-direction: column;
      align-items: center;
    }

    .home,
    .spaces,
    .search,
    .account {
      &_active {
        button {
          svg {
            fill: #696f8c;
            fill: #2d3748;

            path {
              fill: #696f8c;
              fill: #2d3748;
            }
          }
        }

        &_dark {
          button {
            svg {
              fill: #696f8c;
              path {
                fill: #696f8c;
              }
            }
          }
        }
      }

      &_inactive {
        & > p {
          opacity: 0.7;
        }

        button {
          svg {
            // fill: rgba(105, 111, 140, 0.4);
            fill: #718096;
            path {
              // fill: rgba(105, 111, 140, 0.4);
              fill: #718096;
            }
          }
        }

        &_dark {
          & > p {
            opacity: 0.5;
          }

          button {
            svg {
              fill: rgba(105, 111, 140, 0.3);

              path {
                fill: rgba(105, 111, 140, 0.3);
              }
            }
          }
        }
      }
    }

    .spaces {
      margin-right: 18px;
    }

    .create {
      position: absolute;
      top: -30px;
      left: calc((100% - 54px) / 2);
      height: 54px;
      width: 54px;
      box-sizing: border-box;
      border-radius: 50%;

      & > button {
        position: relative;
        height: 54px;
        width: 54px;
        border-radius: 50%;
        overflow: hidden;
        background: $app_dominant_purple;
        padding: 0;
        box-sizing: border-box;
        box-shadow: 0px 0px 32px rgba(88, 119, 234, 0.3);

        svg {
          fill: $app_white_absolute;
          height: 20px;
          width: 20px;
        }

        &:hover,
        &:active {
          background: $app_dominant_purple;
        }
      }

      .link {
        position: absolute;

        a {
          position: absolute;
          height: 100%;
          width: 100%;
          top: 0;
          left: 0;
          text-decoration: none;
        }
      }
    }
  }
}
