@import '../../../../theme/color.scss';
@import '../../../../theme/commons.module.scss';
@import '../../../../theme/scroll.scss';

.gifs {
  position: absolute;
  width: 320px;
  padding: 4px 4px;
  padding-top: 8px;
  border-radius: 5px;
  box-sizing: border-box;
  bottom: calc(100% - 12px);
  left: 16px;
  background: $app_white_absolute;
  box-shadow: 0px 4px 50px rgba(0, 0, 0, 0.05);
  -webkit-box-shadow: 0px 4px 50px rgba(0, 0, 0, 0.05);
  -moz-box-shadow: 0px 4px 50px rgba(0, 0, 0, 0.5);
  border: 1.5px solid $app_white_shade3;
  z-index: calc(#{$high_front} + 1);

  .search_gif {
    position: relative;
    width: 100%;
    margin: 8px 0;
    padding: 0 8px;
    margin-top: 4px;
    box-sizing: border-box;

    & > input[type='text'] {
      width: 100%;
      height: 36px;
      color: $app_black_shade1;
      font-size: 90% !important;
      box-sizing: border-box;
      font-weight: unset;
      font-family: 'WorkSans-Medium';
      padding-left: 32px;
    }

    & > div:last-child {
      position: absolute;
      height: 100%;
      width: 30px;
      top: 10px;
      left: 16px;
      z-index: $high_front;

      svg {
        height: 14px;
        width: 14px;
        box-sizing: border-box;
        fill: $app_gray_shade6;

        path {
          fill: $app_gray_shade6;
        }
      }
    }

    &_dark {
      input[type='text'] {
        background: transparent !important;
        border: 1px solid #28303b;
        color: $app_darkmode_text1 !important;

        &:focus,
        &:active {
          background: transparent;
        }

        &::placeholder {
          color: $app_darkmode_text1;
          opacity: 1;
        }

        &:-ms-input-placeholder {
          color: $app_darkmode_text1;
        }

        &::-ms-input-placeholder {
          color: $app_darkmode_text1;
        }
      }

      & > div:last-child {
        svg {
          fill: $app_darkmode_text1;

          path {
            fill: $app_darkmode_text1;
          }
        }
      }
    }
  }

  .empty {
    position: relative;
    width: 100%;
    padding: 2px 4px;
    padding-left: 10px;
    box-sizing: border-box;
    margin-top: 16px;

    & > h5 {
      position: relative;
      font-weight: unset;
      font-family: 'WorkSans-Medium';
      font-size: 95%;
      color: $app_black_shade3;
    }

    &_dark {
      color: $app_darkmode_text1;

      & > h5 {
        color: $app_darkmode_text1;
      }
    }
  }

  & > ul {
    position: relative;
    min-height: 90px;
    max-height: 220px;
    display: inline-block;
    width: 100%;
    box-sizing: border-box;
    user-select: none;
    -webkit-user-select: none;
    // overflow: hidden;
    overflow: hidden;
    overflow-y: scroll;
    white-space: nowrap;
    box-sizing: border-box;
    padding-right: 4px;
    padding-left: 8px;

    @include custom_scroll;

    &::-webkit-scrollbar {
      height: 7px;
    }

    &::-moz-scrollbar {
      height: 7px;
    }

    & > li {
      position: relative;
      height: 100%;
      width: 100%;
      box-sizing: border-box;
      display: block;
      cursor: pointer;
      overflow: hidden;
      //   border: 2px solid red;

      & > img {
        position: relative;
        height: 100%;
        width: 100%;
        transition: transform 0.2s ease;
      }

      &:hover {
        & > img {
          transform: scale(1.05);
        }
      }
    }

    & > li:not(:nth-child(1)) {
      margin-top: 6px;
    }
  }

  &_dark {
    background: #151617;
    background: #1a1e24;
    border: none;
    -moz-box-shadow: 0px 4px 50px #181d23;
    -webkit-box-shadow: 0px 4px 50px #181d23;
    box-shadow: 0px 4px 50px #181d23;

    & > ul {
      @include custom_scroll_dark;
    }
  }
}

@media screen and (max-width: 900px) {
  .gifs {
    width: 285px;
    bottom: calc(100% - 16px);
    left: 12px;
  }
}
