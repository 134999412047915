@import '../../theme/commons.module.scss';
@import '../../theme/color.scss';
@import '../../theme/scroll.scss';

@keyframes showFrame {
  0% {
    bottom: -300px;
  }

  100% {
    bottom: 0px;
  }
}

@keyframes hideFrame {
  0% {
    bottom: 0px;
  }

  100% {
    bottom: -1000px;
  }
}

.toast {
  position: fixed;
  width: 100%;
  top: 0;
  background: rgba(0, 0, 0, 0.4);
  box-sizing: border-box;
  z-index: 12;

  .content {
    position: absolute;
    bottom: 0;
    box-shadow: 0px 4px 50px rgba(0, 0, 0, 0.05);
    -webkit-box-shadow: 0px 4px 50px rgba(0, 0, 0, 0.05);
    -moz-box-shadow: 0px 4px 50px rgba(0, 0, 0, 0.05);
    background: #fff;
    height: calc(100% - 80px);
    width: 100%;
    border-radius: 0;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    padding: 8px 0;
    padding-bottom: 4px;
    padding-top: 44px;
    box-sizing: border-box;

    .content_dom {
      background: #fff;
      position: relative;
      height: 100%;
      box-sizing: border-box;
      width: 100%;
      margin-top: 2px;
      overflow-y: scroll;
      padding-bottom: 20px;
      padding-right: 12px;
      padding-left: 14px;
      box-sizing: border-box;

      @include custom_scroll;

      &_dark {
        background: #1a1e24;
        border: 1px solid transparent;

        @include custom_scroll_dark;
      }
    }

    &_dark {
      background: #1a1e24;
    }

    .header {
      position: absolute;
      height: 40px;
      top: 4px;
      left: 0;
      right: 0;
      width: 100%;
      padding: 0 8px;
      box-sizing: border-box;
      justify-content: flex-end;

      .close {
        margin-right: -4px;

        &_dark {
          background: transparent !important;

          &:hover,
          &:active {
            background: rgba(
              $color: $app_darkmode_text1,
              $alpha: 0.05
            ) !important;
          }

          svg {
            fill: $app_darkmode_text1;

            path {
              fill: $app_darkmode_text1;
            }
          }
        }
      }
    }
  }

  .show {
    bottom: 0;
    animation: ease;
    animation-name: showFrame;
    animation-duration: 0.2s;
  }

  .hide {
    bottom: -1000px;
    animation: ease;
    animation-name: hideFrame;
    animation-duration: 0.2s;
  }
}
