@import '../../../theme/commons.module.scss';
@import '../../../theme/color.scss';
@import '../../../theme/scroll.scss';

@keyframes run {
  0% {
    right: -150%;
  }
  100% {
    right: 0;
  }
}

@keyframes runMobile {
  0% {
    bottom: -90vh;
  }
  100% {
    bottom: 0;
  }
}

.properties {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  box-sizing: border-box;

  .content {
    position: absolute;
    width: 350px;
    height: 100%;
    padding: 0 16px;
    box-sizing: border-box;
    top: 0;
    right: 0;
    align-items: flex-start;
    justify-content: flex-start;
    box-shadow: 0px 4px 20px rgba(70, 81, 137, 0.16);
    background: $app_white_absolute;
    animation: ease;
    animation-name: run;
    animation-duration: 0.25s;
    box-sizing: border-box;
    overflow: unset;

    .title {
      position: relative;
      width: 100%;
      margin-top: 62px;
      margin-bottom: 14px;

      & > h1 {
        position: relative;
        font-weight: unset;
        font-family: 'WorkSans-SemiBold';
      }

      &_dark {
        & > h1 {
          color: #fff;
        }
      }
    }

    .loading {
      position: relative;
      width: 100%;
      padding-left: 8px;
      margin-top: 16px;
      margin-bottom: 8px;
      justify-content: flex-start;
      box-sizing: border-box;

      &_dark {
        svg {
          circle {
            stroke: $app_white_light;
          }
        }
      }
    }

    .property_item {
      position: relative;
      width: 100%;
      justify-content: space-between;
      margin-top: 2px;
      padding: 12px 0;
      box-sizing: border-box;
      display: flex;
      justify-content: flex-start;

      &_border {
        border-bottom: 1px solid #f4f7fb;

        &_dark {
          border-bottom: 2px solid #28303b;
        }
      }

      .icon_label {
        position: relative;
        width: 116px;
        justify-content: flex-start;

        svg {
          fill: #2d3748;

          path {
            fill: #2d3748;
          }
        }

        & > p {
          position: relative;
          color: rgba(45, 55, 72, 0.8);
          font-weight: unset;
          font-size: 94%;
          margin-left: 8px;
        }

        &_dark {
          svg {
            fill: $app_darkmode_text1;
            path {
              fill: $app_darkmode_text1;
            }
          }

          & > p {
            color: #fff;
          }
        }
      }

      &_content {
        position: relative;
        width: calc(100% - 116px);
        justify-content: flex-end;
        box-sizing: border-box;

        & > button {
          position: relative;
          padding: 4px 8px;
          box-sizing: border-box;

          & > p {
            position: relative;
            color: #4769e4;
            font-weight: unset;
            font-size: 95%;
            font-family: 'WorkSans-Regular';
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }

        &_dark {
          & > button {
            background: transparent !important;

            & > p {
              font-family: 'WorkSans-Medium';
            }

            &:hover {
              background: rgba(
                $color: $app_darkmode_text1,
                $alpha: 0.05
              ) !important;
            }
          }
        }
      }
    }

    .space {
      margin-top: 20px;

      .icon_label {
        svg {
          opacity: 0.8;
        }
      }

      .spaces_list {
        position: absolute;
        top: calc(100% - 4px);
        right: 4px;
        background: #fff;
        border: 1px solid rgba(113, 128, 150, 0.05);
        -webkit-box-shadow: 0px 4px 8px rgba(16, 24, 64, 0.1);
        box-shadow: 0px 4px 8px rgba(16, 24, 64, 0.1);
        padding: 14px 8px;
        padding-left: 16px;
        border-radius: 8px;
        box-sizing: border-box;
        z-index: calc(#{$high_front} + 10);
        min-width: 130px;
        max-width: 280px;
        max-height: 320px;
        overflow-y: scroll;

        @include custom_scroll;

        & > ul {
          position: relative;
          width: 100%;
          list-style: none;
          list-style-type: none;
          align-items: start;

          .default_space {
            position: relative;
            height: 30px;
            width: 30px;
            background: $app_black_shade1;

            & > p {
              position: relative;
              font-weight: unset;
              font-size: 90%;
              color: #fff;
              font-family: 'WorkSans-Regular';
            }
          }

          & > li {
            position: relative;
            cursor: pointer;
            width: 100%;

            & > div:nth-child(1) {
              position: relative;
              height: 30px;
              width: 30px;
              border-radius: 4px;
              overflow: hidden;

              & > img {
                position: relative;
                height: 30px;
                width: 30px;
                object-fit: cover;
                object-position: center;
              }
            }

            & > p {
              width: calc(100% - 40px);
              margin-left: 10px;
            }
          }

          & > li:not(:nth-child(1)) {
            margin-top: 8px;
          }
        }

        &_dark {
          background: #1a1e24;
          border: 1px solid transparent;
          -webkit-box-shadow: none;
          box-shadow: none;

          @include custom_scroll_dark;
        }
      }
    }

    .privacy {
      .privacy_list {
        position: absolute;
        top: calc(100% - 4px);
        right: 4px;
        background: #fff;
        border: 1px solid rgba(113, 128, 150, 0.05);
        -webkit-box-shadow: 0px 4px 8px rgba(16, 24, 64, 0.1);
        box-shadow: 0px 4px 8px rgba(16, 24, 64, 0.1);
        z-index: calc(#{$high_front} + 10);
        border: 2px solid #f4f7fb;
        padding: 14px 8px;
        padding-left: 16px;
        border-radius: 8px;
        box-sizing: border-box;
        min-width: 120px;
        max-width: 260px;
        max-height: 320px;
        overflow-y: scroll;

        @include custom_scroll;

        & > ul {
          position: relative;
          width: 100%;
          list-style: none;
          list-style-type: none;
          align-items: start;

          & > li {
            position: relative;
            width: 100%;
            cursor: pointer;

            & > h5 {
              position: relative;
              font-weight: unset;
              font-size: 100%;
              font-family: 'WorkSans-Medium';
            }

            & > p {
              position: relative;
              font-weight: unset;
              font-family: 'WorkSans-Regular';
              font-size: 90%;
              opacity: 0.8;
            }

            &:hover {
              & > h5 {
                color: $app_dominant_purple;
              }
            }
          }

          & > li:not(:nth-child(1)) {
            margin-top: 16px;
          }
        }

        &_dark {
          background: #1a1e24;
          border: 1px solid transparent;
          -webkit-box-shadow: none;
          box-shadow: none;

          & > ul {
            & > li {
              & > h5 {
                color: #fff;
              }
            }
          }
        }
      }
    }

    .space,
    .reminder {
      border-bottom: 1px solid #f4f7fb;

      &_dark {
        border-bottom: 2px solid #28303b;
      }
    }

    .passcode {
      position: relative;
      width: 100%;
      border-bottom: 1px solid #f4f7fb;
      box-sizing: border-box;
      justify-content: flex-start;

      &_tip {
        position: relative;
        width: 100%;

        & > p {
          font-size: 80%;
          opacity: 0.7;
        }
      }

      &_dark {
        border-bottom: 2px solid #28303b;
      }

      &_input {
        position: relative;
        width: 100%;
        background: transparent;
        box-shadow: none;
        margin-top: 8px;
        margin-bottom: 16px;
        box-sizing: border-box;

        & > input[type='text'],
        & > input[type='password'] {
          position: relative;
          height: 40px;
          width: 100%;
          padding: 4px 8px;
          box-sizing: border-box;
          background: transparent;
          border: 1px solid #e2e8f0;
          font-weight: unset;
          font-family: 'WorkSans-Regular';
          padding-right: 44px;

          &:active {
            background: transparent;
          }
        }

        & > button {
          position: absolute;
          right: 8px;
          top: 5px;
          height: 30px;
          width: 30px;
          background: transparent;
          padding: 0;
          box-sizing: border-box;
          z-index: calc(#{$high_front} + 9);
        }

        &_dark {
          & > input[type='text'],
          & > input[type='password'] {
            background: transparent;
            border: 1px solid #28303b;
            color: $app_darkmode_text1 !important;
          }

          & > button {
            svg {
              fill: $app_darkmode_text1;
              path {
                fill: $app_darkmode_text1;
              }
            }
          }
        }
      }
    }

    .advanced_options {
      position: relative;
      width: 100%;
      align-items: flex-start;
      justify-content: flex-start;
      margin-top: 30px;

      &_title {
        position: relative;
        width: 100%;

        & > h2 {
          position: relative;
          font-size: 95%;
          font-weight: unset;
          font-family: 'WorkSans-SemiBold';
        }

        &_dark {
          & > h2 {
            color: #fff;
          }
        }
      }

      .advanced_option_item {
        position: relative;
        justify-content: flex-start;
        margin-top: 8px;

        & > p {
          position: relative;
          color: rgba(45, 55, 72, 0.8);
          font-weight: unset;
          font-size: 95%;
          margin-left: 8px;
        }

        &_dark {
          & > p {
            color: $app_darkmode_text1;
          }
        }
      }

      .allow_comments {
        margin-top: 16px;
      }
    }

    .delete {
      position: relative;
      width: 100%;
      justify-content: flex-start;
      margin-top: 20px;

      & > button {
        position: relative;
        padding: 4px 8px;
        padding-left: 3px;

        svg {
          fill: #e53e3e;
          path {
            fill: #e53e3e;
          }
        }

        & > p {
          position: relative;
          margin-left: 8px;
          font-size: 110%;
          color: #e53e3e;
        }
      }

      &_dark {
        & > button {
          background: transparent !important;

          &:hover {
            background: rgba(
              $color: $app_darkmode_text1,
              $alpha: 0.05
            ) !important;
          }
        }
      }
    }

    .close {
      position: absolute;
      top: 16px;
      right: 16px;

      & > button {
        background: #fbfcfe;
        height: 30px;
        width: 30px;
        padding: 0;
        box-sizing: border-box;

        svg {
          fill: #718096;

          path {
            fill: #718096;
          }
        }
      }

      &_dark {
        & > button {
          background: transparent !important;

          &:hover,
          &:active {
            background: rgba(
              $color: $app_darkmode_text1,
              $alpha: 0.05
            ) !important;
          }

          svg {
            fill: $app_darkmode_text1;

            path {
              fill: $app_darkmode_text1;
            }
          }
        }
      }
    }

    &_dark {
      border: none;
      border: 0.5px solid rgba($color: $app_darkmode_text1, $alpha: 0.1);
      -moz-box-shadow: none;
      -webkit-box-shadow: none;
      box-shadow: none;
      background: $app_darkmode_background_header1;
    }
  }
}

@media screen and (max-width: 900px) {
  .properties {
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;

    .content {
      position: absolute;
      width: 100%;
      height: 90%;
      padding: 4px 16px;
      padding-bottom: 40px;
      box-sizing: border-box;
      top: unset;
      bottom: 0;
      align-items: flex-start;
      justify-content: flex-start;
      animation: ease;
      animation-name: runMobile;
      animation-duration: 0.25s;
      overflow: hidden;
      overflow-y: scroll;

      .privacy {
        .privacy_list {
          & > ul {
            & > li {
              & > h5 {
                font-size: 110%;
              }

              & > p {
                font-size: 95%;
              }
            }
          }
        }
      }
    }
  }
}
