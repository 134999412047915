@import '../../theme/color.scss';
@import '../../theme/commons.module.scss';

.left_panel {
  position: relative;
  width: 100%;
  padding: 4px 0;
  padding-right: 24px;
  box-sizing: border-box;

  .item_info {
    position: relative;
    justify-content: space-between;
    box-sizing: border-box;
    width: 100%;
    margin-top: 8px;
    cursor: pointer;

    &_content {
      & > p {
        position: relative;
        font-weight: unset;
        font-family: 'WorkSans-SemiBold';
      }

      &_dark {
        & > p {
          color: #fff;
        }
      }
    }
  }
}

@media screen and (max-width: 900px) {
  .left_panel {
    display: none;
  }
}
