@import '../../theme/color.scss';
@import '../../theme/commons.module.scss';
@import '../../theme/scroll.scss';

@keyframes runMobile {
  0% {
    bottom: -90vh;
  }
  100% {
    bottom: 0;
  }
}

.comments {
  position: relative;
  width: 100%;
  display: inline-block;
  margin-top: 16px;
  box-sizing: border-box;

  .content {
    position: relative;
    width: 100%;
    justify-content: flex-start;
    align-items: flex-start;
    box-sizing: border-box;

    .title {
      position: relative;
      width: 100%;
      justify-content: flex-start;
      box-sizing: border-box;

      & > svg {
        display: none;
      }

      & > h1 {
        position: relative;
        margin-left: 0;
        font-weight: unset;
        font-family: 'WorkSans-SemiBold';
        font-size: 120%;
      }

      &_dark {
        svg {
          fill: #fff;
          path {
            fill: #fff;
          }
        }

        & > h1 {
          color: #fff;
        }
      }
    }

    .loading {
      position: relative;
      width: 100%;
      margin-top: 16px;
      margin-bottom: 8px;
      box-sizing: border-box;

      &_dark {
        svg {
          circle {
            stroke: $app_white_light;
          }
        }
      }
    }

    .show_more {
      position: relative;
      width: 100%;
      height: 40px;
      margin-top: 4px;
      box-sizing: border-box;

      & > button {
        position: relative;
        padding: 4px 16px;
        box-sizing: border-box;
        height: 40px;
      }
    }

    .comment_input {
      position: relative;
      width: 100%;
      display: inline-block;
      box-sizing: border-box;
      margin-top: 16px;
      margin-bottom: 8px;

      .author {
        position: relative;
        height: 40px;
        width: 100%;
        justify-content: flex-start;
        margin-bottom: 16px;

        span {
          color: #fff;

          & > div {
            font-size: 90%;
            color: #fff;
          }
        }

        & > p:nth-child(2) {
          margin-left: 8px;
        }
      }
    }

    .empty_comments {
      position: relative;
      width: 100%;
      margin-top: 30px;
      margin-bottom: 16px;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;

      &_icon {
        position: relative;
        width: 100%;
        margin: 8px 0;
        overflow: hidden;
      }

      & > h5 {
        position: relative;
        font-weight: unset;
        box-sizing: border-box;
        font-size: 105%;
        font-family: 'WorkSans-Medium';
        max-width: 310px;
      }

      & > p {
        margin-top: 12px;
        position: relative;
        font-size: 90%;
        max-width: 300px;
        text-align: center;
        font-family: 'WorkSans-Regular';
        opacity: 0.8;
      }

      &_dark {
        & > h5 {
          color: #fff;
        }

        & > p {
          opacity: 1;
        }
      }
    }

    .list {
      position: relative;
      width: 100%;
      margin-top: 16px;

      & > div {
        position: relative;
        width: 100%;
        list-style: none;
        list-style-type: none;

        & > div,
        & > li {
          position: relative;
          width: 100%;
          box-sizing: border-box;
        }

        & > div:not(:nth-child(1)),
        & > li:not(:nth-child(1)) {
          margin-top: 8px;
        }
      }
    }

    .close {
      display: none;
    }
  }
}

@media screen and (max-width: 900px) {
  // modal mode
  .comments {
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    bottom: unset;
    box-sizing: border-box;

    .content {
      position: absolute;
      height: 85%;
      width: 100%;
      left: 0;
      bottom: 0;
      margin: 0;
      box-sizing: border-box;
      background: #fff;
      animation: ease;
      animation-name: runMobile;
      animation-duration: 0.25s;
      padding: 24px 16px;
      padding-right: 12px;
      padding-bottom: 8px;

      &_dark {
        border: none;
        border: 0.5px solid rgba($color: $app_darkmode_text1, $alpha: 0.1);
        -moz-box-shadow: none;
        -webkit-box-shadow: none;
        box-shadow: none;
        background: $app_darkmode_background_header1;
      }

      .title {
        & > svg {
          display: unset;
        }

        & > h1 {
          margin-left: 4px;
          font-family: 'WorkSans-Medium';
        }
      }

      .comment_input {
        position: relative;
        width: 100%;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        margin-top: 10px;
        margin-bottom: 0;

        .author {
          margin-top: 8px;
        }
      }

      .list {
        position: relative;
        margin-top: 8px;
        height: calc(100% - 305px);
        padding: 8px 0;
        box-sizing: border-box;
        overflow: hidden;
        overflow-y: scroll !important;

        & > div {
          min-height: 100%;
          box-sizing: border-box;
          width: 100%;
        }

        @include custom_scroll;

        &_dark {
          @include custom_scroll_dark;
        }
      }

      .list_no_comment {
        height: calc(100% - 90px);
      }

      .list_no_comment_no_more {
        height: calc(100% - 55px);
      }

      .list_with_comment_no_more {
        height: calc(100% - 264px);
      }

      .close {
        position: absolute;
        top: 16px;
        right: 8px;
        display: flex;

        & > button {
          height: 30px;
          width: 30px;
          padding: 0;
          box-sizing: border-box;
          background: #fbfcfe;

          svg {
            fill: #718096;

            path {
              fill: #718096;
            }
          }
        }

        &_dark {
          & > button {
            background: transparent !important;

            &:hover,
            &:active {
              background: rgba(
                $color: $app_darkmode_text1,
                $alpha: 0.05
              ) !important;
            }

            svg {
              fill: $app_darkmode_text1;

              path {
                fill: $app_darkmode_text1;
              }
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 800px) {
  .comments {
    .content {
      .empty_comments {
        margin-top: 12px;

        &_icon {
          height: 130px;
          width: 230px;
          box-sizing: border-box;

          svg {
            height: 120px !important;
            width: 220px !important;
          }
        }
      }
    }
  }
}
