@import '../theme/color.scss';

.menu_list_dark {
  border: 1px solid rgba($color: $app_darkmode_text1, $alpha: 0.1);
  -moz-box-shadow: 0px 4px 50px $app_black_shade5;
  -webkit-box-shadow: 0px 4px 50px $app_black_shade5;
  box-shadow: 0px 4px 50px $app_black_shade5;
  background: $app_darkmode_background_header1 !important;
}

.menu_item {
  background: transparent !important;
  justify-content: flex-start !important;
  min-width: 100px;

  a {
    min-width: 100px;
    position: relative;
  }
}

.menu_item_dark {
  &:hover,
  &:active,
  &:focus {
    background: rgba($color: $app_darkmode_text1, $alpha: 0.05) !important;
  }
}

@media screen and (max-width: 900px) {
  .menu_item {
    height: 44px !important;
    padding-top: 16px;
    padding-bottom: 16px;
    box-sizing: border-box;
    min-width: 160px !important;
  }
}
