@import '../../theme/commons.module.scss';
@import '../../theme/color.scss';

.network_offline {
  position: fixed;
  top: 40px;
  left: 0;
  width: 100%;
  z-index: 20;

  .content {
    position: relative;
    background: #fff5f5;
    border-radius: 4px;
    padding: 8px 12px;
    box-sizing: border-box;
    width: 260px;

    svg {
      fill: #df3e4a;
    }

    & > p {
      position: relative;
      font-size: 14px;
      box-sizing: border-box;
      font-family: 'WorkSans-Medium';
      white-space: nowrap;
      max-width: calc(100% - 50px);
      margin-left: 8px;
      color: $app_black_shade1;
    }
  }
}
