@import '../../theme/color.scss';
@import '../../theme/commons.module.scss';

.header_user_options {
  position: absolute;
  top: calc(100% + 20px);
  right: -4px;
  left: unset;
  display: inline-block;
  background: $app_white_absolute;
  border: 1px solid #f4f7fb;
  -webkit-box-shadow: 0px 4px 8px rgba(16, 24, 64, 0.1);
  box-shadow: 0px 4px 8px rgba(16, 24, 64, 0.1);
  padding: 8px 0px;
  border-radius: 8px;
  z-index: calc(#{$high_front} + 9);

  .content {
    position: relative;
    align-items: flex-start;

    .item {
      position: relative;
      width: 100%;
      margin-top: 2px;

      .link {
        position: absolute;

        a {
          height: 100%;
          width: 100%;
          position: absolute;
          top: 0;
          left: 0;
        }
      }

      & > button {
        position: relative;
        padding: 8px 16px;
        font-size: 100%;
        width: 100%;
        box-sizing: border-box;
        justify-content: flex-start;
        background: transparent !important;

        &:hover,
        &:active {
          background: transparent;
        }

        svg {
          height: 18px;
          width: 18px;
        }

        p {
          position: relative;
          font-size: 100%;
          font-weight: unset;
          font-family: 'WorkSans-Regular';
          color: #2d3748;
          margin-left: 8px;
          box-sizing: border-box;
        }
      }

      .link {
        a {
          position: absolute;
          height: 100%;
          width: 100%;
          top: 0;
          left: 0;
          text-decoration: none;
        }
      }

      &_dark {
        & > button {
          svg {
            fill: rgba(195, 208, 229, 0.8);

            path {
              fill: rgba(195, 208, 229, 0.8);
            }
          }

          & > p {
            color: $app_darkmode_text1;
          }
        }
      }
    }

    .dashboard {
      & > button {
        & > svg {
          path {
            fill: #718096;
          }
        }
      }
    }
  }

  &_dark {
    // background: #222a34;
    // border: 1px solid #222a34;
    background: #1a1e24;
    border: 1px solid transparent;
  }
}
