@import '../../theme/color.scss';
@import '../../theme/commons.module.scss';

.header_mobile {
  position: relative;
  width: 100%;
  display: none;

  &_ios_app {
    top: 35px;
  }
}

@media screen and (max-width: 900px) {
  .header_mobile {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    top: 0;
    left: 0;
    height: 60px;
    width: 100%;
    box-sizing: border-box;

    &_ios_app {
      top: 35px;
    }

    .left {
      position: relative;
      width: 60px;
      box-sizing: border-box;

      .logo_link {
        position: absolute;
        box-sizing: border-box;

        a {
          position: absolute;
          height: 100%;
          width: 100%;
          top: 0;
          left: 0;
          text-decoration: none;
        }
      }
    }

    .space_info {
      position: relative;
      width: calc(100% - 110px);
      box-sizing: border-box;
      user-select: none;
      -webkit-user-select: none;
      -moz-user-select: none;

      & > p {
        width: 100%;
        text-align: center;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        position: relative;
        font-size: 105%;
        font-weight: unset;
        font-family: 'WorkSans-Regular';
      }
    }

    .right {
      width: 45px;
      margin-right: 4px;
      box-sizing: border-box;

      & > button {
        position: relative;
        box-sizing: border-box;
        height: 40px;
        width: 40px;
        padding: 0;
        display: flex;
        justify-content: center;

        .link {
          a {
            position: absolute;
            height: 100%;
            width: 100%;
            left: 0;
            top: 0;
            text-decoration: none;
          }
        }
      }

      .notif {
        &:hover,
        &:active {
          background: transparent !important;
        }

        &_dark {
          &:active {
            background: rgba($color: $app_darkmode_text1, $alpha: 0.05);
          }
        }
      }
    }
  }
}
