@import '../../theme/color.scss';
@import '../../theme/commons.module.scss';
@import '../../theme/scroll.scss';

.add_subscribers {
  position: relative;
  width: 100%;
  box-sizing: border-box;
  padding: 4px 0;

  .input {
    position: relative;
    width: 100%;

    & > input[type='text'] {
      position: relative;
      height: 36px;
      width: 100%;
      box-sizing: border-box;
      background: transparent;
      border: 1px solid #e2e8f0;
      font-weight: unset;
      font-family: 'WorkSans-Regular';
      font-size: 102%;
    }

    &_dark {
      & > input[type='text'] {
        background: transparent;
        border: 1px solid #28303b;
        color: $app_darkmode_text1 !important;

        &:hover,
        &:active {
          background: transparent;
        }
      }
    }

    &_filled {
      & > input[type='text'] {
        padding-right: 36px;
        box-sizing: border-box;
      }
    }
  }

  .clear {
    position: absolute;
    height: 30px;
    width: 30px;
    top: 4px;
    right: 3px;
    padding: 0;
    box-sizing: border-box;
    z-index: calc(#{$high_front} + 10);

    svg {
      height: 14px;
      width: 14px;

      path {
        fill: #718096;
      }
    }

    &_dark {
      background: rgba($color: #222a34, $alpha: 0.25);

      &:hover,
      &:active,
      &:focus {
        background: rgba($color: #222a34, $alpha: 0.4) !important;
      }

      svg {
        height: 14px;
        width: 14px;

        path {
          fill: $app_darkmode_text1;
        }
      }
    }
  }

  .results {
    position: absolute;
    top: calc(100% - 2px);
    left: 2px;
    width: calc(100% - 4px);
    border-radius: 4px;
    background: #fff;
    border: 1px solid rgba(113, 128, 150, 0.05);
    box-shadow: 0px 4px 50px rgba(16, 24, 64, 0.04);
    -webkit-box-shadow: 0px 4px 50px rgba(16, 24, 64, 0.04);
    -moz-box-shadow: 0px 4px 50px rgba(16, 24, 64, 0.04);
    display: inline-block;
    padding: 8px 8px;
    padding-left: 16px;
    box-sizing: border-box;
    overflow: hidden;
    overflow-y: scroll;
    min-height: 40px;
    max-height: 230px;
    z-index: calc(#{$high_front} + 10);
    @include custom_scroll;

    .loading {
      position: relative;
      padding: 4px 8px;
      box-sizing: border-box;
      width: 100%;

      &_dark {
        svg {
          circle {
            stroke: $app_white_light;
          }
        }
      }
    }

    .results_content_empty {
      position: relative;
      width: 100%;
      margin: 8px 0;
      box-sizing: border-box;
      align-items: flex-start;

      & > h3 {
        position: relative;
        width: 100%;
        font-size: 105%;
      }

      & > p {
        position: relative;
        margin-top: 4px;
        opacity: 0.8;
        font-size: 95%;
        text-align: left;
      }

      &_dark {
        & > h3,
        & > p {
          color: #fff;
        }
      }
    }

    .results_content {
      position: relative;
      width: 100%;
      justify-content: flex-start;

      & > ul {
        position: relative;
        width: 100%;
        list-style: none;
        list-style-type: none;
        box-sizing: border-box;

        & > li {
          position: relative;
          width: 100%;
          display: flex;
          justify-content: flex-start;
          align-items: center;
          box-sizing: border-box;
          cursor: pointer;

          & > div:nth-child(1) {
            justify-content: flex-start;
            align-items: center;

            & > p {
              position: relative;
              margin-left: 8px;
              max-width: calc(100% - 50px);
              overflow: hidden;
              text-overflow: ellipsis;
              font-size: 95%;
              white-space: nowrap;
            }
          }
        }

        .avatar {
          color: #fff;

          div {
            font-size: 85%;
            color: #fff;
          }
        }

        & > li:not(:nth-child(1)) {
          margin-top: 8px;
        }
      }
    }

    .result_content_expand {
      position: relative;
      width: 100%;
      box-sizing: border-box;
    }

    .result_content_min {
      position: relative;
      width: calc(100% - 40px);
      margin-right: 4px;
      box-sizing: border-box;
    }

    .preview_remove {
      position: relative;
      height: 30px;
      width: 30px;

      & > button {
        position: relative;
        background: #fbfcfe;
        height: 30px;
        width: 30px;
        padding: 0;
        box-sizing: border-box;

        svg {
          fill: #718096;

          path {
            fill: #718096;
          }
        }

        padding: 0;
        box-sizing: border-box;
      }

      &_dark {
        & > button {
          background: transparent !important;

          &:hover,
          &:active {
            background: rgba(
              $color: $app_darkmode_text1,
              $alpha: 0.05
            ) !important;
          }

          svg {
            fill: $app_darkmode_text1;

            path {
              fill: $app_darkmode_text1;
            }
          }
        }
      }
    }

    &_dark {
      background: #1a1e24;
      border: 1px solid transparent;
      box-shadow: none;
      -webkit-box-shadow: none;
      -moz-box-shadow: none;
    }
  }
}
