@import '../../../../theme/color.scss';
@import '../../../../theme/commons.module.scss';

.gif_preview {
  position: relative;
  width: 280px;
  padding: 4px 10px;
  padding-left: 14px;
  box-sizing: border-box;
  text-align: left;
  //   border: 2px solid red;

  & > img {
    position: relative;
    height: auto;
    width: 100%;
    object-fit: contain;
  }

  .error {
    position: relative;
    width: 100%;
    box-sizing: border-box;
    padding: 0 8px;
    flex-direction: column;
    margin-top: 4px;

    & > div:nth-child(1) {
      position: relative;

      svg {
        height: 20px;
        width: 20px;
        fill: $app_black_shade2;
      }
    }

    & > h5 {
      margin-top: 4px;
      position: relative;
      font-family: 'WorkSans-Bold';
    }

    &_dark {
      & > div:nth-child(1) {
        svg {
          fill: $app_darkmode_text1;
          color: $app_darkmode_text1;
        }
      }
    }
  }

  .remove_gif {
    position: absolute;
    top: 8px;
    right: 14px;
    padding: 0;
    background: rgba($color: #fbfcfe, $alpha: 1);

    &:hover {
      background: rgba($color: #fbfcfe, $alpha: 0.7);
    }

    svg {
      height: 18px;
      width: 18px;
      // fill: $app_black_shade1;
      // color: $app_black_shade1;
    }

    svg {
      fill: #718096;

      path {
        fill: #718096;
      }
    }
  }
}

@media screen and (max-width: 900px) {
  .gif_preview {
    width: 280px;
    height: 110px;
    margin-top: 0;
    overflow: hidden;
    display: flex;
    justify-content: center;

    & > img {
      width: 280px;
      height: 110px;
      margin: auto;
      object-position: center;
      object-fit: contain;
    }

    .remove_gif {
      top: 0px;
    }
  }
}
