@import '../../../theme/commons.module.scss';
@import '../../../theme/color.scss';
@import '../../../theme/scroll.scss';

.space_members {
  position: relative;
  width: 100%;
  top: 0;
  left: 0;
  box-sizing: border-box;

  &_raw {
    position: relative;
    width: 100%;
    background: transparent;

    .title {
      position: relative;
      width: 100%;
      display: inline-block;

      & > h1 {
        position: relative;
        display: inline-block;
        vertical-align: middle;
        font-weight: unset;
        font-family: 'WorkSans-Medium';
        font-size: 110%;

        & > span {
          position: relative;
          margin-left: 8px;
          font-weight: unset;
          font-family: 'WorkSans-Medium';
          font-size: 90%;
          display: inline-block;
          vertical-align: middle;
          margin-bottom: 2px;
        }
      }

      &_dark {
        & > h1 {
          color: $app_darkmode_text1;

          & > span {
            color: $app_darkmode_text1;
          }
        }
      }
    }

    .no_members {
      position: relative;
      width: 100%;
      display: inline-block;
      text-align: center;
      margin-top: 16px;
      margin-bottom: 16px;

      & > h5 {
        position: relative;
        font-weight: unset;
        font-family: 'WorkSans-Regular';
        font-size: 100%;
        text-align: center;
      }

      &_dark {
        & > h5 {
          color: $app_darkmode_text1;
        }
      }
    }

    .add_member {
      margin-top: 30px;
      margin-bottom: 8px;
      position: relative;
      width: 100%;

      &_input {
        height: 40px;
        width: 100%;
        font-size: 85% !important;
        font-family: 'WorkSans-Regular' !important;

        &_dark {
          background: transparent !important;
          border: 1px solid #28303b;
          color: $app_darkmode_text1 !important;

          &:focus,
          &:active {
            background: transparent;
          }
        }
      }

      .clear_input {
        position: absolute;
        right: 2px;
        top: 4px;
        display: inline-block;
        text-align: center;

        svg {
          height: 12px;
          width: 12px;
        }

        &_dark {
          & > button {
            background: transparent;

            &:hover,
            &:active {
              background: rgba($color: $app_darkmode_text1, $alpha: 0.05);
            }
          }

          svg {
            fill: $app_darkmode_text1;

            path {
              fill: $app_darkmode_text1;
            }
          }
        }
      }
    }

    .search_results {
      position: absolute;
      top: 90%;
      left: 0;
      width: 100%;
      background: $app_white_absolute;
      border: 1.5px solid $app_white_shade3;
      box-shadow: 0px 4px 50px rgba(0, 0, 0, 0.05);
      -webkit-box-shadow: 0px 4px 50px rgba(0, 0, 0, 0.05);
      -moz-box-shadow: 0px 4px 50px rgba(0, 0, 0, 0.05);
      border-radius: 4px;
      padding: 4px 8px;
      padding-right: 4px;
      box-sizing: border-box;
      z-index: $normal_front;
      overflow: hidden;
      overflow-y: scroll;
      max-height: 190px;

      @include custom_scroll;

      &_empty {
        position: relative;
        width: 100%;
        display: inline-block;
        text-align: left;

        & > p {
          font-size: 90%;
          font-weight: unset;
          font-family: 'WorkSans-Regular';
        }
      }

      &_loading {
        position: relative;
        text-align: center;
        width: 100%;
        margin: 8px 0;
        box-sizing: border-box;
      }

      & > ul {
        position: relative;
        width: 100%;
        display: inline-block;
        text-align: left;
        list-style-type: none;
        list-style: none;
        // border: 1px solid red;

        & > li {
          position: relative;
          width: 100%;
          cursor: pointer;
          padding: 4px 4px;
          border-radius: 4px;
          justify-content: flex-start;
          box-sizing: border-box;

          &:hover {
            background: $app_white_shade4;
          }
        }

        & > li:not(:nth-child(1)) {
          margin-top: 8px;
        }
      }

      &_dark {
        border: 1px solid rgba($color: $app_darkmode_text1, $alpha: 0.1);
        -moz-box-shadow: 0px 4px 50px $app_black_shade5;
        -webkit-box-shadow: 0px 4px 50px $app_black_shade5;
        box-shadow: 0px 4px 50px $app_black_shade5;
        background: $app_darkmode_background_header1;

        @include custom_scroll_dark;

        .search_results_empty {
          & > p {
            color: $app_darkmode_text1;
          }
        }

        .search_results_loading {
          svg {
            circle {
              stroke: $app_white_light !important;
            }
          }
        }

        & > ul {
          & > li {
            &:hover {
              background: rgba($color: $app_darkmode_text1, $alpha: 0.05);
            }
          }
        }
      }
    }

    .avatar,
    .name {
      position: relative;
      box-sizing: border-box;
    }

    .avatar {
      span {
        & > div {
          color: #fff;
        }
      }
    }

    .name {
      margin-left: 8px;
      max-width: calc(100% - 80px);
      justify-content: flex-start;

      & > h5 {
        font-size: 85%;
        font-weight: unset;
        font-family: 'WorkSans-Medium';
        position: relative;
        display: inline-block;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
      }
    }

    .add_confirmation {
      position: relative;
      width: 100%;
      display: inline-block;
      text-align: left;
      margin: 16px 0;
      box-sizing: border-box;

      & > p:nth-child(1) {
        position: relative;
        width: 100%;
        text-align: left;
        display: inline-block;
        font-size: 85%;
        font-weight: unset;
        font-family: 'WorkSans-Medium';
        margin: 4px 0;
        padding: 0;
        box-sizing: border-box;
        margin-bottom: 8px;
      }

      & > div {
        position: relative;
        width: 100%;
        box-sizing: border-box;
      }

      & > div:nth-child(2) {
        margin-right: 8px;
        box-sizing: border-box;
        justify-content: space-between;

        & > div {
          position: relative;
          box-sizing: border-box;
        }

        & > div:nth-child(1) {
          position: relative;
          width: calc(100% - 110px);
          justify-content: flex-start;
          box-sizing: border-box;
        }
      }

      .actions {
        position: relative;
        width: 110px;
        display: inline-block;
        text-align: end;
        box-sizing: border-box;
        // border: 1px solid red;

        .confirm {
          position: relative;
          display: inline-block;
          vertical-align: middle;
          margin-left: 4px;
          padding: 0 8px;

          & > p {
            font-weight: unset;
            font-size: 105%;
            font-family: 'WorkSans-Regular';
          }
        }
      }

      &_dark {
        & > p:nth-child(1) {
          color: $app_darkmode_text1;
        }

        .actions {
          .confirm {
            background: transparent;

            &:hover,
            &:active {
              background: rgba($color: $app_darkmode_text1, $alpha: 0.05);
            }

            & > p {
              color: $app_darkmode_text1;
            }
          }
        }
      }
    }

    .list {
      position: relative;
      width: calc(100% + 6px);
      margin-top: 10px;
      display: inline-block;
      min-height: 200px;
      max-height: 300px;
      box-sizing: border-box;
      overflow: hidden;
      overflow-y: scroll;
      padding: 8px 0;
      padding-right: 4px;
      padding-bottom: 34px;
      box-sizing: border-box;

      @include custom_scroll;

      &_dark {
        @include custom_scroll_dark;
      }

      &::-webkit-scrollbar-thumb {
        visibility: hidden;
      }

      &::-moz-scrollbar-thumb {
        visibility: hidden;
      }

      &:hover {
        scrollbar-color: rgba($color: #636363, $alpha: 0.5) transparent;
        &::-webkit-scrollbar-thumb {
          visibility: visible;
        }

        &::-moz-scrollbar-thumb {
          visibility: visible;
        }
      }

      & > ul {
        position: relative;
        width: 100%;
        // border: 1px solid red;
        box-sizing: border-box;
        padding-bottom: 8px;

        & > li {
          position: relative;
          width: 100%;
          display: inline-block;
          padding: 4px 0;
          box-sizing: border-box;
        }

        & > li:not(:nth-child(1)) {
          margin-top: 4px;
        }

        & > li:last-child {
          margin-bottom: 8px;
        }
      }
    }

    .with_actions {
      padding-bottom: 46px;
      // border: 1px solid red;
    }
  }

  &_dark {
    .space_members_raw {
      .name {
        & > h5 {
          color: $app_darkmode_text1;
        }
      }
    }
  }
}

@media screen and (max-width: 800px) {
  .space_members {
    &_raw {
      .add_member {
        &_input {
          display: none;
          overflow: hidden;
        }

        .clear_input {
          display: none;
        }
      }

      .list {
        min-height: 140px;
      }
    }
  }
}
