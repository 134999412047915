@import '../../../theme/commons.module.scss';
@import '../../../theme/color.scss';

.user_notification_entity {
  position: relative;
  width: 100%;
  padding: 20px 0px;
  padding-left: 5px;
  cursor: pointer;
  border-radius: 4px;

  &_link {
    a {
      position: absolute;
      height: 100%;
      width: 100%;
      left: 0;
      top: 0;
    }
  }

  &_unread {
    position: relative;
    display: inline-block;
    border-radius: 50% 50%;
    height: 8px;
    width: 8px;
    margin-right: 4px;
    background: $app_red_shade1;
    left: 0;
    margin-bottom: 2px;
  }

  .message {
    width: calc(100% - 85px);
    display: inline-block;
    box-sizing: border-box;
    padding-left: 5px;
    overflow-wrap: break-word;

    & > p {
      font-size: 90%;
      line-height: 20px;
      max-width: 99%;
      display: inline-block;
      overflow-wrap: break-word;
      font-weight: unset;
      font-family: 'WorkSans-Medium';

      span {
        position: relative;
        font-size: 100%;
        font-family: 'WorkSans-Medium';
        color: $app_purple_shade1;
      }
    }

    &_dark {
      & > p {
        span {
          color: $app_white_absolute;
        }
      }
    }
  }

  .date {
    display: inline-block;
    position: relative;
    height: 100%;
    width: 75px;
    box-sizing: border-box;

    & > h5 {
      font-size: 80%;
      font-weight: unset;
      font-family: 'WorkSans-Medium';
      text-align: center;
    }
  }

  .preview {
    position: relative;
    width: calc(100% - 25px);
    margin-left: 10px;
    display: inline-block;
    box-sizing: border-box;
    margin-top: 20px;
  }
}
