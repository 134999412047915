@import '../../../theme/color.scss';
@import '../../../theme/commons.module.scss';

.saving_progress {
  position: fixed;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  box-sizing: border-box;
  z-index: calc(#{$high_front} + 9);

  .content {
    position: relative;
    margin-top: 24px;
    width: 100%;
    padding: 8px 4px;
    box-sizing: border-box;
    background: #fffaed;
    border-radius: 8px;
    max-width: 230px;
    user-select: none;
    -webkit-user-drag: none;

    .icon {
      position: relative;
      height: 30px;
      width: 30px;
      margin-right: 4px;
    }

    & > p {
      color: #61522e;
    }
  }
}
