@import '../../theme/color.scss';
@import '../../theme/commons.module.scss';

.empty_content {
  position: relative;
  width: 100%;
  margin-top: 40px;
  padding: 16px 16px;
  box-sizing: border-box;

  .content {
    position: relative;

    .icon {
      position: relative;
      padding: 8px 16px;
      box-sizing: border-box;
      border-radius: 4px;
      background: #f9fafc;
      margin: 8px 0;

      &_dark {
        background: rgba($color: $app_darkmode_text1, $alpha: 0.04);
      }
    }

    .message {
      position: relative;
      margin-top: 16px;
      font-weight: unset;
      font-family: 'WorkSans-Medium';

      &_dark {
        color: #fff;
      }
    }

    .cta {
      position: relative;
      width: 100%;
      margin-top: 16px;

      & > h5 {
        font-weight: unset;
        font-family: 'WorkSans-Regular';
        margin: 0 6px;
        font-size: 95%;
        white-space: nowrap;

        span {
          margin: 0 4px;
        }
      }
    }
  }
}

@media screen and (max-width: 420px) {
  .empty_content {
    .content {
      .cta {
        & > h5 {
          font-size: 85%;
          font-family: 'WorkSans-Medium';
        }
      }
    }
  }
}

@media screen and (max-width: 340px) {
  .empty_content {
    .content {
      .cta {
        & > h5 {
          white-space: normal;
        }
      }
    }
  }
}
