@import '../../../theme/color.scss';
@import '../../../theme/commons.module.scss';
@import '../../../theme/scroll.scss';

@keyframes runMobile {
  0% {
    bottom: -90vh;
  }
  100% {
    bottom: 0;
  }
}

.tags {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  box-sizing: border-box;

  .content {
    position: relative;
    width: 400px;
    padding: 24px 24px;
    box-sizing: border-box;
    border-radius: 10px;
    background: #fff;
    box-shadow: 0px 4px 50px rgba(16, 24, 64, 0.04);
    -webkit-box-shadow: 0px 4px 50px rgba(16, 24, 64, 0.04);
    -moz-box-shadow: 0px 4px 50px rgba(16, 24, 64, 0.04);
    align-items: flex-start;

    &_dark {
      border: none;
      border: 0.5px solid rgba($color: $app_darkmode_text1, $alpha: 0.1);
      -moz-box-shadow: none;
      -webkit-box-shadow: none;
      box-shadow: none;
      background: $app_darkmode_background_header1;
    }

    .saving {
      position: relative;
      padding: 4px 8px;
      box-sizing: border-box;
      width: 100%;

      &_dark {
        svg {
          circle {
            stroke: $app_white_light;
          }
        }
      }
    }

    .title {
      position: relative;
      width: 100%;

      & > h2 {
        position: relative;
        font-size: 110%;
        font-weight: unset;
        font-family: 'WorkSans-Medium';
      }

      &_dark {
        & > h2 {
          color: #fff;
        }
      }
    }

    .list {
      position: relative;
      width: 100%;
      margin-top: 16px;

      & > ul {
        position: relative;
        list-style: none;
        list-style-type: none;
        width: 100%;

        & > li {
          position: relative;
          display: inline-block;
          vertical-align: middle;
          margin-top: 8px;
          margin-right: 8px;
          box-sizing: border-box;
          cursor: pointer;
        }
      }

      max-height: 360px;
      overflow: hidden;
      overflow-y: scroll;

      @include custom_scroll;

      &_dark {
        @include custom_scroll_dark;
      }
    }

    .close {
      position: absolute;
      top: 16px;
      right: 16px;

      & > button {
        height: 30px;
        width: 30px;
        padding: 0;
        box-sizing: border-box;

        svg {
          fill: #718096;

          path {
            fill: #718096;
          }
        }
      }

      &_dark {
        & > button {
          background: transparent !important;
          color: $app_darkmode_text1 !important;

          &:hover,
          &:active {
            background: rgba(
              $color: $app_darkmode_text1,
              $alpha: 0.05
            ) !important;
          }

          svg {
            fill: $app_darkmode_text1;

            path {
              fill: $app_darkmode_text1;
            }
          }
        }
      }
    }
  }
}

.tag_unit {
  position: relative;
  background: $app_white_shade8;
  padding: 4px 8px;
  border-radius: 4px;
  box-sizing: border-box;
  border: 1px solid transparent;
  cursor: pointer;

  & > div:nth-child(1) {
    svg {
      fill: #4769e4;
    }
  }

  & > p {
    margin-left: 4px;
  }

  &_active {
    position: relative;
    border: 1px solid #4769e4;

    & > p {
      color: #4769e4;
    }
  }

  &_dark {
    background: #222a34;

    & > p {
      color: rgba(195, 208, 229, 0.75);
    }
  }

  &_dark_active {
    border: 1px solid #fff;

    & > div:nth-child(1) {
      svg {
        fill: #fff;
      }
    }

    & > p {
      color: #fff;
    }
  }
}

@media screen and (max-width: 700px) {
  .tags {
    left: 0;

    .content {
      position: absolute;
      height: 80%;
      width: 100%;
      bottom: 0;
      left: 0;
      max-height: unset;
      padding: 24px 16px;
      box-sizing: border-box;
      border-radius: unset;
      overflow: hidden;
      overflow-y: scroll;
      justify-content: flex-start;
      align-items: center;
      animation: ease;
      animation-name: runMobile;
      animation-duration: 0.25s;

      @include custom_scroll;

      &_dark {
        @include custom_scroll_dark;
      }

      .saving {
        margin-top: 32px;
      }

      .list {
        overflow-y: visible;
        overflow: visible;
        max-height: unset;
        min-height: unset;

        &_dark {
          overflow: visible;
        }
      }
    }
  }
}
