@import '../../theme/color.scss';
@import '../../theme/commons.module.scss';

.no_results {
  position: relative;
  width: 100%;
  margin-top: 8px;
  // border: 2px solid red;
  box-sizing: border-box;

  .pensive {
    position: relative;
    padding: 4px 8px;
    border-radius: 4px;
    background: $app_gray_shade5;

    &_dark {
      background: $app_darkmode_background3;
    }
  }

  & > h4 {
    position: relative;
    font-size: 105%;
    font-weight: unset;
    font-family: 'WorkSans-Medium';
    margin-top: 8px;
  }

  & > p {
    position: relative;
    max-width: 94%;
    margin-top: 8px;
    font-weight: unset;
    font-family: 'WorkSans-Regular';
    color: #718096;
    margin-bottom: 4px;
    text-align: center;
  }

  &_dark {
    & > h4 {
      color: #fff;
    }

    & > p {
      color: $app_darkmode_text1;
    }
  }
}

@media screen and (max-width: 900px) {
  .no_results {
    box-sizing: border-box;
    padding: 8px 4px;
  }
}
