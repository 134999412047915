@import '../../theme/color.scss';
@import '../../theme/commons.module.scss';

.password_protect {
  position: fixed;
  top: 64px;
  left: 0;
  height: calc(100% - 64px);
  width: 100%;
  box-sizing: border-box;
  z-index: calc(#{$high_front} + 8);
  background: rgba($color: #fff, $alpha: 0.6);
  backdrop-filter: blur(4px);

  &_dark {
    background: transparent;
  }

  .content {
    position: relative;
    width: 440px;
    padding: 24px 24px;
    box-sizing: border-box;
    border-radius: 10px;
    background: #fff;
    border: 1px solid #f4f7fb;
    box-shadow: 0px 4px 50px rgba(16, 24, 64, 0.04);
    -webkit-box-shadow: 0px 4px 50px rgba(16, 24, 64, 0.04);
    -moz-box-shadow: 0px 4px 50px rgba(16, 24, 64, 0.04);
    align-items: flex-start;

    & > h1 {
      position: relative;
      font-weight: unset;
      font-family: 'WorkSans-Medium';
      font-size: 190%;
    }

    & > p {
      position: relative;
      margin-top: 16px;
      width: 100%;
      font-weight: unset;
    }

    .error {
      position: relative;
      width: 100%;
      padding: 4px 16px;
      padding-right: 8px;
      margin: 8px 0;
      box-sizing: border-box;

      .icon {
        position: relative;
        width: 30px;
        height: 30px;

        svg {
          path {
            fill: #e53e3e;
          }
        }
      }

      .texts {
        position: relative;
        width: calc(100% - 76px);
        margin: 0 8px;

        & > h3 {
          position: relative;
          font-weight: unset;
          font-family: 'WorkSans-Medium';
        }

        & > p {
          position: relative;
          margin-top: 8px;
          font-weight: unset;
        }
      }

      &_close {
        position: relative;
        width: 30px;
        height: 30px;

        &_dark {
          & > button {
            background: transparent !important;

            &:hover,
            &:active {
              background: rgba(
                $color: $app_darkmode_text1,
                $alpha: 0.05
              ) !important;
            }

            svg {
              fill: $app_darkmode_text1;

              path {
                fill: $app_darkmode_text1;
              }
            }
          }
        }
      }
    }

    .input {
      position: relative;
      width: 100%;
      margin-top: 16px;

      & > input[type='text'],
      & > input[type='password'] {
        position: relative;
        width: 100%;
        height: 40px;
        padding: 4px 8px;
        padding-right: 36px;
        border: 1px solid #e2e8f0;
        background: transparent;
        font-weight: unset;
        font-size: 105%;
        font-family: 'WorkSans-Medium';

        &:active {
          background: transparent;
        }
      }

      .toggle_show {
        position: absolute;
        height: 40px;
        top: 0;
        right: 4px;
        padding: 0;
        z-index: calc(#{$high_front} + 9);

        &_dark {
          background: transparent !important;

          &:hover {
            background: rgba(
              $color: $app_darkmode_text1,
              $alpha: 0.05
            ) !important;
          }

          svg {
            fill: $app_darkmode_text1;

            path {
              fill: $app_darkmode_text1;
            }
          }
        }
      }

      &_dark {
        & > input[type='text'],
        & > input[type='password'] {
          background: transparent;
          border: 1px solid #28303b;
          color: $app_darkmode_text1;

          &:focus,
          &:active {
            background: transparent;
          }
        }

        button {
          svg {
            fill: $app_darkmode_text1;
          }
        }
      }
    }

    .confirm {
      position: relative;
      width: 100%;
      margin-top: 24px;

      .view_note {
        position: relative;
        width: 100%;
        height: 40px;
      }
    }

    .back_to_home {
      position: relative;
      width: 100%;
      margin-top: 16px;

      & > a {
        position: relative;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        text-decoration: none;
        color: #4769e4;
        font-weight: unset;
        font-family: 'WorkSans-Medium';

        &:hover {
          text-decoration: underline;
        }

        svg {
          margin-left: 8px;
          transform: rotate(90deg);
          fill: #4769e4;
          margin-top: 1px;

          path {
            fill: #4769e4;
          }
        }
      }
    }

    &_dark {
      background: #1a1e24;
      border: 1px solid transparent;
    }
  }
}

@media screen and (max-width: 900px) {
  .password_protect {
    width: 100vw;
  }
}

@media screen and (max-width: 460px) {
  .password_protect {
    width: 100vw;

    .content {
      width: 90vw;
    }
  }
}
