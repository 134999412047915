@import '../../../theme/color.scss';
@import '../../../theme/commons.module.scss';

@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.file_preview {
  position: relative;
  width: 100%;
  box-sizing: border-box;
  border-radius: 4px;
  padding: 8px 4px;
  border: 2px solid rgba($color: #d8dae5, $alpha: 0.5);

  &_dark {
    position: relative;

    & > div:nth-child(1) {
      & > div:nth-child(1),
      & > div:nth-child(2) {
        & > h6 {
          color: $app_darkmode_text1;
        }
      }
    }
  }

  &_spinner {
    position: relative;
    height: 22px;
    width: 22px;
    border-radius: 50%;
    box-sizing: border-box;
    border: 4px solid #f3f3f3;
    border-top: 4px solid rgba($color: $app_dominant_purple, $alpha: 1);
    -webkit-animation: lds-ring 1s cubic-bezier(0.5, 0, 0.5, 1) infinite; /* Safari */
    -moz-animation: lds-ring 1s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    animation: lds-ring 1s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  }

  &_light {
    background: transparent;

    & > div:nth-child(1) {
      & > div:nth-child(1),
      & > div:nth-child(2) {
        & > h6 {
          // border: 2px solid red;
          color: $app_black_shade1;
        }
      }
    }
  }

  &_hover {
    position: absolute;
    bottom: calc(60% - 4px);
    background: $app_black_shade1;
    padding: 4px 8px;
    border-radius: 4px;
    display: none;
    left: 45px;

    & > h6 {
      position: relative;
      color: $app_white_absolute;
      font-weight: unset;
      font-family: 'WorkSans-SemiBold';
      font-size: 13px;
      min-width: 220px;
      max-height: 280px;
      max-width: 420px;
      margin-top: 4px;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  &_info {
    position: relative;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: calc(85% - 50px);

    &:hover {
      .file_preview_hover {
        display: inline-block;
      }
    }

    &_icon {
      position: relative;
      height: 40px;
      width: 40px;
      display: flex;
      justify-content: center;
      align-items: center;

      & > img {
        position: relative;
        height: 40px;
        width: 40px;
        object-position: center;
        object-fit: contain;
        top: 0;
        outline: none;
        border: none;
      }

      &_loading,
      &_error {
        position: relative;
        height: 40px;
        width: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      &_loading {
        font-weight: unset;
        font-family: 'WorkSans-Medium';
        font-size: 13px;
        text-align: center;

        &_dark {
          color: #c3d0e5;
        }

        &_light {
          color: #8f95b2;
        }
      }

      &_error {
        & > img {
          position: relative;
          height: 20px;
          width: 20px;
          object-position: center;
          object-fit: contain;
          top: 0;
          outline: none;
          border: none;
        }
      }
    }

    &_texts {
      position: relative;
      margin-left: 8px;
      box-sizing: border-box;
      min-width: 220px;
      max-width: 360px;

      & > h6 {
        position: relative;
        margin-top: 2px;
        font-size: 13px;
        font-weight: unset;
        font-family: 'WorkSans-Medium';
      }

      & > h6:nth-child(1) {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }

      & > h6:nth-child(2) {
        margin-top: 4px;
        font-size: 12px;
      }
    }
  }

  &_progress {
    position: relative;
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    height: 4px;
    border-radius: 10px;
    background: #e0e2e9;
    overflow: hidden;
    margin-top: 10px;

    & > div:nth-child(1) {
      position: relative;
      width: calc(1% + 2px);
      top: 0;
      left: -2px;
      height: 4px;
      transition: width 0.2s ease-in;
      background: $app_dominant_purple;
    }
  }

  &_close {
    position: absolute;
    height: 40px;
    width: 40px;
    right: 0;
    top: calc((100% - 40px) / 2);
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    z-index: calc(#{$high_front} + 1);

    &_dark {
      svg {
        path {
          fill: #c3d0e5;
        }
      }
    }

    &_light {
      svg {
        path {
          fill: #8f95b2;
        }
      }
    }
  }

  &_link {
    a {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
    }
  }
}

@media screen and (max-width: 900px) {
  .file_preview {
    &_info {
      &_texts {
        max-width: 250px;
        min-width: 220px;
      }
    }
  }
}

@media screen and (max-width: 450px) {
  .file_preview {
    &_info {
      &_texts {
        max-width: 160px;
        min-width: 160px;
      }
    }

    &_hover {
      & > h6 {
        max-height: 220px;
        max-width: 310px;
      }
    }
  }
}
