@import '../../theme/commons.module.scss';
@import '../../theme/color.scss';

.docs_header {
  height: 72px;
  width: 100%;
  background: #fff;
  border-bottom: 1.2px solid rgba(224, 224, 224, 0.6);
  display: flex;
  flex-direction: row;
  text-align: center;
  align-items: center;
  justify-content: space-between;
  padding: 0 24px;
  box-sizing: border-box;

  .logo {
    position: relative;
    margin: 0;
    padding: 4px;
    justify-content: flex-start;
    box-sizing: border-box;
    text-decoration: none;

    & > h2 {
      text-decoration: none;
    }
  }

  .title {
    margin-left: 8px;
    align-items: center;
    display: flex;
    font-weight: unset;
    font-family: 'WorkSans-Medium';
  }

  .search_button,
  .more_button {
    position: relative;
    padding: 4px;
    margin: 0;
    box-sizing: border-box;
    height: 30px;
    width: 30px;
    margin-right: 8px;
    display: none;
  }

  .login_signup_wrapper {
    display: flex;
    justify-content: flex-end;
    box-sizing: border-box;

    .buttons {
      margin-right: 16px;
      padding: 14px 16px;
      font-size: 100%;
      box-sizing: border-box;

      & > p,
      & > h5 {
        font-family: 'WorkSans-Medium';
        font-size: 105%;
      }
    }

    .avatar {
      position: relative;
      height: 40px;
      width: 40px;

      & > a {
        position: relative;
        height: 40px;
        width: 40px;
        display: flex;
        justify-content: center;
        align-items: center;

        span {
          color: #fff;

          & > div {
            font-size: 90%;
            font-family: 'WorkSans-Medium';
            color: #fff;
          }
        }
      }

      &_default {
        position: relative;
        height: 40px;
        width: 40px;
        background: #000;
        border-radius: 50%;
        overflow: hidden;

        svg {
          path {
            fill: #fff;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 900px) {
  .docs_header {
    position: fixed;
    z-index: calc(#{$high_front} + 5);
    top: 0;
    left: 0;
    bottom: unset;
    width: 100vw;
    box-sizing: border-box;
    padding: 0 12px;
    justify-content: space-between;

    .logo {
      position: relative;
    }

    .title {
      margin-left: 8px;
    }

    .search_button,
    .more_button {
      display: flex;
      margin: 0 4px;
      box-sizing: border-box;
    }

    .login_signup_wrapper {
      .avatar {
        position: relative;
        height: 30px;
        width: 30px;

        & > a {
          height: 30px;
          width: 30px;

          & > div {
            height: 30px;
            width: 30px;

            div {
              height: 30px;
              width: 30px;
            }

            img {
              height: 30px;
              width: 30px;
            }
          }
        }

        &_default {
          height: 30px;
          width: 30px;
        }
      }
    }
  }
}

@media screen and (max-width: 390px) {
  .docs_header {
    box-sizing: border-box;
    padding: 0 8px;

    .logo {
      .title {
        margin-left: 4px;
      }
    }

    .login_signup_wrapper {
      box-sizing: border-box;

      .buttons {
        margin-right: 4px;
      }
    }
  }
}
