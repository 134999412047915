@import '../../../theme/color.scss';
@import '../../../theme/commons.module.scss';

.sidebar {
  width: 100%;

  .sidebar_title {
    color: $app_black_shade6;
    font-weight: 600;
    font-size: 100%;
    line-height: 16.42px;
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    padding-left: 24px;
    padding-top: 28px;
    box-sizing: border-box;

    &:first-child {
      border: none;
    }

    &:not(first-child) {
      border-top: 1.2px solid rgba(224, 224, 224, 0.6);
    }
  }

  .sidebar_item {
    text-decoration: none;

    .icon_header_wrapper {
      display: flex;
      align-items: center;

      & > svg {
        display: inline-block;
        width: auto;
        padding-left: 25px;
      }

      & > h4 {
        color: rgba(45, 55, 72, 0.8);
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        font-style: normal;
        display: inline-block;
      }

      &_documentation {
        color: rgba(45, 55, 72, 0.8);
        padding-top: 14px;
      }

      .icon {
        padding-right: 16px;

        path {
          fill: #718096;
        }
      }
    }

    & > h4 {
      color: rgba(45, 55, 72, 0.8);
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      font-style: normal;
      display: inline-block;
    }

    &_getting_started {
      color: rgba(45, 55, 72, 0.8);
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      padding-top: 14px;
      font-style: normal;
      display: inline-block;
      padding-left: 25px;
    }
  }

  .bottom_item {
    padding-bottom: 26px;
  }

  &_remove_borders {
    border: none;

    .sidebar_title {
      &:first-child {
        border: none;
      }

      &:not(first-child) {
        border: none;
      }
    }
  }
}
