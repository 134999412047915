@import '../../../../theme/color.scss';
@import '../../../../theme/commons.module.scss';
@import '../../../../theme/scroll.scss';

.sidepanel {
  position: relative;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  border-right: 1px solid #f4f7fb;
  border-color: rgba(237, 239, 242, 1);
  justify-content: flex-start;
  padding: 16px 24px;
  padding-bottom: 0;
  box-sizing: border-box;
  z-index: calc(#{$high_front} + 5);
  overflow: visible;

  &_dark {
    background: $app_darkmode_background1;
    border-right: 1.5px solid #28303b;
  }

  .header {
    position: relative;
    width: 100%;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0;

    .icon {
      position: relative;
    }

    .icon_link {
      a {
        position: absolute;
        height: 100%;
        width: 100%;
        top: 0;
        left: 0;
        text-decoration: none;
      }
    }

    .right {
      position: relative;
      box-sizing: border-box;

      .toggle_expand,
      .notif_bell {
        position: relative;
        padding: 4px 8px;

        &_dark {
          background: transparent !important;

          & > svg {
            fill: $app_darkmode_text1;
            path {
              fill: $app_darkmode_text1;
            }
          }

          &:hover,
          &:active,
          &:focus {
            background: rgba(
              $color: $app_darkmode_text1,
              $alpha: 0.05
            ) !important;
          }
        }
      }

      .notif_bell {
        margin-right: 4px;

        .link {
          a {
            position: absolute;
            height: 100%;
            width: 100%;
            top: 0;
            left: 0;
          }
        }
      }

      &_shrink {
        flex-direction: column;
        justify-content: flex-start;
        width: 100%;

        .notif_bell {
          margin-right: 0;
          margin-top: 16px;
        }
      }
    }

    .notif_bell_new {
      position: absolute;
      top: -4px;
      right: -4px;
      border-radius: 50%;
      background: #bb1e1e;
      height: 20px;
      width: 20px;

      & > p {
        position: relative;
        font-weight: unset;
        font-size: 95%;
        font-family: 'WorkSans-Medium';
        color: $app_white_absolute;
      }
    }
  }

  .search_button {
    background: transparent !important;

    &:hover,
    &:active {
      background: transparent !important;
    }

    &_dark {
      background: transparent !important;

      &:hover {
        background: rgba($color: $app_darkmode_text1, $alpha: 0.05) !important;
      }
    }
  }

  .space_options {
    position: absolute;
    min-width: 270px;
    max-width: 276px;
    top: calc(100% + 8px);
    left: -4px;
    padding: 8px 2px;
    padding-left: 4px;
    border-radius: 6px;
    max-height: 320px;
    overflow: hidden;
    overflow-y: scroll;
    scrollbar-color: rgba($color: #e8e8e8, $alpha: 0.8) transparent;
    z-index: calc(#{$high_front} + 6);

    -webkit-box-shadow: 0px 4px 8px rgba(16, 24, 64, 0.05);
    box-shadow: 0px 4px 8px rgba(16, 24, 64, 0.05);
    background: $app_white_absolute;
    border: 1px solid #e6e8f0;

    @include custom_scroll;

    .create_space {
      position: relative;
      width: 100%;
      padding: 0;
      justify-content: flex-start;
      margin-bottom: 16px;

      & > div:nth-child(1) {
        position: relative;
        height: 40px;
        width: 40px;
      }

      & > p {
        position: relative;
        font-size: 110%;
        margin-left: 6px;
      }

      &_dark {
        background: transparent !important;

        &:hover {
          background: rgba(
            $color: $app_darkmode_text1,
            $alpha: 0.05
          ) !important;
        }
      }
    }

    & > ul {
      position: relative;
      width: 100%;
      list-style-type: none;
      list-style: none;

      & > li {
        position: relative;
        width: 100%;

        & > button {
          position: relative;
          width: 100%;
          box-sizing: border-box;
          padding: 4px;
          height: 40px;
          justify-content: flex-start;
          background: transparent;

          & > p {
            max-width: calc(100% - 44px);
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            position: relative;
            font-size: 110%;
            font-weight: unset;
            font-family: 'WorkSans-Regular';
            color: #2d3748;
          }
        }
      }

      & > li:not(:nth-child(1)) {
        margin-top: 8px;
      }

      .space_option_avatar {
        position: relative;
        height: 36px;
        width: 36px;
        margin-right: 8px;
        border-radius: 4px;
        overflow: hidden;

        &_default {
          background: $app_black_shade1;
        }

        & > p {
          position: relative;
          color: $app_white_absolute;
          font-weight: unset;
          font-size: 110%;
          font-family: 'WorkSans-Medium';
        }

        & > img {
          position: relative;
          height: 36px;
          width: 36px;
          object-fit: cover;
        }
      }
    }

    &_dark {
      background: #1a1e24;
      border: 1px solid transparent;

      @include custom_scroll_dark;

      & > ul {
        & > li {
          & > button {
            background: transparent !important;
            color: $app_darkmode_text1 !important;

            & > p {
              color: $app_darkmode_text1 !important;
            }

            &:hover,
            &:active {
              background: rgba(
                $color: $app_darkmode_text1,
                $alpha: 0.05
              ) !important;
            }
          }
        }
      }
    }
  }

  .selected_space {
    position: relative;
    width: 100%;
    margin-top: 16px;

    & > button {
      position: relative;
      padding: 0;
      width: 100%;
      height: 40px;
      font-size: 100%;
    }

    .space_name {
      position: relative;
      width: calc(100% - 52px);
      margin-left: 12px;
      justify-content: flex-start;
      font-size: 100%;

      & > p {
        position: relative;
        font-weight: unset;
        font-size: 98%;
        max-width: 100%;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        font-family: 'WorkSans-Medium';
      }

      &_dark {
        & > p {
          color: #fff;
        }
      }

      &_with_arrow {
        justify-content: space-between;

        & > p {
          max-width: calc(100% - 30px);
        }
      }

      &_no_arrow {
        & > p {
          max-width: 100%;
        }
      }

      &_arrow {
        padding: 4px;
        box-sizing: border-box;
        transition: transform 0.1s ease;

        &_dark {
          svg {
            & > path:nth-child(2) {
              fill: #fff;
            }
          }
        }

        &_up {
          transform: rotate(-180deg);
        }
      }
    }

    .space_icon {
      position: relative;
      height: 40px;
      width: 40px;
      border-radius: 4px;
      overflow: hidden;

      .space_avatar {
        position: relative;
        height: 100%;
        width: 100%;
        object-fit: cover;
        object-position: center;
      }

      .space_avatar_default {
        position: absolute;
        height: 100%;
        width: 100%;
        top: 0;
        left: 0;
        background: $app_black_shade1;

        & > h5 {
          position: relative;
          font-weight: unset;
          font-size: 105%;
          color: $app_white_absolute;
          font-family: 'WorkSans-Medium';
        }
      }
    }

    &_dark {
      & > button {
        background: transparent;

        &:hover,
        &:active {
          background: rgba(
            $color: $app_darkmode_text1,
            $alpha: 0.05
          ) !important;
        }
      }
    }
  }

  .search {
    position: relative;
    width: 100%;
    box-sizing: border-box;
    margin-top: 24px;

    input[type='text'] {
      padding-left: 40px;
      box-sizing: border-box;
      position: relative;
      height: 40px;
      width: 100%;
      font-weight: unset;
      font-size: 95%;
      border-radius: 4px;
      font-family: 'WorkSans-Medium';
    }

    &_icon {
      position: absolute;
      top: 11px;
      left: 10px;
      z-index: calc(#{$high_front} + 6);

      svg {
        fill: #718096;
      }

      &_dark {
        svg {
          fill: $app_darkmode_text1;
          path {
            fill: $app_darkmode_text1;
          }
        }
      }
    }

    &_dark {
      background: transparent;

      input[type='text'] {
        background: transparent !important;
        border: 1px solid #28303b !important;
        color: $app_darkmode_text1;

        &::placeholder {
          /* Chrome, Firefox, Opera, Safari 10.1+ */
          color: $app_darkmode_text1;
          opacity: 1; /* Firefox */
        }

        &:-ms-input-placeholder {
          color: $app_darkmode_text1;
        }

        &::-ms-input-placeholder {
          color: $app_darkmode_text1;
        }
      }
    }
  }

  .tags {
    position: relative;
    width: calc(100% + 48px);
    height: calc(100% - 220px);
    margin-top: 8px;
    overflow: hidden;
    overflow-y: scroll;
    padding-right: 8px;
    padding-left: 18px;
    padding-bottom: 16px;
    box-sizing: border-box;

    & > ul {
      list-style: none;
      list-style-type: none;
      position: relative;
      width: 100%;
    }

    &_light {
      @include custom_scroll;
    }

    &_dark {
      @include custom_scroll_dark;
    }

    scrollbar-color: transparent transparent;
    &::-webkit-scrollbar-thumb {
      visibility: hidden;
    }
    &::-moz-scrollbar-thumb {
      visibility: hidden;
    }

    &:hover {
      scrollbar-color: rgba($color: #636363, $alpha: 0.4) transparent;

      &::-webkit-scrollbar-thumb {
        visibility: visible;
      }
      &::-moz-scrollbar-thumb {
        visibility: visible;
      }
    }

    .loading {
      position: relative;
      width: 100%;
      margin-top: 16px;
      margin-bottom: 8px;
      padding: 0 4px;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      box-sizing: border-box;

      & > div {
        position: relative;
        width: 100%;
        padding-left: 0;
        padding-right: 16px;
        box-sizing: border-box;
        justify-content: flex-start;
      }

      &_dark {
        svg {
          circle {
            stroke: $app_white_light;
          }
        }
      }
    }
  }

  .user_options {
    position: absolute;
    height: 50px;
    width: 100%;
    bottom: 8px;
    left: 0;
    padding: 0 18px;
    box-sizing: border-box;
    justify-content: space-between;

    .user_avatar {
      position: relative;
      width: calc(100% - 40px);
      margin-right: 4px;
      box-sizing: border-box;
      justify-content: flex-start;

      & > div:nth-child(1) {
        position: relative;

        span {
          & > div {
            color: #fff;
            font-size: 90%;
          }
        }
      }

      & > p {
        position: relative;
        margin-left: 8px;
        font-size: 90%;
        max-width: calc(100% - 48px);
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }

    .more {
      position: relative;
      height: 36px;
      width: 36px;

      &_button {
        height: 36px;
        width: 36px;
        padding: 4px;
        box-sizing: border-box;
      }
    }

    .more_button_dark {
      background: transparent;

      &:hover,
      &:active {
        background: rgba($color: $app_darkmode_text1, $alpha: 0.05) !important;
      }

      svg {
        fill: $app_darkmode_text1 !important;
        path {
          fill: $app_darkmode_text1 !important;
        }
      }
    }

    .more_options {
      position: absolute;
      top: unset;
      right: 0;
      min-width: 190px;
      bottom: calc(100% + 10px);

      &_shrink {
        left: 0;
        right: unset;
      }
    }

    &_shrink {
      justify-content: center;
      padding: 0 24px;

      .more {
        height: 40px;
        width: 40px;

        &_button {
          height: 40px;
          width: 40px;
        }
      }
    }
  }
}

@media screen and (max-width: 900px) {
  .sidepanel {
    .space_options {
      position: absolute;
      min-width: 225px;
      max-width: 236px;
    }
  }
}
