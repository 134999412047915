@import '../../theme/color.scss';
@import '../../theme/commons.module.scss';

.avatar_premium {
  position: absolute;
  height: 22px;
  width: 22px;
  border-radius: 50%;
  background: linear-gradient(135deg, #ffc83a 0%, #e4a647 100%);
  border: 1px solid #fff;
  box-sizing: border-box;
  bottom: -6px;
  right: -6px;

  svg {
    fill: #fff !important;

    path {
      fill: #fff !important;
    }
  }
}
