@import '../../theme/commons.module.scss';

.user_main_wrap {
  position: relative;
  min-height: 100%;
  width: 100%;
}

@media screen and (max-width: 900px) {
  .user_main_wrap {
    min-height: unset;
    height: 100%;
  }
}
