@import '../../theme/color.scss';
@import '../../theme/commons.module.scss';

.message_hover {
  position: absolute;
  top: calc(100% + 10px);
  right: 0;
  text-align: center;
  z-index: $high_front;

  & > div:nth-child(1) {
    position: relative;
    padding: 8px 12px;
    border-radius: 5px;
    box-sizing: border-box;
    background: $app_black_shade1;

    & > h5 {
      position: relative;
      font-weight: unset;
      font-family: 'WorkSans-Regular';
      font-size: 90%;
      text-align: center;
      color: $app_white_absolute;
    }
  }
}
