@import '../../../../theme/color.scss';
@import '../../../../theme/commons.module.scss';

.add_tag {
  position: relative;
  width: 100%;
  margin-top: 4px;
  box-sizing: border-box;
  margin-top: 8px;
  padding-right: 10px;

  & > button {
    position: relative;
    padding: 4px 8px;
    width: 100%;
    box-sizing: border-box;
    justify-content: flex-start;

    svg {
      fill: #718096;
      path {
        fill: #718096;
      }
    }

    & > p {
      position: relative;
      color: #718096;
      margin-left: 8px;
      font-size: 115%;
    }
  }
  &_dark {
    & > button {
      background: transparent;

      &:hover,
      &:active {
        background: rgba($color: $app_darkmode_text1, $alpha: 0.05) !important;
      }

      svg {
        fill: rgba(195, 208, 229, 0.75);
        path {
          fill: rgba(195, 208, 229, 0.75);
        }
      }

      & > p {
        color: rgba(195, 208, 229, 0.75);
      }
    }
  }
}
