@import '../../theme/color.scss';
@import '../../theme/commons.module.scss';

.files_view {
  position: relative;
  width: 100%;

  .content {
    position: relative;
    width: 100%;
    justify-content: flex-start;
    align-items: flex-start;
  }
}
