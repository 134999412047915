@import '../../theme/commons.module.scss';
@import '../../theme/color.scss';

.saved_search_unit {
  position: relative;
  padding: 4px 8px;
  border-radius: 2px;
  box-sizing: border-box;
  background: #f9fafc;
  cursor: pointer;

  & > p {
    position: relative;
    font-size: 90%;
    line-height: unset;
    font-weight: unset;
    font-family: 'WorkSans-Regular';
    color: #718096;
  }

  &_dark {
    background: $app_darkmode_background3;

    & > p {
      color: $app_darkmode_text1;
    }
  }
}

@media screen and (max-width: 900px) {
  .saved_search_unit {
    & > p {
      font-size: 95%;
    }
  }
}
