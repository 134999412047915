@import '../../theme/color.scss';
@import '../../theme/commons.module.scss';

.create_space {
  position: relative;
  width: 100%;
  top: 0;
  left: 0;
  box-sizing: border-box;

  .raw {
    position: relative;
    padding: 0;
    width: 100%;
    background: transparent;

    .space {
      position: relative;
      width: 100%;
      padding: 0 5px;
      box-sizing: border-box;
      margin-bottom: 5px;
      margin-top: 20px;
      position: relative;
      padding: 0 5px;
      box-sizing: border-box;

      &_title {
        position: relative;
        width: 100%;
        margin: 4px 0;

        & > h5 {
          display: inline-block;
          font-weight: unset;
          font-family: 'WorkSans-Medium' !important;
          font-size: 100%;
        }

        &_dark {
          & > h5 {
            font-weight: unset;
            color: $app_darkmode_text1;
          }
        }
      }

      &_input {
        position: relative;
        margin-top: 10px;
        width: 100%;
        height: 40px;
        box-sizing: border-box;
        font-family: 'WorkSans-Regular' !important ;
      }

      &_dark {
        input[type='text'],
        input[type='password'] {
          background: transparent !important;
          border: 1px solid #28303b;
          color: $app_darkmode_text1 !important;
          font-size: 100%;

          &:focus,
          &:active {
            background: transparent;
          }
        }
      }
    }

    .buttons {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      margin-top: 20px;

      button {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 38px;
        box-sizing: border-box;

        & > h4 {
          font-weight: unset;
          font-family: 'WorkSans-Medium';
          font-size: 120%;
        }
      }

      & > button:nth-child(2) {
        position: relative;
        color: $app_white_absolute;
        & > h4,
        & > span {
          color: $app_white_absolute;
        }
      }

      .cancel {
        margin-right: 8px;

        &_dark {
          border: none;
          background: rgba($color: $app_darkmode_text1, $alpha: 0.02);

          &:hover {
            background: rgba($color: $app_darkmode_text1, $alpha: 0.1);
          }

          & > h4,
          & > h3 {
            color: $app_darkmode_text1;
          }
        }
      }

      .loading {
        position: relative;
        width: 100%;
        margin-top: 8px;
        margin-bottom: 8px;
        padding: 0 8px;
        box-sizing: border-box;
        justify-content: flex-end;

        &_dark {
          svg {
            circle {
              stroke: $app_white_light;
            }
          }
        }
      }
    }

    .proceed {
      &_enable {
        background: $app_dominant_purple;

        &:hover {
          background: $app_dominant_purple;
        }
      }

      &_disable {
        background: $app_gray_shade4;
        &:hover {
          background: $app_gray_shade4;
        }
      }
    }
  }
}

@media screen and (max-width: 900px) {
  .create_space {
    .raw {
      .space {
        &_title {
          & > h5 {
            font-family: 'WorkSans-Bold';
            font-size: 14px;
          }
        }
      }
    }
  }
}
