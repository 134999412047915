@import '../../theme/color.scss';
@import '../../theme/commons.module.scss';

.prio_icon {
  position: relative;

  &:hover {
    .priority_box_tip {
      display: flex;
    }
  }

  &_raw {
    position: relative;
    height: 100%;
    width: 100%;
  }

  .priority_box_tip {
    position: absolute;
    left: 100%;
    top: unset;
    bottom: 100%;
    display: none;
  }

  & > ul {
    position: relative;
    list-style-type: none;
    list-style: none;
    height: 20px;
    width: 20px;

    .low_prio {
      background: $app_low_prio_color;
    }

    .medium_prio {
      background: $app_medium_prio_color;
    }

    .high_prio {
      background: $app_high_prio_color;
    }

    .is_active {
      background: $app_black_shade1;
    }

    .is_default {
      background: $app_white_shade7;
    }

    & > li {
      position: relative;
      display: inline-block;
      width: 5px;
      box-sizing: border-box;
      border-radius: 7px;
    }

    & > li:nth-child(1) {
      height: 8px;
      width: 4px;
      margin-right: 2px;
    }

    & > li:nth-child(2) {
      height: 11px;
      margin-right: 2px;
    }

    & > li:nth-child(3) {
      height: 16px;
    }
  }
}

@media screen and (max-width: 900px) {
  .prio_icon {
    .priority_box_tip {
      bottom: unset;
      top: calc(100% + 10px);
    }
  }
}
