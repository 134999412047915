@import '../../../theme/commons.module.scss';
@import '../../../theme/assets.scss';

.better_image {
  max-width: 450px !important;
  padding: 0 8px;
  object-position: center;
  display: block;
  cursor: zoom-in;
  box-sizing: border-box;
}

.uploading_image {
  position: relative;
  opacity: 0.3 !important;
}

@media screen and (min-width: 1800px) {
  .better_image {
    max-width: 650px !important;
  }
}

@media screen and (min-width: 1680px) {
  .better_image {
    max-width: 550px !important;
  }
}

@media screen and (max-width: 900px) {
  .better_image {
    max-width: 460px !important;
    margin: 0;
  }
}

@media screen and (max-width: 450px) {
  .better_image {
    max-width: 93vw !important;
    margin: 0;
  }
}
