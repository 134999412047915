@import '../../../theme/commons.module.scss';
@import '../../../theme/color.scss';

.space_selected {
  position: relative;
  width: 100%;
  justify-content: flex-start;
  align-items: flex-start;
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  margin-bottom: 4px;
  padding-left: 24px;

  .space_info {
    position: relative;
    width: 100%;
    justify-content: flex-start;
    align-items: flex-start;

    &_actions {
      position: absolute;
      top: 8px;
      right: 20px;
      box-sizing: border-box;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      //   border: 2px solid red;

      .create {
        position: relative;
        margin-left: 8px;
        display: inline-block;

        &_button {
          position: relative;
          height: 40px;
          width: 100px;
          box-sizing: border-box;

          h5,
          p {
            font-family: 'WorkSans-Regular';
          }
        }
      }

      .settings,
      .members {
        position: relative;
        display: flex;

        button {
          position: relative;
          box-sizing: border-box;
          height: 40px;
          width: 40px;
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 0;
          background: #f9fafc;

          svg {
            fill: $app_gray_shade6;

            path {
              fill: $app_gray_shade6;
            }
          }

          & > h5 {
            display: inline-block;
            vertical-align: middle;
            font-weight: unset;
            font-family: 'WorkSans-Medium';
            font-size: 120%;
            margin-left: 4px;
            color: $app_gray_shade6;
          }
        }

        &_dark {
          button {
            background: transparent;
            background: rgba($color: $app_darkmode_text1, $alpha: 0.1);

            &:hover {
              background: rgba($color: $app_darkmode_text1, $alpha: 0.05);
            }

            svg {
              fill: #fff;

              path {
                fill: #fff;
              }
            }
          }
        }
      }

      .settings {
        margin-right: 4px;
      }
    }

    .space_avatar {
      position: relative;
      display: flex;
      height: 75px;
      width: 75px;
      border-radius: 6px;
      overflow: hidden;
      cursor: pointer;
      box-sizing: border-box;

      &_image {
        position: relative;
        height: 100%;
        width: 100%;
        display: inline-block;
        text-align: center;

        &_placeholder {
          position: absolute;
          height: 100%;
          width: 100%;
          vertical-align: middle;
          background: $app_black_shade1;

          p {
            font-size: 20px;
            color: $app_white_absolute;
            font-weight: unset;
            font-family: 'WorkSans-SemiBold';
          }
        }

        & > img {
          position: absolute;
          height: 100%;
          width: 100%;
          top: 0;
          left: 0;
          object-fit: cover;
          object-position: center;
        }
      }

      .initials {
        position: relative;
        height: 100%;
        width: 100%;
        display: inline-block;
        text-align: center;
        border-radius: 8px;
        background: $app_black_shade1;

        & > h4 {
          position: relative;
          line-height: 75px;
          display: inline-block;
          text-align: center;
          font-family: 'WorkSans-SemiBold';
          font-size: 30px;
          color: $app_white_absolute;
          text-align: center;
        }
      }

      .upload_avatar {
        position: absolute;
        height: 100%;
        width: 100%;
        // border-radius: 50%;
        border-radius: 8px;
        left: 0;
        top: 0;
        background: rgba($color: $app_black_shade1, $alpha: 0.7);
        text-align: center;
        display: none;

        .upload_ic {
          position: relative;
          height: 100%;
          width: 100%;
          border-radius: 8px;
          box-sizing: border-box;

          button {
            padding: 4px 8px;
            position: relative;
            height: 100%;
            width: 100%;
            top: 0;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 8px;
          }

          svg {
            fill: $app_white_absolute;
          }
        }
      }

      &:hover {
        .upload_avatar {
          display: flex;
        }
      }
    }

    &_texts {
      position: relative;
      width: calc(100% - 340px);
      margin-left: 16px;
      box-sizing: border-box;

      .read_only {
        position: relative;
        font-family: 'WorkSans-Medium' !important;
        font-size: 105%;
        box-shadow: none;
        border: none;
        outline: none;
        white-space: nowrap;
        width: 300px;
        overflow: hidden;
        text-overflow: ellipsis;
        box-sizing: border-box;
      }

      .not_read_only {
        position: relative;
        font-family: 'WorkSans-Medium' !important;
        font-size: 105%;
        white-space: nowrap;
        width: 300px;
        overflow: hidden;
        text-overflow: ellipsis;
        margin: 0;
        box-sizing: border-box;
      }

      .space_name,
      .space_bio {
        position: relative;
        box-sizing: border-box;
        min-height: 40px;

        .editing {
          position: relative;
          display: inline-block;

          & > div:nth-child(1) {
            margin: 0;
            padding: 0;

            & > div:nth-child(1) {
              margin-bottom: 0;
            }
          }

          label {
            display: none;
            margin: 0;
          }

          &_hidden {
            position: absolute;
            left: 100%;
            opacity: 0;
            top: 100%;
            width: 0;
            overflow: hidden;
            visibility: hidden;
          }
        }

        .to_edit {
          position: relative;
          display: inline-block;
          box-sizing: border-box;

          .to_edit_tip {
            position: absolute;
            top: calc(100% - 8px);
            left: 10%;
            display: none;
          }

          &:hover {
            .action_button {
              display: flex;
            }

            .to_edit_tip {
              display: flex !important;
            }
          }

          input[type='text'] {
            position: relative;
            display: inline-block;
          }

          .action {
            position: relative;
            display: inline-block;
            vertical-align: middle;
            // box-sizing: border-box;
            // border: 1px solid red;
            min-height: 20px;
            min-width: 20px;
            margin-left: 4px;

            &_button {
              display: none;

              svg {
                fill: $app_gray_shade6;
              }
            }

            &:hover {
              .action_button {
                display: flex;
              }
            }
          }

          &_dark {
            button {
              background: rgba($color: $app_darkmode_text1, $alpha: 0.01);

              &:hover {
                background: rgba($color: $app_darkmode_text1, $alpha: 0.06);
              }
            }
          }
        }
      }

      .space_bio {
        display: block;

        .not_read_only,
        .read_only {
          font-family: 'WorkSans-Regular' !important;
        }

        .not_read_only,
        .read_only {
          font-size: 85%;
        }
      }

      .space_name,
      .space_bio {
        &_dark {
          input[type='text'],
          input [type='password'] {
            border: none;
            outline: none;
            background: transparent;
            color: $app_darkmode_text1 !important;
          }
        }
      }

      .space_name {
        .read_only,
        .not_read_only {
          font-size: 110%;
        }
      }
    }
  }
}

@media screen and (min-width: 801px) and (max-width: 900px) {
  .space_selected {
    margin: 0;
    padding: 0;

    .space_info {
      width: 100%;
      box-sizing: border-box;

      &_actions {
        right: 8px;
      }

      &_texts {
        margin-left: 8px;
        box-sizing: border-box;
        width: calc(100% - 320px);

        .read_only,
        .not_read_only {
          min-width: 100%;
          width: 100%;
          box-sizing: border-box;
        }

        .space_name,
        .space_bio {
          width: 100%;
          box-sizing: border-box;

          .editing,
          .to_edit {
            width: 100%;
          }

          .to_edit {
            input[type='text'] {
              min-width: calc(100% - 42px) !important;
              width: calc(100% - 42px) !important;
            }

            .action_button {
              display: flex !important;
              box-sizing: border-box;
              overflow: hidden;
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 800px) {
  .space_selected {
    display: none;
    height: 0;
    overflow: hidden;
  }
}
