@import '../../theme/color.scss';
@import '../../theme/commons.module.scss';

.note_view_modal {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100vw;
  box-sizing: border-box;
  z-index: calc(#{$high_front} + 9);

  &_with_background {
    background: rgba(0, 0, 0, 0.2);

    &_dark {
      background: rgba(0, 0, 0, 0.4);
    }
  }
}
