@import '../../theme/commons.module.scss';
@import '../../theme/color.scss';
@import '../../theme/assets.scss';

.docs_main_wrap {
  display: flex;
  flex-wrap: wrap;

  .content {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    flex-direction: row;
  }

  .banner_wrapper {
    background-image: linear-gradient(to right, #fff, #f1f2ff);
    height: 180px;
    box-sizing: border-box;

    .title {
      font-size: 250%;
      font-weight: 600;
      color: $app_black_shade7;
      padding-top: 100px;
      padding-left: 90px;
    }
  }
}

.content_wrapper {
  position: relative;
  width: 100%;
  box-sizing: border-box;
}

@media screen and (max-width: 900px) {
  .docs_main_wrap {
    position: relative;
    width: 100%;
    top: 0;
    left: 0;
    padding-top: 60px;
    padding-bottom: 16px;
    box-sizing: border-box;
    bottom: unset;

    .banner_wrapper {
      position: relative;
      padding-left: 20px;
      width: 100%;
      box-sizing: border-box;

      .title {
        padding-left: 20px;
      }
    }
  }
}
