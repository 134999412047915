@import '../../../theme/color.scss';
@import '../../../theme/commons.module.scss';

.payment_failed {
  position: relative;
  height: 100vh;
  width: 100%;

  .raw {
    position: relative;
    width: 450px;
    padding: 30px 40px;
    box-sizing: border-box;
    border-radius: 10px;
    overflow: hidden;
    background: $app_white_absolute;
    border-bottom: 1.2px solid rgba($color: $app_white_shade3, $alpha: 0.6);
    box-shadow: 0px 4px 50px rgba(16, 24, 64, 0.04);
    -moz-box-shadow: 0px 4px 50px rgba(16, 24, 64, 0.04);
    -webkit-box-shadow: 0px 4px 50px rgba(16, 24, 64, 0.04);

    .failed {
      position: relative;
      width: 100%;
      box-sizing: border-box;

      .loading {
        position: relative;
        margin: 40px 0;

        &_dark {
          svg {
            circle {
              stroke: $app_white_light;
            }
          }
        }
      }

      .title,
      .message,
      .try_again,
      .redirect {
        position: relative;
        width: 100%;
      }

      &_ic {
        position: relative;
        width: 100%;
        justify-content: flex-start;

        & > div {
          background: #d14343;
          position: relative;
          height: 32px;
          width: 32px;
          border-radius: 50%;

          svg {
            height: 24px;
            width: 24px;
            fill: #fff;
          }
        }
      }

      .title {
        margin-top: 30px;

        & > h3 {
          font-weight: unset;
          font-family: 'WorkSans-Semibold';
          font-size: 190%;
        }
      }

      .message {
        margin-top: 20px;

        & > h5 {
          font-weight: unset;
          font-family: 'WorkSans-Regular';
          font-size: 14.4px;
          max-width: 95%;
        }
      }

      .try_again {
        position: relative;
        margin-top: 30px;

        &_button {
          height: 35px;
          padding-right: 80px;
          padding-left: 80px;
        }
      }

      .redirect {
        margin-top: 20px;

        span,
        h5 {
          position: relative;
          display: inline-block;
          vertical-align: middle;
          font-weight: unset;
        }

        h5 {
          font-size: 14.5px;
          font-weight: unset;
        }

        span {
          margin-left: 6px;
          margin-top: 4px;

          svg {
            height: 14px;
            width: 14px;
          }
        }
      }
    }

    &_dark {
      position: relative;

      background: $app_darkmode_background_header1;
      -moz-box-shadow: 0px 4px 50px $app_black_shade5;
      -webkit-box-shadow: 0px 4px 50px $app_black_shade5;
      box-shadow: 0px 4px 50px $app_black_shade5;
      border: none;
    }
  }
}

@media screen and (max-width: 460px) {
  .payment_failed {
    .raw {
      width: 92%;
    }
  }
}
