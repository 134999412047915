#toast-chakraToast {
  & > div:nth-child(2) {
    & > div {
      color: #fff !important;
    }
  }

  & > span:nth-child(1) {
    svg {
      path {
        fill: #fff;
      }
    }
  }

  & > button {
    svg {
      path {
        fill: #fff;
      }
    }
  }
}

.chakra-toast {
  & > div:nth-child(2) {
    border-color: #fff;
    color: #fff;

    & > div {
      color: #fff !important;
    }
  }

  & > span:nth-child(1) {
    border-color: #fff;
    color: #fff;

    svg {
      path {
        fill: #fff !important;
      }
    }
  }

  & > button {
    color: #fff;

    svg {
      path {
        fill: #fff !important;
      }
    }
  }
}
