@import '../../../../theme/commons.module.scss';
@import '../../../../theme/color.scss';

.compare_plans {
  position: relative;
  width: 100%;
  box-sizing: border-box;
  margin-top: 100px;
  padding: 0 24px;

  .title {
    position: relative;
    width: 100%;
    text-align: center;

    & > h1 {
      position: relative;
      font-style: normal;
      font-family: 'WorkSans-SemiBold';
      font-size: 42px;
      line-height: 90px;
      text-align: center;
      // border: 1px solid red;
      box-sizing: border-box;
      display: inline-block;
      text-align: center;
      color: $app_blue_shade1;
    }
  }

  .content {
    position: relative;
    width: 100%;
    margin-top: 40px;

    & > ul {
      position: relative;
      width: 100%;
      list-style-type: none;
      list-style: none;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      //   border: 1px solid red;
      box-sizing: border-box;
      border-bottom: 1px solid $app_white_shade5;

      & > li {
        position: relative;
        width: calc(25% - 20px);
        display: inline-block;
        vertical-align: top;
        padding: 8px 16px;
        box-sizing: border-box;
        min-height: 24px;
        // border: 1px solid red;
        text-align: left;

        .help_icon {
          position: absolute;
          right: 6px;
          top: 8px;
          display: inline-block;
          opacity: 0.7;

          svg {
            height: 12px;
            width: 12px;
            fill: $app_gray_shade6;
            path {
              fill: $app_gray_shade6;
            }
          }
        }
      }

      & > li:nth-child(2) {
        margin-left: 8px;
      }
    }

    & > ul:nth-child(1) {
      & > li:nth-child(2),
      & > li:nth-child(3),
      & > li:nth-child(4),
      & > li:nth-child(5) {
        background: $app_white_shade4;

        & > h5 {
          position: relative;
          font-weight: unset;
          font-size: 92%;
          font-family: 'WorkSans-Medium';
          color: $app_black_shade3;
        }
      }
    }

    .label {
      position: relative;
      width: 100%;
      padding: 24px 16px;
      box-sizing: border-box;
      text-align: left;
      border-bottom: 1px solid $app_white_shade5;

      & > h4 {
        position: relative;
        text-align: left;
        font-size: 110%;
        font-weight: unset;
        font-family: 'WorkSans-SemiBold';
        color: $app_blue_shade1;
        box-sizing: border-box;
      }
    }

    .tasks,
    .fileuploads,
    .subscribers,
    .linksharing,
    .collabspaces,
    .webresponsive,
    .richeditor,
    .tags,
    .activitylog,
    .api,
    .priority {
      padding: 16px 0;

      svg {
        fill: #5a46de;
        height: 16px;
        width: 16px;

        path {
          fill: #5a46de;
        }
      }

      & > li:nth-child(1) {
        & > h5 {
          font-weight: unset;
          font-family: 'WorkSans-Medium';
        }
      }

      & > li {
        & > h5 {
          color: $app_black_shade3;
          font-weight: unset;
          font-family: 'WorkSans-Regular';
          line-height: 16px;
          font-size: 95%;
        }
      }
    }
  }
}

@media screen and (max-width: 1024px) {
  .compare_plans {
    display: none;
    overflow: hidden;
    width: 0;
  }
}
