@import '../../../../theme/color.scss';
@import '../../../../theme/commons.module.scss';
@import '../../../../theme/scroll.scss';

.spaces_dashboard_view {
  position: absolute;
  top: 66px;
  left: 0;
  height: calc(100% - 150px);
  width: 100%;
  overflow: visible;
  box-sizing: border-box;

  &_mobile_modal {
    height: calc(100% - 116px);
  }

  &_list {
    position: relative;
    width: 100%;
    max-height: 100%;
    display: inline-block;
    padding: 0 12px;
    padding-left: 16px;
    padding-bottom: 30px;
    overflow: hidden;
    overflow-y: scroll;
    box-sizing: border-box;

    @include custom_scroll;

    .create_new_space {
      position: relative;
      display: inline-block;
      width: 100%;
      padding: 16px 0;
      margin-top: 10px;
      box-sizing: border-box;
      border-bottom: 1px solid rgba($color: #f4f6fa, $alpha: 1);

      & > button {
        width: 100%;
        justify-content: flex-start;

        svg {
          height: 20px;
          width: 20px;
        }

        & > h5 {
          position: relative;
          font-size: 18px;
          font-weight: unset;
          font-family: 'WorkSans-Medium';
          margin-left: 16px;
        }
      }

      &_dark {
        border-bottom: 1px solid rgba($color: $app_darkmode_text1, $alpha: 0.1);

        & > button {
          background: transparent;
          color: $app_darkmode_text1;

          &:hover,
          &:active {
            background: rgba(
              $color: $app_darkmode_text1,
              $alpha: 0.05
            ) !important;
          }
          color: $app_darkmode_text1 !important;

          & > h5 {
            color: $app_darkmode_text1;
          }
        }
      }
    }

    & > ul {
      position: relative;
      display: inline-block;
      width: 100%;
      padding-top: 12px;
      box-sizing: border-box;
      list-style: none;
      list-style-type: none;

      & > li {
        position: relative;
        width: 100%;
        align-items: flex-start;
        align-items: center;
        box-sizing: border-box;
        padding-top: 16px;
        box-sizing: border-box;

        .space_avatar {
          position: relative;
          height: 40px;
          width: 40px;
          border-radius: 4px;
          overflow: hidden;

          & > img,
          & > h5 {
            position: relative;
            height: 40px;
            width: 40px;
          }

          & > img {
            object-fit: cover;
            object-position: center;
          }

          & > h5 {
            background: $app_black_shade1;
            color: $app_white_absolute;
            border-radius: 4px;
            font-size: 14px;
            font-weight: unset;
            font-family: 'WorkSans-Medium';
          }
        }

        .space_info {
          position: relative;
          width: calc(100% - 58px);
          margin-left: 16px;
          margin-top: 1px;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: flex-start;
          box-sizing: border-box;

          & > h4 {
            font-weight: unset;
            font-family: 'WorkSans-Medium';
            font-size: 16px;
            color: $app_blue_shade1;
          }

          & > h5 {
            font-weight: unset;
            font-family: 'WorkSans-Regular';
            font-size: 14px;
            margin-top: 4px;
            opacity: 0.7;
          }

          &_dark {
            & > h4 {
              color: $app_white_absolute;
            }
          }

          .selected {
            position: absolute;
            right: 0px;
            top: 6px;

            svg {
              height: 20px;
              width: 20px;
            }
          }
        }
      }

      & > li:not(:nth-child(1)) {
        margin-top: 16px;
        border-top: 1px solid rgba($color: #f4f6fa, $alpha: 1);
      }

      & > li:last-child {
        margin-bottom: 30px;
      }
    }

    &_dark {
      & > ul {
        & > li:not(:nth-child(1)) {
          border-top: 1px solid rgba($color: $app_darkmode_text1, $alpha: 0.1);
        }
      }

      @include custom_scroll_dark;
    }
  }
}
