@import '../../../theme/color.scss';
@import '../../../theme/commons.module.scss';
@import '../../../theme/menu.module.scss';
@import './NotesList.commons.module.scss';

.note_item {
  position: relative;
  width: 100%;
  min-height: 44px;
  padding: 0 8px;
  box-sizing: border-box;
  border: 1px solid transparent;

  &:hover {
    border-color: rgba($color: $app_gray_shade4, $alpha: 0.2);
  }

  .desktop {
    position: relative;
    width: 100%;
    padding-bottom: 8px;
    box-sizing: border-box;
    justify-content: flex-start;
    align-items: center;
    cursor: default;
    font-size: 100%;

    .desktop_title {
      & > p {
        font-size: 105%;
      }
    }

    .desktop_label {
      position: relative;
    }
  }

  .mobile {
    display: none;
  }

  .copy_link_button,
  .show_more {
    position: relative;
    height: 40px;
    width: 40px;
    padding: 0;
    box-sizing: border-box;
    margin: 0;

    &_dark {
      background: transparent !important;

      svg {
        fill: $app_darkmode_text1;

        path {
          fill: $app_darkmode_text1;
        }
      }

      &:hover,
      &:active,
      &:focus {
        background: rgba($color: $app_darkmode_text1, $alpha: 0.05) !important;
      }
    }
  }

  .show_more {
    position: relative;
  }

  .note_item_delete {
    box-sizing: border-box;

    svg {
      path {
        fill: #df3e4a !important;
      }
    }

    span {
      color: #df3e4a !important;
    }
  }
}

@media screen and (max-width: 800px) {
  .note_item {
    padding: 0 4px;
    min-height: 60px;
    max-height: unset;
    box-sizing: border-box;
    border: 1px solid transparent;

    &:hover {
      border-color: transparent;
    }

    .desktop {
      display: none;
    }

    .mobile {
      display: flex;
      justify-content: flex-start;
    }
  }
}
